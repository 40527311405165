<template>
  <div>
    <el-dialog
      :title="form.valueName?form.valueName:'阀门'"
      :visible.sync="dialogVisible"
      :inline="true"
      :modal="false"
      width="333px"
      custom-class="dialog-class"
      :close-on-click-modal="false"
      @close="close"
      @open="openD"
    >
      <el-form ref="form" :model="form" label-width="75px" class="desform">
       <el-form-item label="所属图层 :">
            <div class="equipment-item">{{ form.layerName }}</div>
          </el-form-item>
          <el-form-item label="设备编号 :">
            <div class="equipment-item">{{ form.valueCode }}</div>
          </el-form-item>
          <el-form-item label="经纬度 :">
            <label slot="label">经&nbsp;&nbsp;纬&nbsp;&nbsp;度 :</label>
            <div class="equipment-item">{{ postion}}</div>
          </el-form-item>
          <el-form-item label="阀门种类 :">
            <div class="equipment-item">{{ form.valveKindName }}</div>
          </el-form-item>
          <el-form-item label="敷设方式 :">
            <div class="equipment-item">{{ form.layTypeName }}</div>
          </el-form-item>
          <el-form-item label="阀门设施 :">
            <div class="equipment-item">{{ form.valveFacilitiesName }}</div>
          </el-form-item>
          <!-- <el-form-item label="阀井 :">
            <label slot="label">阀&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;井:</label>
            <div class="equipment-item">{{ form.valveWell }}</div>
          </el-form-item> -->
          <el-form-item label="阀井深度 :">
            <div class="equipment-item">{{ form.valveWellDepth }}</div>
          </el-form-item>
          <el-form-item label="埋深 :">
            <label slot="label">埋&nbsp;&nbsp;&nbsp;&nbsp;深(m) :</label>
            <div class="equipment-item">{{ form.depth }}</div>
          </el-form-item>
          <el-form-item label="规格 :">
            <label slot="label">规&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;格 :</label>
            <div class="equipment-item">{{ form.spec }}</div>
          </el-form-item>
          <el-form-item label="型号 :">
            <label slot="label">型&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;号 :</label>
            <div class="equipment-item">{{ form.model}}</div>
          </el-form-item>
          <el-form-item label="连接方式 :">
            <div class="equipment-item">{{ form.connectTypeName }}</div>
          </el-form-item>
          <el-form-item label="设备状态 :">
            
            <div class="equipment-item">{{ form.generalStatusName }}</div>
          </el-form-item>
          <el-form-item label="安装日期 :">
            <div class="equipment-item">{{ form.installDate }}</div>
          </el-form-item>
          <el-form-item label="附着压力级别 :" label-width="105px">
            <div class="equipment-item">{{ form.pressureLevelName }}</div>
          </el-form-item>
          <el-form-item label="生产厂家 :">
   
            <div class="equipment-item">{{ form.factory}}</div>
          </el-form-item>
          <el-form-item label="所在道路 :">
            <div class="equipment-item">{{ form.road }}</div>
          </el-form-item>
          <el-form-item label="备注 :">
            <label slot="label">备&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;注 :</label>
            <div class="equipment-item">{{ form.remark }}</div>
          </el-form-item>
      </el-form>
      <!-- <div class="btn">
        <el-button size="mini" @click="close">取消</el-button>
      </div> -->
    </el-dialog>
  </div>
</template>
<script>
import { valveFindById } from '../apis/tool'
export default {
  props: {
    id: {
      type: String,
      default: () => ''
    },
    postion: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      form: {
        companyId: '',
        connectType: '',
        connectTypeName: '',
        controlType: '',
        controlTypeName: '',
        createBy: '',
        createTime: '',
        factory: '',
        factoryName: '',
        generalStatus: '',
        generalStatusName: '',
        id: '',
        installDate: '',
        layType: '',
        layTypeName: '',
        modelCode: '',
        modelName: '',
        pointId: '',
        pressureLevelName: '',
        spec: '',
        specName: '',
        switchStatus: '',
        switchStatusName: '',
        switchType: '',
        switchTypeName: '',
        transmitName: '',
        updateTime: '',
        valueCode: '',
        valueKindName: '',
        valueName: ''
      },
      dialogVisible: false
    }
  },
  mounted() {},
  methods: {
    close() {
      this.dialogVisible = false
      this.form = {}
    },
    openD() {
      //获取数据的接口等待对接

      valveFindById({ id: this.id }).then(res => {
        this.form = res.data
      })
    },
    async openDesc(flag) {
      let that = this
      this.dialogVisible = flag
    }
  }
}
</script>
<style lang="scss" scoped>
.btn {
  text-align: center;
  button {
    margin: 7px 15px 0 15px;
  }
}
.spanDesc {
  background: rgba(102, 122, 143, 0.2);
  padding: 5px 10px;
  color: #fff;
  width: 100%;
  display: inline-block;
  min-height: 42px;
  border: rgba(110, 183, 227, 0.2) 1px solid;
  font-weight: 400;
}
.equipment-item {
  text-align: left;
  width: 180px;
  color: var(--font-color-c2);
  margin-left: 5px;
}
.desform{text-align: left;margin-left: 0px;}
</style>
