<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :inline="true"
      :modal="false"
      :close-on-click-modal="false"
      width="356px"
      custom-class="dialog-class"
      @close="close"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="80px" size="mini">
      <el-form-item label="所属图层:" prop="point">
          <el-input v-model="form.layerName" :disabled="true" placeholder="请输入所属图层"></el-input>
        </el-form-item>
        <el-form-item label="名称:" prop="pointName">
          <el-input
            v-model="form.pointName"
            placeholder="请输入名称"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="经纬度:" prop="point">
          <el-input v-model="form.point" :disabled="true" placeholder="请输入经纬度"></el-input>
        </el-form-item>
        <el-form-item label="高程:" prop="elevation">
          <el-input v-model="form.elevation" @input="onTableNub($event, form, 'elevation')" placeholder="请输入高程">
            <template slot="append">m</template>
          </el-input>
        </el-form-item>
        <el-form-item label="埋深:" prop="depth">
          <el-input v-model="form.depth" @input="onTableNub($event, form, 'depth')" placeholder="请输入埋深">
            <template slot="append">m</template>
          </el-input>
        </el-form-item>
        <el-form-item :label="title + ':'" prop="nodeType">
          <el-select
            v-model="form.nodeType"
            :placeholder="'请选择' + title"
            popper-class="gis-default"
          >
            <el-option
              v-for="item in nodeTypeList"
              :key="item.dictCode"
              :label="item.name"
              :value="item.dictCode"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注:">
          <el-input
            v-model="form.remark"
            type="textarea"
            :rows="3"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>
        <el-form-item label="所在地址:" prop="address">
          <el-input
            v-model="form.address"
            type="textarea"
            :rows="3"
            placeholder="请输入所在地址"
          ></el-input>
        </el-form-item>
        <el-form-item :label="item.attributeName+':'" v-for="(item,index) in mbList" :key="index">
          <el-input v-model="addtpl[item.id]" maxlength="40" :placeholder="'请输入'+item.attributeName"></el-input>
        </el-form-item>
        <div class="mbget" @click="getmb()" v-if="showadd">获取自由属性模板</div>
      </el-form>
      <div class="btn">
        <el-button size="mini" @click="close">取消</el-button>
        <el-button type="primary" v-if="addOrEdit === 'add'" size="mini" @click="update">保存</el-button>
        <el-button type="primary" v-else size="mini" @click="update()">保存</el-button>
        
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { attribute_queryConfig } from '../apis/base'
import { findDictTree } from '../apis/commonType'
import { updateById } from '../apis/tool'
import { commonDict, serverMap } from '../utils/dict.js'
export default {
  data() {
    let checkElevation = (rule, value, callback) => {
      if (value === null || value === undefined || value === '') {
        callback()
      } else if (!/^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/.test(value)) {
        callback(new Error('高程必须为数值'))
      } else {
        callback()
      }
    }
    let checkDepth = (rule, value, callback) => {
      if (value === null || value === undefined || value === '') {
        callback()
      } else if (!/^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/.test(value)) {
        callback(new Error('埋深必须为数值'))
      } else {
        callback()
      }
    }
    let validateNodeType = (rule, value, callback) => {
      if (value === null || value === undefined || value === '') {
        callback(new Error('请选择' + this.title))
      } else {
        callback()
      }
    }
    return {
      title: '管件',
      diaCode: 'fittings',
      addOrEdit: 'add',
      form: {
        elevation: '',
        depth: ''
      },
      rules: {
        pointName:[{ required: true, message: '请输入名称', trigger: 'blur' }],
        point: [{ required: true, message: '请输入经纬度', trigger: 'blur' }],
        nodeType: [{ required: true, validator: validateNodeType, trigger: 'change' }],
        elevation: [{ validator: checkElevation, trigger: 'blur' }],
        depth: [{ validator: checkDepth, trigger: 'blur' }],
        remark: [{ max: 60, message: '超出最大限制', trigger: ['change', 'blur'] }],
        address: [{ required: true, message: '请输入所在地址', trigger: 'blur' }],
      },
      dialogVisible: false,
      nodeTypeList: [],
      showadd:true,
      mbList:[],
      addtpl:[]
    }
  },
  mounted() {},
  methods: {
    getmb(){
      this.showadd = false;
      let code =  this.title == '管件' ? '11001' : '11002'
      attribute_queryConfig({pidDictCode:code}).then(res=>{
        this.mbList = res.data
       
      })
      
    },
     /*
     *@description: 数值改变
     *@author: 黄
     */
    onTableNub(nub, row, prop) {
      if (nub == '') {
        return
      }
      let arr = nub.split('')
      let index = arr.findIndex(el => el != 0)
      if (nub > 99999999) {
        row[prop] = 99999999
      } else if (index != -1) {
        if (arr[1] != '.') {
          arr.splice(0, index)
          row[prop] = arr.join('')
        }
      } else {
        row[prop] = 0
      }
    },
    getCodeName() {
      let that = this
      let code = commonDict[100001].code
      if (that.diaCode == 'fittings') {
        that.nodeTypeList = serverMap[1003]
      } else {
        findDictTree({ dictCode: code }).then(res => {
          if (res.data) {
            that.nodeTypeList = res.data[code]
          }
        })
      }
    },
    openDialog(data) {
      if (data.addOrEdit == 'edit') {
        // 编辑
        this.diaCode = data.diaCode
        this.addOrEdit = data.addOrEdit
        
        this.form = {
          ...data
        }
        this.title = data.diaCode == 'fittings' ? '管件' : '管线点'
        // 节点转换成管件、管线点，nodeType清空
        if (data.nodeType == 0) {
          delete this.form.nodeType
           let that = this;
          var geocoder = new AMap.Geocoder();
          let lnglat1 = this.form.point.split(' ')
            geocoder.getAddress(lnglat1, function (status, result) {
              if (result.info === "OK") {
                that.$set(that.form,'address',result.regeocode.formattedAddress);
              }
            });
        } else {
          this.showadd = false
          if(this.form.frees){
            this.form.frees.forEach(el=>{
              this.mbList.push({id:el.freeId,attributeName:el.attributeName})
               this.addtpl[el.freeId] = el.freeValue
            })
          }
          this.form.nodeType = data.nodeType
         
        }
        this.getCodeName()
        console.log(this.form)
      } else {
        // 新增
        this.form.pipeId = data.id
        this.form.point = data.lnglat
        this.form.layerName=   this.$store.getters.currentLayerName
        
        let that = this;
        var geocoder = new AMap.Geocoder();
        let lnglat1 = this.form.point.split(' ')
          geocoder.getAddress(lnglat1, function (status, result) {
            if (result.info === "OK") {
              that.$set(that.form,'address',result.regeocode.formattedAddress);
            }
          });
        
      }
      
      this.dialogVisible = true
      this.$refs['form'].resetFields()
    },
    close() {
      this.dialogVisible = false
      this.$parent.$parent.editTool.close(true)
      this.$parent.$parent.pointHighlightRemove()
      if (this.addOrEdit == 'add') {
        this.$parent.startDraw('node')
      }
      this.showadd = true
      this.mbList = []
      this.addtpl = []
    },
    update() {
      console.log(this.addtpl)
      
      let that = this
      this.$refs['form'].validate(valid => {
        if (valid) {
          if (!that.form.elevation) that.form.elevation = 0
          let addData = [];
          this.mbList.forEach((el,index)=>{
            addData.push({freeId:el.id,freeValue:this.addtpl[el.id]?this.addtpl[el.id]:null})
          })
          that.form.frees = addData;
          updateById(that.form)
            .then(res => {
              that.$message.success(res.msg)
              that.$eventBus.$emit('setFilter')
              that.close()
            })
            
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.btn {
  text-align: right;
  button {
    margin: 7px 15px 0 15px;
  }
}
.mbget{text-align: right;color: #1082FF;}
</style>
