<template>
  <div style="display: flex;flex-wrap: wrap;float: right;">
  
    <div class="tool-bar">
      <div  class="tool-list">
        <li :class="leftTopActive==item.value||(item.value=='layerM'&&$parent.layerShow)||(item.value=='lineStat'&&lineStatShow)?'activeAll':''" v-for="(item,index) in mapToolList" :key="'info1'+index" @click="allActive(item.value)">
          <!-- <el-tooltip class="tooltipGis" :content="item.tip" placement="top"> -->
            <div
              :class="leftTopActive==item.value||(item.value=='layerM'&&$parent.layerShow)||(item.value=='lineStat'&&lineStatShow)?'active':''"
              
              :style="item.value=='fullScreen'?'padding: 0px 9px':''"
            >
            <span :class="['icon', 'iconfont', item.img]"></span>
            <!-- <img :src="item.img" class="showimg"></img> -->
              <!-- <svg class="icon svg-icon" aria-hidden="true">
                <use :xlink:href="item.img"></use>
              </svg> -->
              <span>{{ item.name }}</span>
            </div>
          <!-- </el-tooltip> -->
        </li>
         <el-popover
          v-for="(item, index) in anysList"
          :key="'eq' + index"
          placement="bottom"
          trigger="click"
          popper-class="tool-pop"
          
        >
          <div class="tool-pop-item">
            <li 
              v-for="(itemsub, indexsub) in item.children"
              :key="'eqsub' + indexsub"
              :class="leftTopActive == itemsub.value ? 'active' : ''"
              @click="startAnysList(itemsub, itemsub.name)"
            >
            <span :class="['icon', 'iconfont', itemsub.img]" :style="itemsub.value=='pipeAnalysis'?'margin:0px 5px;font-size: 1px;width:20px':'margin:0px 5px;width:20px'"></span>
            <!-- <img :src="itemsub.img" class="showimg" style="margin:0px 5px;width: 16px;"></img> -->
              <!-- <svg class="icon svg-icon" aria-hidden="true">
                <use :xlink:href="itemsub.img"></use>
              </svg> -->
              <span style="margin-right: 10px">{{ itemsub.name }}</span>
            </li>
          </div>
          <li slot="reference" :class="
                leftTopActive == item.children[0].value || leftTopActive == item.children[1].value
                  ? 'activeAll'
                  : ''">
            <div
              :class="
                leftTopActive == item.children[0].value || leftTopActive == item.children[1].value
                  ? 'active'
                  : ''"
            >
            <span :class="['icon', 'iconfont', item.img]"></span>
               <!-- <img :src="item.img" class="showimg" ></img> -->
              <span>{{ item.name }}</span>
              <span style="float: right;  margin-right: -7px">
                <img src="../../image/tool-subscript.png" />
               
              </span>
            </div>
          </li>
        </el-popover>
        <li :class="leftTopActive==item.value?'activeAll':''" v-for="(item,index) in setSHowList" :key="'info3'+index" @click="allActive(item.value)">
          <!-- <el-tooltip class="tooltipGis" :content="item.tip" placement="top"> -->
            <div
              :class="leftTopActive==item.value?'active':''"
            >
            <span :class="['icon', 'iconfont', item.img]"></span>
            <!-- <img :src="item.img" class="showimg"></img> -->
              <!-- <svg class="icon svg-icon" aria-hidden="true">
                <use :xlink:href="item.img"></use>
              </svg> -->
              <span>{{ item.name }}</span>
            </div>
          <!-- </el-tooltip> -->
        </li>
        <li  v-for="(item,index) in cloneList" :key="'info4'+index" @click="cloneActive(item.value)">
          <!-- <el-tooltip class="tooltipGis" :content="item.tip" placement="top"> -->
            <div>
              <span :class="['icon', 'iconfont', item.img]"></span>
            <!-- <img :src="item.img" class="showimg"></img> -->
              <!-- <svg class="icon svg-icon" aria-hidden="true">
                <use :xlink:href="item.img"></use>
              </svg> -->
              <span>{{ item.name }}</span>
            </div>
          <!-- </el-tooltip> -->
        </li>
        
        <li  @click="fullScreen">
        <div style="padding:0px 4px">
           <i :class="this.FullScreenBool?'iconfont icon-quanping':'iconfont icon-tuichuquanping'"></i>
        </div>
      </li>
      </div>
     
    </div>
   <line-stat ref="linestat"></line-stat>
 
  </div>
</template>

<script>
import LineStat from '../layer/lineStat.vue'
export default {
  name: 'TopTool',
  components: {
    LineStat
  },
  data() {
    return {
      mapToolList: [
        
        { name: '图层管理', img: 'icon-tuceng1', value: 'layerM', tip: '测量地图绘制折线距离' },
       
        {
          name: '管网概况',
          img:  'icon-a-ziyuan4',
          value: 'lineStat',
          tip: ''
        },
        {
          name: '框选查询',
          img:  'icon-kuangxuanchaxun',
          value: 'polygonQuery',
          tip: ''
        },
        ],
        anysList:[
            {
          name: '管网分析',
          img: 'icon-a-ziyuan5',
          value: 'pipeAnalysis',
          tip: '',
          children:[ {
                name: '爆管分析',
                img: 'icon-a-ziyuan1',
                value: 'pipeAnalysis',
                tip: ''
                },
                {
                name: '关阀分析',
                img: 'icon-a-ziyuan2',
                value: 'valueAnalysis',
                tip: ''
                }]
        }
            
        ],
        setSHowList:[{
          name: '显示设置',
          img: 'icon-a-ziyuan3',
          value: 'setShow',
          tip: ''
        },
        
      ],
      cloneList:[{
          name: '清除',
          img: 'icon-qingkongtuxing',
          value: 'setShow',
          tip: ''
        }],
      FullScreenBool:true,
      lineStatShow:false,
      leftTopActive:''
    }
  },
  watch: {
    activeOptionItem(val) {
      this.$store.commit('gis/activeOptionChange', val)
    }
  },
  mounted() {},
  methods: {
    allActive(val){
        if(val=='layerM'){
            this.$parent.layerShow = !this.$parent.layerShow
        }else if(val=='lineStat'){
            if(!this.lineStatShow){
                this.lineStatShow = true
                this.$refs.linestat.openDialog()
            }else{
                this.$refs.linestat.close()
                this.lineStatShow = false
            }
            
        }else{
            if(this.leftTopActive!='pipeAnalysis'&&this.leftTopActive!='valueAnalysis'&&this.leftTopActive){
                this.leftTopActive = ''
                
            }else{
                this.leftTopActive = val;
                if(val=='fullScreen'){
                    this.fullScreen()
                }
                
                if(val=='polygonQuery'){
                  
                  this.cloneActive()
                  this.leftTopActive = 'polygonQuery'
                    this.$parent.draw(val)
                }
                if(val=='setShow'){
                    this.displaySettings()
                }
            }
            
        }
        
    },
    startAnysList(obj,name){
      this.cloneActive()
      if(this.leftTopActive ==obj.value){
        this.leftTopActive = '';
      }else{
        this.leftTopActive = obj.value;
        this.anysList[0].name = name;
      }
        
    },
    cloneActive(){
       this.$parent.removeHighligAll()
      this.$parent.pointHighlightRemove()
      this.$parent.lineHighlightRemove()
      this.leftTopActive = '';
      this.$parent.clearDraw()
      this.$parent.$refs.queryInfo.dialogVisible = false
      //this.$eventBus.$emit('setFilter')
    },
    /**
     * 显示设置
     */
    displaySettings() {
        this.$parent.settingsItem('dialogDisplay')
    },
    /**
     * 全屏
     */
    fullScreen() {
      if (this.FullScreenBool) {
        var el = document.documentElement
        var rfs =
          el.requestFullScreen ||
          el.webkitRequestFullScreen ||
          el.mozRequestFullScreen ||
          el.msRequestFullScreen
        if (rfs) {
          rfs.call(el)
        } else if (typeof window.ActiveXObject !== 'undefined') {
          var wscript = new ActiveXObject('WScript.Shell')
          if (wscript != null) {
            wscript.SendKeys('{F11}')
          }
        }
      } else {
        var el = document
        var cfs =
          el.cancelFullScreen ||
          el.webkitCancelFullScreen ||
          el.mozCancelFullScreen ||
          el.exitFullScreen
        if (cfs) {
          cfs.call(el)
        } else if (typeof window.ActiveXObject !== 'undefined') {
          var wscript = new ActiveXObject('WScript.Shell')
          if (wscript != null) {
            wscript.SendKeys('{F11}')
          }
        }
      }
      this.FullScreenBool = !this.FullScreenBool
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__label {
          padding-left: 5px;
}
.back-style {
  min-width: 20px !important;

}
.selectColor {
  background: var(--background-hover-skin);
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 40px;
  padding: 0 2px 0 8px;
}
.sketchImg {
  display: flex;
  .span {
    width: 30px;
    height: 30px;
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  // .span:hover {
  //   background: var(--background-hover-skin);
  // }
  .span.active {
    background: var(--background-hover-skin);
  }
  .img {
    height: 25px;
    width: auto;
    cursor: pointer;
    text-align: center;
  }
}
.showimg{display: inline-block;
// vertical-align: middle;
height: 14px;
        width: auto;}
.tool-bar {
  // float: left;
  display: flex;
  font-size: 14px;
  height: 40px;
  margin-left: 4px;
  color: var(--font-color-top);
  background: var(--background-color-skin);
  border: 1px solid var(--border-color2-skin);
  box-shadow: 0px 1px 6px 0px rgba(4, 57, 138, 0.3);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 28px;
  .tool-name {
    float: left;
    height: 36px;
    margin: 7px 0;
    padding: 0 10px;
    line-height: 36px;
    box-shadow: 1px 0px 0px 0px var(--boxshadow-color-skin);
  }
  .tool-list {
    overflow: hidden;
  }
  .isShow {
    display: flex;
    align-items: center;
    &.unfold {
      transform: rotate(180deg);
    }
    img {
      width: 10px;
    }
  }
  .activeAll{
    background: linear-gradient(-37deg, #4C729D, rgba(255, 255, 255, 0), #4C729D);
    background-color: #245082;
    margin-right: 1px;
    }
  li {
    float: left;
    height: 24px;
    padding: 10px 0;
    text-align: center;
    
    div {
      height: 24px;
      line-height: 24px;
      min-width: 32px;
    //   margin: -1px 11px 2px 11px;
      padding: 0px 11px;
      color: var(--font-color-top);
      box-shadow: 1px 0px 0px 0px var(--boxshadow-color-skin);
     
      .img {
        height: 18px;
        width: auto;
      }
      .icon {
        height: 16px;
        width: 16px;
        vertical-align:middle;
      }
      span {
        font-size: 14px;
        margin-left: 7px;
      }
    }
    .active {
      color: var(--font-color-topA) !important;
    }
    // div:hover {
    //   background: var(--background-hover-skin);
    //   cursor: pointer;
    // }
  }
}
.tool-pop-item {
  padding: 4px 0;
  li {
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    // img,
    // .icon {
    //   height: 16px;
    //   width: 40px;
    // }
  }
  // li:hover {
  //   background: var(--background-hover-skin);
  // }
  .active {
    color: var(--font-color-f1) !important;
    background: var(--background-active-skin) !important;
  }
}

.bubbleBox {
  position: absolute;
  z-index: -1;
  display: none;
}
.Bubble {
  div {
    font-size: 14px;
  }
  position: relative;
  .lj {
    display: block;
    position: absolute;
    top: 0.5rem;
    left: -21px;
    transform: rotate(-90deg);
    z-index: 1;
    .tip {
      width: 0;
      height: 0;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 12px solid #222;
      position: relative;
    }
    .tip:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      bottom: -10px;
      left: -12px;
      z-index: -1;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 12px solid rgba(0, 204, 255, 0.5);
    }
    // border-bottom: 12px solid rgba(8, 31, 48, 1);
  }
}
.markbox {
  background: #15202f;
  border: 1px solid #6eb7e3;

  min-width: 290px;
  min-height: 115px;

  // background-size: 100% 100%;
  font-size: 18px;
  // margin-top: -0.6rem;
  margin-left: 0.1rem;
  overflow: visible;
  position: relative;
}
.markTitle {
  height: 42px;
  display: flex;
  // line-height: 1;
  justify-content: space-between;
  align-items: center;
  //background-image: linear-gradient(to right, #0a608c, #081f30);
  background-color: var(--background-title-skin);
  opacity: 1;
  p {
    display: flex;
    line-height: 1;
    justify-content: flex-start;
    align-items: center;
    margin-left: 10px;
    img {
      margin-right: 10px;
      height: 24px;
      width: auto;
    }
  }
  i {
    font-size: 18px;
    color: #679db4;
    cursor: pointer;
    margin-right: 10px;
  }
  // i:hover {
  //   color: #548aa1;
  // }
}
.mark-con {
  margin: 5px 10px;
  line-height: 2;
  .li:hover,
  .li.active {
    color: var(--font-color-b1);
    cursor: pointer;
  }
}
.btn {
  text-align: right;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
