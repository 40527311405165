<template>
  <div>
    <div v-show="dialogVisible" id="dragBox" ref="dragBox" class="layerBox">
      <!-- <el-dialog
      class="query"
      title=""
      :visible.sync="dialogVisible"
      width="1200px"
      :show-close="item.uuid ? true : false"
      :close-on-click-modal="false"
    > -->
      <div >
        <div class="show-border"  @mousedown="move">
        <div style="width:620px;display:inline-block">
          <el-tabs ref="tabs" v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="管线" name="one"></el-tab-pane>
            <el-tab-pane label="场站" name="three"></el-tab-pane>
            <el-tab-pane label="阀门" name="two"></el-tab-pane>
            <!-- <el-tab-pane label="仪表设备" name="five"></el-tab-pane> -->
            
          </el-tabs>
        </div>
        <div class="caozu">
          <span v-if="activeName == 'one'&&$parent.gis" class="activeBut" @click="editAll()"
            ><i class="el-icon-edit-outline"></i> 批量修改</span
          >

          <download-excel
            style="display:inline-block;"
            :data="tableData1"
            :fields="json_fields"
            :before-generate="getData11"
            worksheet="My Worksheet"
            :name="fileName"
          >
            <span class="activeBut"> <i class="el-icon-edit-outline"></i> 导出</span>
          </download-excel>

          <span class="colseBut" @click="colse"><i class="iconfont icon-shanchu2"></i></span>
        </div>
        </div>
        <div>
          <el-table
            id="show111"
            ref="multipleTable"
            v-loading="loading"
            element-loading-text="正在查询，请稍后"
            element-loading-spinner="el-icon-loading"
            :data="tableData"
            :stripe="false"
            :default-sort="{ prop: 'build_date', order: 'ascending' }"
            :height="420"
            size="small"
            border
            @sort-change="sort_change"
          >
            <el-table-column
              type="index"
              width="50"
              label="序号"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              v-for="(item, index) in showListColumn[activeName]"
              :key="index"
              :prop="item.propName"
              :label="item.label"
              :width="item.width"
              show-overflow-tooltip
              sortable="custom"
            >
              <template slot-scope="{ row }">
                <span v-if="item.option">{{ optionList[row[item.propName]] }}</span>
                <span v-else>{{  item.propName == 'location_str'
                    ? row[item.propName].replace('POINT(', '').replace(')', '')
                    : row[item.propName] }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" show-overflow-tooltip width="120">
              <template slot-scope="{ row }">
                <el-button type="text" size="small" @click="showDesc(row)">查看</el-button>
                <el-button type="text" class="butleft" style="color:#0A8E78" @click="locationMap(row)">定位</el-button>
              </template>
            </el-table-column>
          </el-table>

          <div class="pageBox">
            <el-pagination
              :current-page="page"
              :background="true"
              :page-sizes="[30, 50, 100]"
              :page-size="size"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <!-- </el-dialog> -->
    </div>

    <el-dialog
      title="批量修改"
      :visible.sync="dialogVisibleEdit"
      :inline="true"
      :modal="false"
      width="566px"
      custom-class="dialog-class"
      :close-on-click-modal="false"
      @close="closeAll"
    >
      <el-form
          ref="form"
          :model="form"
          :rules="rules"
          :inline="true"
          label-position="right"
          label-width="100px"
          class="form"
          size="mini"
        >
        
          <el-form-item label="管道名称:" prop="pipeName">
            <el-input
              v-model="form.pipeName"
              placeholder="请输入管道名称"
              :style="labelWidthMax"
              maxlength="40"
            ></el-input>
          </el-form-item>
          <el-form-item label="管材:" prop="pipeType">
            <el-select
              v-model="form.pipeType"
              popper-class="gis-default"
              placeholder="请选择管材"
              :style="labelWidth"
              @change="pipeTypeChange"
            >
              <el-option
                v-for="item in pipeTypeList"
                :key="item.dictCode"
                :label="item.name"
                :value="item.dictCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="管材型号:" prop="pipeModel">
            <el-select
              v-model="form.pipeModel" 
              popper-class="gis-default"
              placeholder="请选择管材型号"
              clearable
              :style="labelWidth"
            
            >
              <el-option
                v-for="item in pipeModelList"
                :key="item.dictCode"
                :label="item.name"
                :value="item.dictCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="公称直径:" prop="diameter"> 
            <el-select
              v-model="form.diameter"
              popper-class="gis-default"
              placeholder="请选择公称直径"
              :style="labelWidth"
            >
              <el-option
                v-for="item in diameterList"
                :key="item.code+''"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="实际外径(mm):" prop="outerDiameter">
            <el-input
              v-model="form.outerDiameter"
              maxlength="22"
              placeholder="请输入实际外径"
              :style="labelWidth"
            ></el-input>
          </el-form-item>
          <el-form-item label="壁厚(mm):" prop="thickness">
            <el-input
              v-model="form.thickness"
              placeholder="请输入壁厚"
              clearable
              :style="labelWidth"
              maxlength="22"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="实际内径(mm):" prop="innerDiameter">
            <el-input
              v-model="form.innerDiameter"
              maxlength="22"
              placeholder="请输入实际内径"
              :style="labelWidth"
            ></el-input>
          </el-form-item>
          <el-form-item label="粗糙度:" prop="roughness">
            <el-input-number
              v-model="form.roughness"
              :precision="2"
              :step="0.01"
              :max="999999.99"
              :min="0"
              :style="labelWidth"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="埋深(m):" prop="depth">
            <el-input
              v-model="form.depth"
              placeholder="请输入埋深"
              clearable
              :style="labelWidth"
              maxlength="22"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="压力级别:" prop="pressureLevel">
            <el-select
              v-model="form.pressureLevel"
              popper-class="gis-default"
              :style="labelWidth"
              placeholder="请选择压力级别"
            >
              <el-option
                v-for="item in pressureLevelList"
                :key="item.dictCode"
                :label="item.name"
                :value="item.dictCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
           <el-form-item label="设计压力:" >
            <el-input
              v-model="form.designPressure"
              placeholder="请输入设计压力"
              :style="labelWidth"
              maxlength="60"
            ></el-input>
          </el-form-item>
          <el-form-item label="运行压力:" >
            <el-input
              v-model="form.operationPressure"
              placeholder="请输入运行压力"
              :style="labelWidth"
              maxlength="60"
            ></el-input>
          </el-form-item>
         
         
          <el-form-item label="建设日期:" prop="buildDate">
            <el-date-picker
              v-model="form.buildDate"
              type="date"
              :style="labelWidth"
              :clearable="false"
              :editable="false"
              popper-class="datePickerClass"
              value-format="yyyy-MM-dd"
              placeholder="建设日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="燃气介质:" prop="gasType">
            <el-select
              v-model="form.gasType"
              popper-class="gis-default"
              :style="labelWidth"
              placeholder="请选择燃气介质"
            >
              <el-option
                v-for="item in gasTypeList"
                :key="item.dictCode"
                :label="item.name"
                :value="item.dictCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
         
          <el-form-item label="状态:" prop="pipeStatus">
            <el-select
              v-model="form.pipeStatus"
              popper-class="gis-default"
              :style="labelWidth"
              placeholder="请选择状态"
            >
              <el-option
                v-for="item in pipeStatusList"
                :key="item.dictCode"
                :label="item.name"
                :value="item.dictCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
           <el-form-item label="敷设方式:" prop="buildType">
            <el-select
              v-model="form.buildType"
              popper-class="gis-default"
              :style="labelWidth"
              placeholder="请选择敷设方式"
            >
              <el-option
                v-for="item in buildTypeList"
                :key="item.dictCode"
                :label="item.name"
                :value="item.dictCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          
          <el-form-item label="确认状态:" prop="confirmStatusCode">
            <el-select
              v-model="form.confirmStatusCode"
              popper-class="gis-default"
              :style="labelWidth"
              placeholder="请选择确认状态"
            >
              <el-option
                v-for="item in confirmStatusList"
                :key="item.dictCode"
                :label="item.name"
                :value="item.dictCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="下次检测日期:" prop="nextMonitorDate">
             <el-date-picker
              v-model="form.nextMonitorDate"
              type="date"
              :style="labelWidth"
              :clearable="false"
              :editable="false"
              popper-class="datePickerClass"
              value-format="yyyy-MM-dd"
              placeholder="下次检测日期"
            >
            </el-date-picker>
          </el-form-item>
         
          
          <el-form-item label="所在道路:" prop="road">
            <el-input
              v-model="form.road"
              placeholder="请输入所在道路"
              :style="labelWidthMax"
              maxlength="60"
            ></el-input>
          </el-form-item>
          <el-form-item label="建设单位:">
            <el-input
              v-model="form.buildOrganization"
              placeholder="请输入建设单位"
              :style="labelWidthMax"
              maxlength="60"
            ></el-input>
          </el-form-item>
          <el-form-item label="设计单位:">
            <el-input
              v-model="form.designOrganization"
              placeholder="请输入设计单位"
              :style="labelWidthMax"
              maxlength="60"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注:">
            <el-input
              v-model="form.remark"
              type="textarea"
              :rows="2"
              :style="labelWidthMax"
              maxlength="60"
              placeholder="请输入备注"
            ></el-input>
          </el-form-item>
        </el-form>
      <div class="btn">
         <el-button size="mini" @click="closeAll">取消</el-button>
        <el-button size="mini" type="primary" @click="submitAll">保存</el-button>
       
      </div>
    </el-dialog>

    <line-desc :id="allid" ref="LineDesc"></line-desc>
    <value-desc :id="allid" ref="ValueDesc" :postion="postion"></value-desc>
    <!-- <station-desc :id="allid" ref="StationDesc" :postion="postion"></station-desc> -->
    <equipment-desc ref="EquipmentDesc"></equipment-desc>
  </div>
</template>
<script>
import { queryFeatrue } from '../../apis/query'
import { commonDict, commonLayer, serverMap } from '../../utils/dict.js'
import { findDictTree, findCmpOptions, batchUpdate,selectDictListByParentCode,selectDictListByPid } from '../../apis/commonType'
import LineDesc from '../../dialog/lineDesc.vue'
import ValueDesc from '../../dialog/valueDesc.vue'
// import StationDesc from '../../dialog/stationDesc.vue'
import getNowFormatDate from '../../common/nowDate'
import downloadExcel from 'vue-json-excel'
import { pipeFindById, stationFindByIds } from '../../apis/tool'
import EquipmentDesc from '../../dialog/equipmentDesc.vue'
import { formatLocation } from '../../../Gis/utils/geoTool'

export default {
  components: { LineDesc, ValueDesc, downloadExcel, EquipmentDesc },
  data() {
    return {
      json_fields: {},
      tableData1: [],
      fileName: '',
      dialogVisible: false,
      item: {},
      activeName: 'one',
      tableData: [],
      search: { sortColumn: 'build_date', sortKey: 'A' },
      showListColumn: {
        one: [
          { label: '管线编码', propName: 'pipe_code', option: false, width: 120 },
          { label: '管线名称', propName: 'pipe_name', option: false, width: 150 },
          { label: '管材', propName: 'pipe_type', option: true, width: 100 },//100023
          { label: '管材类型', propName: 'pipe_model', option: true, width: 120 },//100023子
          { label: '公称直径', propName: 'diameter', option: true, width: 120 },//100024
          { label: '压力级别', propName: 'pressure_level_name', option: false, width: 120 },
          { label: '燃气介质', propName: 'gas_type_name', option: false, width: 120 },
          { label: '建设日期', propName: 'build_date', option: false, width: 120 },
          { label: '下次检测日期', propName: 'next_monitor_date', option: false, width: 200 },
          { label: '所在道路', propName: 'road', option: false, width: 200 },
          { label: '状态', propName: 'general_status', option: false, width: 200 },
          { label: '确认状态', propName: 'confirm_status_code', option: true, width: 200 }// 30011
        ],
        two: [
          { label: '设备编码', propName: 'value_code', option: false },
          { label: '名称', propName: 'value_name', option: false },
          { label: '阀门种类', propName: 'value_kind', option: true },//100003
        //  { label: '阀门设施', propName: 'valve_facilities', option: true },//300001
      
          { label: '厂家', propName: 'factory_code', option: false },
         { label: '连接方式', propName: 'connect_type', option: true },//100006
         
          { label: '安装日期', propName: 'build_date', option: false },
           { label: '设备状态', propName: 'switch_status_name', option: false },
        ],
        three: [
          { label: '场站编码', propName: 'station_code', option: false },
          { label: '场站名称', propName: 'station_name', option: false },
          { label: '场站类型', propName: 'station_type_name', option: false },
          { label: '场站分类', propName: 'station_kind', option: true },//100019 //100032
          
          // { label: '设计规模(Nm³/h)', propName: 'station_scale', option: false, width: 170 },
          { label: '地址', propName: 'address', option: false },
          { label: '经纬度', propName: 'location_str', option: false },
          { label: '位置', propName: 'address', option: false }
        ],
        five:[
          { label: '设备编码', propName: 'sbcode', option: false },
          { label: '名称', propName: 'sbname', option: false },
          { label: '设备类型', propName: 'sbtype', option: false },
          { label: '监测点', propName: 'point_name', option: false },
          { label: '经纬度', propName: 'location_str', option: false },
          { label: '规格', propName: 'spec_code', option: false },
          { label: '型号', propName: 'model_code', option: false },
          { label: '厂家', propName: 'factory_code', option: false },
          { label: '安装日期', propName: 'build_date', option: false },
          { label: '设备状态', propName: 'status_name', option: false }
        ]
      },
      page: 1,
      total: 50,
      size: 100,
      optionList: {},
      form: { diameter: [],pipeType:'1000230001' },
      tableName: {
        one: commonLayer.queryOne,
        two: commonLayer.queryTwo,
        three: commonLayer.queryThree,
        five: commonLayer.queryFive,
      },
      polyline: null,
      marker: null,
      dialogVisibleEdit: false,
      optionsM: [],
      dialogVisibleDesc: false,
      headerStyle: {
        'background-color': '#244162',
        'border-bottom': 'rgba(110, 183, 227, 0.2) 1px solid'
      },
      loading: false,
      params: {},
      allid: '',
      postion: '',
      optionsD: [],
      optionsShow: [],
      showD: false,
      idArr: [],
      buildTypeList: [],
      pipeTypeList: [],
      pipeModelList: [],
      diameterList: [],
      diameterData: [],
      pressureLevelList: [],
      gasTypeList: [],
      pipeStatusList: [],
      standardList: [],
      antisepticTypeList: [],
      confirmStatusList:[],
      labelWidth: 'width:140px',
      labelWidthMax: 'width:405px',
      rules: {
        // roughness: [{ required: true, message: '请输入粗糙度', trigger: ['blur', 'change'] }],
        pipeName: [{ required: true, message: '请输入管道名称', trigger: 'blur' }],
        pipeType: [{ required: true, message: '请选择管材', trigger: 'change' }],
        // pipeModel: [{ required: true, message: '请选择管材型号', trigger: 'change' }],
        diameter: [{ required: true, message: '请选择公称直径', trigger: 'change' }],
        pressureLevel: [{ required: true, message: '请选择压力级别', trigger: 'change' }],
        gasType: [{ required: true, message: '请选择燃气介质', trigger: 'change' }],
        // buildDate: [{ required: true, message: '请选择建设日期', trigger: 'change' }],
        pipeStatus: [{ required: true, message: '请选择状态', trigger: 'change' }],
        buildType:[{ required: true, message: '请选择敷设方式', trigger: 'change' }],
        confirmStatusCode:[{ required: true, message: '请选择确认状态', trigger: 'change' }],
        // nextMonitorDate:[{ required: true, message: '下次检测日期', trigger: 'change' }],
        innerDiameter: [
          {
            pattern: /(^[1-9]\d{0,5}?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: '数据格式不正确!'
          }
        ],
       outerDiameter: [
          {
            pattern: /(^[1-9]\d{0,5}?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: '数据格式不正确!'
          }
        ],
        thickness: [
          {
            pattern: /(^[1-9]\d{0,5}?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: '数据格式不正确!'
          }
        ],
        depth: [
          {
            pattern: /(^[1-9]\d{0,5}?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: '数据格式不正确!'
          }
        ]
      },
    }
  },
  mounted() {
    this.getCodeName()
  },
  methods: {
    pipeTypeChange(value, type) {
      if (type != 'edit') {
        this.form.pipeModel = ''
        this.form.diameter = ''
        this.pipeModelList = []
        this.diameterList = []
        this.$set(this.form, 'thickness', null)
        this.$set(this.form, 'realBore', null)
        this.form.roughness = value == '1000230002' ? 0.01 : 0.1
       value = this.form.pipeType 
      }
      for (let item of this.pipeTypeList) {
        if (item.dictCode == value) {
          this.pipeModelList = item.children
            this.setDiameterList(item.linkCode)
          break
        }
      }
      
    },
    setDiameterList(linkCode) {
      selectDictListByPid({pid:linkCode}).then(res=>{
        this.diameterList = res.data
      })
    },
    getCodeNameNew(type, value) {
      let that = this
      let code =
        commonDict[100023].code +
        ',' +
        commonDict[100024].code +
        ',' +
        commonDict[100028].code +
        ',' +
        commonDict[100029].code +
        ',' +
        commonDict[100004].code +
        ',' +
        commonDict[30011].code
      findDictTree({ dictCode: code }).then(res => {
        if (res.data) {
          let pipeTypeData = res.data[commonDict[100023].code]
          that.pipeTypeList = pipeTypeData
          that.pressureLevelList = serverMap[1006]
          that.gasTypeList = serverMap[1008]
          that.pipeStatusList = serverMap[1007]
          that.standardList = res.data[commonDict[100028].code]
          that.antisepticTypeList = res.data[commonDict[100029].code]
          that.buildTypeList = res.data[commonDict[100004].code]
          that.confirmStatusList = res.data[commonDict[30011].code]
          console.log(value)
          this.pipeTypeChange(value, type)
        }
      })
    },
    resetActivePosition($el) {
      this.$nextTick(() => {
        const activeEl = $el.querySelector('.el-tabs__item.is-active')
        const lineEl = $el.querySelector('.el-tabs__active-bar')
        const style = getComputedStyle(activeEl)
        const w = style.width.match(/\d+/)[0] * 1
        lineEl.style.transform = 'translateX(' + activeEl.offsetLeft + 'px)'
        lineEl.style.width = 28 + 'px'
      })
    },
    getDiameter(val) {
      let pipeType = this.optionsM.find(el => el.dictCode == val[0]).linkCode
      let list = []
      this.showD = false
      selectDictListByPid({pid:pipeType}).then(res=>{
        this.showD = true
        this.optionsShow = res.data
      })
      
    },
    async getData11() {
      if (this.activeName == 'one') {
        this.fileName = '管线_' + getNowFormatDate()
      }
      if (this.activeName == 'two') {
        this.fileName = '阀门_' + getNowFormatDate()
      }
      if (this.activeName == 'three') {
        this.fileName = '场站_' + getNowFormatDate()
      }
      if (this.activeName == 'five') {
        this.fileName = '仪表设备_' + getNowFormatDate()
      }
      this.json_fields = {'序号':'num'}
      this.showListColumn[this.activeName].forEach(el => {
        this.json_fields[el.label] = el.propName
      })
      let data = this.params
      data.startIndex = 0
      data.maxFeatures = 10000000
      const loading = this.$loading({
        lock: true,
        text: '正在查询，请稍后',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.tableData1 = []
      this.idArr = []
      await queryFeatrue(data).then(res => {
        this.loading = false
        if (res.totalFeatures > 0) {
          let i = 1;
          res.features.forEach(el => {
            el.properties.num = i;
            i++;
            el.properties.pipe_type = this.optionList[el.properties.pipe_type]
            el.properties.pipe_model = this.optionList[el.properties.pipe_model]
            el.properties.diameter = this.optionList[el.properties.diameter]
            el.properties.confirm_status_code = this.optionList[el.properties.confirm_status_code]
            el.properties.value_kind = this.optionList[el.properties.value_kind]
            el.properties.valve_facilities = this.optionList[el.properties.valve_facilities]
            el.properties.connect_type = this.optionList[el.properties.connect_type]
            el.properties.station_kind = this.optionList[el.properties.station_kind]
            
             if (el.properties.location_str) {
              el.properties.location_str = el.properties.location_str
                .replace('POINT(', '')
                .replace(')', '')
            }
            this.tableData1.push(el.properties)
            loading.close()
          })
        } else {
          this.$message.warning('暂无数据')
          this.tableData1 = []
          loading.close()
        }
      })
    },
    /*
     ****************************************************************************************************
     * 功能描述： 初始换数据
     * 开发人： 黄丽玲
     * 开发时间： 2022-5-24
     ****************************************************************************************************
     */
    initialize(e) {
      this.$parent.isClick = false
      this.item = e
      this.dialogVisible = true
      // this.$parent.map.setStatus({
      //   dragEnable: false,
      //   zoomEnable: false
      // })
      // this.$parent.$refs.leftTop.toolActive = ''
      this.resetActivePosition(this.$refs.tabs.$el)
      this.getData()
    },
    /*
     ****************************************************************************************************
     * 功能描述： 标签切换
     * 开发人： 黄丽玲
     * 开发时间： 2022-5-24
     ****************************************************************************************************
     */
    handleClick() {
      this.$refs.multipleTable.clearSort()
      this.search = { sortColumn: 'build_date', sortKey: 'A' }
      this.getData(1)
    },
    /*
     ****************************************************************************************************
     * 功能描述：显示详情
     * 开发人： 黄丽玲
     * 开发时间： 2022-5-24
     ****************************************************************************************************
     */
    showDesc(row) {
      this.allid = row.id.toString()

      this.postion = row.geometry.coordinates.toString()
      if (this.activeName == 'one') {
        this.$refs.LineDesc.openDesc(true)
      }
      if (this.activeName == 'two') {
        this.$refs.ValueDesc.openDesc(true)
      }
      if (this.activeName == 'three') {
        //  this.$refs.StationDesc.openD(this.allid)

        stationFindByIds({ id: row.id }).then(res => {
          let obj = {
            geometryType: null,
            equipmentType: 'userSta',
            title: '用户站',
            ...res.data
          }
          if (row.station_type_name != '用户场站') {
            ;(obj.equipmentType = ''), (obj.title = '供气站')
          }
          obj.cz = true
          obj.point = row.geometry.coordinates[0] + ' ' + row.geometry.coordinates[1]
          obj.pointId = row.id
          this.$parent.$refs.stationInfo.openDialog(obj, row.geometry.coordinates)
        })
      }
      if(this.activeName == 'five'){
        this.$refs.EquipmentDesc.openD(row.myid,row.sbtype)
      }
    },
    /*
     ****************************************************************************************************
     * 功能描述： 查看列表
     * 开发人： 黄丽玲
     * 开发时间： 2022-5-24
     ****************************************************************************************************
     */
    getData(page) {
      if (page) {
        this.page = 1
      }
      let pos = []
      this.item.line.forEach(element => {
        pos.push(element.lng + ' ' + element.lat)
      })
      pos.push(pos[0])
      let posStr = pos.join(',')
      let groupLayer = this.$store.getters.groupLayer
      let notGroupLayer = this.$store.getters.notGroupLayer
      let group = []
      let sqlwhere = ''
      if (notGroupLayer && notGroupLayer.length ==1&&notGroupLayer[0].id=='-100') {

      }else{
        if(groupLayer && notGroupLayer&&groupLayer.length<=notGroupLayer.length){
          groupLayer.forEach(item => {
            group.push(item.id)
          })
          sqlwhere += `  layer_id  in (${group.toString()})`
        }
        if(groupLayer && notGroupLayer&&groupLayer.length>notGroupLayer.length){
          notGroupLayer.forEach(item => {
            group.push(item.id)
          })
          sqlwhere += `  layer_id not in (${group.toString()})`
        }
      }
      
      // if (groupLayer && groupLayer.length > 0) {
      //   groupLayer.forEach(item => {
      //     group.push(item.id)
      //   })
      // }
      let layersVue1 = group.toString()
      this.params = {
        SERVICE: 'wfs',
        VERSION: '1.0.0',
        REQUEST: 'GetFeature',
        sortBy: this.search.sortColumn + ' ' + this.search.sortKey,
        typeName: this.tableName[this.activeName],
        startIndex: (this.page - 1) * this.size,
        maxFeatures: this.size,
        outputFormat: 'application/json',
        CQL_FILTER:
        sqlwhere + ' and INTERSECTS(location,POLYGON((' + posStr + ')))'
      }
      if (!layersVue1) {
        this.params.CQL_FILTER = ' INTERSECTS(location,POLYGON((' + posStr + ')))'
      }
      this.tableData = []
      this.loading = true
        queryFeatrue(this.params).then(res => {
          this.loading = false
          this.total = res.totalFeatures
          res.features.forEach(el => {
            el.properties.geometry = el.geometry
            this.tableData.push(el.properties)
            this.$refs.multipleTable.doLayout()
          })
        })
    },
    /*
     ****************************************************************************************************
     * 功能描述：获取查询file
     * 开发人： 黄丽玲
     * 开发时间： 2022-5-24
     ****************************************************************************************************
     */
    getFiles(pos) {
      let groupLayer = this.$store.getters.groupLayer
      let file =
        '<Filter xmlns="http://www.opengis.net/ogc" xmlns:gml="http://www.opengis.net/gml">' +
        ' <And> <Or>  '
      if (groupLayer && groupLayer.length > 0) {
        groupLayer.forEach(item => {
          file +=
            '<PropertyIsEqualTo><PropertyName>layer_id</PropertyName><Literal>' +
            item.id +
            '</Literal></PropertyIsEqualTo>'
        })
      }
      file +=
        '</Or><Intersects><PropertyName>location</PropertyName><gml:Polygon>' +
        '<gml:outerBoundaryIs>' +
        '<gml:LinearRing>' +
        '<gml:coordinates>' +
        pos +
        '</gml:coordinates>' +
        '</gml:LinearRing>' +
        '</gml:outerBoundaryIs>' +
        '</gml:Polygon></Intersects></And></Filter>'
      return file
    },
    /*
     ****************************************************************************************************
     * 功能描述： 关闭窗口
     * 开发人： 黄丽玲
     * 开发时间： 2022-5-24
     ****************************************************************************************************
     */
    colse() {
      this.$parent.isClick = true
      this.dialogVisible = false
      this.$parent.isEdit = false
      this.activeName = 'one'
      this.tableData = []
       this.$parent.clearDraw()
      this.search = { sortColumn: 'build_date', sortKey: 'A' }
      if (this.polyline) {
        this.$parent.map.remove(this.polyline)
      }
      if (this.marker) {
        this.$parent.map.remove(this.marker)
      }
      if(!this.$parent.gis){
        this.$parent.$refs.LeftTop.leftTopActive = ''
      }
      
      // this.$parent.$refs.topTool.clearTopTool()
    },
    /*
     ****************************************************************************************************
     * 功能描述： 数据分页
     * 开发人： 黄丽玲
     * 开发时间： 2022-5-25
     ****************************************************************************************************
     */
    handleSizeChange(val) {
      this.size = val
      this.getData(1)
    },
    /*
     ****************************************************************************************************
     * 功能描述： 数据分页
     * 开发人： 黄丽玲
     * 开发时间： 2022-5-25
     ****************************************************************************************************
     */
    handleCurrentChange(val) {
      this.page = val
      this.getData()
    },
    /*
     ****************************************************************************************************
     * 功能描述： 获取字典数据
     * 开发人： 黄丽玲
     * 开发时间： 2022-5-24
     ****************************************************************************************************
     */
    getCodeName() {
      let code =
        commonDict[100023].code +
        ',' +
        commonDict[100024].code +
        ',' +
        commonDict[100003].code +
        ',' +
        commonDict[30011].code +
        ',' +
        commonDict[100006].code +
        ',' +
        commonDict[300001].code+
        ',' +
        commonDict[100019].code+
        ',' +
        commonDict[100032].code
      findDictTree({ dictCode: code }).then(res => {
        if (res.data) {
          if (res.data[commonDict[100023].code]) {
            res.data[commonDict[100023].code].forEach(el => {
              this.optionList['' + el.dictCode] = el.name
              if (el.children) {
                el.children.forEach(element => {
                  this.optionList['' + element.dictCode] = element.name
                })
              }
            })
          }
          this.optionsM = res.data[commonDict[100023].code]
          if (res.data[commonDict[100024].code]) {
            res.data[commonDict[100024].code].forEach(el => {
              this.optionList[el.dictCode] = el.name
              if (el.children) {
                el.children.forEach(element => {
                  this.optionList[element.dictCode] = element.name
                })
              }
            })
          }

          this.optionsD = res.data[commonDict[100024].code]
          if (res.data[commonDict[100003].code]) {
            res.data[commonDict[100003].code].forEach(el => {
              this.optionList[el.dictCode] = el.name
            })
          }
          if (res.data[commonDict[30011].code]) {
            res.data[commonDict[30011].code].forEach(el => {
              this.optionList[el.dictCode] = el.name
            })
          }
          if (res.data[commonDict[100006].code]) {
            res.data[commonDict[100006].code].forEach(el => {
              this.optionList[el.dictCode] = el.name
            })
          }
          if (res.data[commonDict[300001].code]) {
            res.data[commonDict[300001].code].forEach(el => {
              this.optionList[el.dictCode] = el.name
            })
          }
           if (res.data[commonDict[100019].code]) {
            res.data[commonDict[100019].code].forEach(el => {
              this.optionList[el.dictCode] = el.name
            })
          }
           if (res.data[commonDict[100032].code]) {
            res.data[commonDict[100032].code].forEach(el => {
              this.optionList[el.dictCode] = el.name
            })
          }
        }
      })
    
    },
    /*
     ****************************************************************************************************
     * 功能描述： 排序
     * 开发人： 黄丽玲
     * 开发时间： 2022-5-25
     ****************************************************************************************************
     */
    sort_change(column) {
      this.search.sortColumn = column.prop
      if (column.order == 'ascending') {
        this.search.sortKey = 'A'
      } else {
        this.search.sortKey = 'D'
      }
      this.getData(1)
    },
    /*
     ****************************************************************************************************
     * 功能描述： 定位到地图
     * 开发人： 黄丽玲
     * 开发时间： 2022-5-25
     ****************************************************************************************************
     */
    locationMap(row) {
      var that = this
      if (this.activeName == 'one') {
        //添加一个线
        if (this.polyline) {
          this.$parent.map.remove(this.polyline)
        }
        this.polyline = new AMap.Polyline({
          path: formatLocation(row.location_str,'LineString'),
          isOutline: true,
          outlineColor: '#1A5F48',
          borderWeight: 3,
          strokeColor: '#00FF7F',
          strokeOpacity: 1,
          strokeWeight: 3,
          strokeStyle: 'solid',
          lineJoin: 'round',
          lineCap: 'round',
          zIndex: 150,
          extData: row
        })
        this.polyline.on('click', function(e) {
          const obj = e.target.getExtData()
          obj.id = row.id
          that.showDesc(obj)
        })
        this.$parent.map.add(this.polyline)
        this.$parent.map.setCenter(row.geometry.coordinates[0])
      } else {
        //添加一点
        if (this.marker) {
          this.$parent.map.remove(this.marker)
        }
        this.marker = new AMap.Marker({
          content:
            '<div> <div class="scatterPoint scatterPoint2"></div>' +
            '<div class="scatterPoint scatterPoint3" ></div></div>',
          offset: new AMap.Pixel(0, 0),
          position: row.geometry.coordinates,
          extData: row
        })
        this.marker.on('click', function(e) {
          const obj = e.target.getExtData()
          that.showDesc(obj)
        })

        this.$parent.map.add(this.marker)
        this.$parent.map.setCenter(row.geometry.coordinates)
      }
    },
    editAll() {
      let groupLayer = this.$store.getters.groupLayer
      if(groupLayer&&groupLayer.length!=1){
        this.$message.error('请在单一图层进行修改');
        return
      }
      if(this.tableData.length>0){
        this.dialogVisibleEdit = true
        pipeFindById({id:this.tableData[0].id}).then(res=>{
          if(res.data){
            this.form = res.data
          }
          this.getCodeNameNew('edit',this.form.pipeType)
        })
        
       
      }else{
        this.$message.error('暂无数据')
      }
      
    },
    submitAll() {
      let that = this
      that.$refs.form.validate(valid => {
        if (valid) {
            this.$confirm('是否对管线进行批量修改', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            customClass: 'gis-default',
            type: 'warning',
            closeOnClickModal: false,
            distinguishCancelAndClose: true
          })
            .then(() => {
              let data = that.form
              let data1 = this.params
              data1.startIndex = 0
              data1.maxFeatures = 10000000
              const loading = this.$loading({
                lock: true,
                text: '正在查询，请稍后',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
              })
              this.tableData1 = []
              this.idArr = []
              queryFeatrue(data1).then(res => {
                //this.loading = false
                let layerId = res.features[0].properties.layer_id;
                 let isLayerId = false;
                res.features.forEach(el => {
                  if(layerId!=el.properties.layer_id){
                    isLayerId = true
                    return 
                  }
                  this.idArr.push(el.properties.id)
                })
                loading.close()
                if(isLayerId){
                  this.$message.error('请在单一图层进行修改');
                  return
                }
                data.ids = this.idArr
                batchUpdate(data).then(res => {
                  if (res.code == '200') {
                    this.$message.success(res.msg)
                    this.getData()
                    this.$eventBus.$emit('setFilter')
                    this.dialogVisibleEdit = false
                  }
                })
              })
            })
            .catch(action => {
              if (action == 'cancel') {
                this.dialogVisibleEdit = false
              }
            })
        }
        })
    },
    closeAll() {
      this.formData = { pipeModel: [], diameter: [] }
      this.dialogVisibleEdit = false
      this.showD = false
    },
    move(e) {
      let odiv = this.$refs.dragBox
      let disX = e.clientX - odiv.offsetLeft
      let disY = e.clientY - odiv.offsetTop
      document.onmousemove = e => {
        let left = e.clientX - disX
        let top = e.clientY - disY
        this.positionX = top
        this.positionY = left

        odiv.style.left = left + 'px'
        odiv.style.top = top + 'px'
      }
      document.onmouseup = e => {
        document.onmousemove = null
        document.onmouseup = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.show-border{
  border-bottom: var(--form-border) 1px solid;
 height: 42px;}
::v-deep .query .el-dialog {
  background: var(--background-color-skin);
  border: 1px solid var(--border-color-skin);
  border-radius: 2px;
}
::v-deep .query .el-dialog__header {
  display: none;
}
::v-deep .query .el-dialog__body {
  padding: 0px 20px 15px 20px;
}
::v-deep .el-tabs__item.is-active {
  color: var(--font-color-b1);
  font-size: 18px;
  font-weight: bold;
}
::v-deep .el-tabs__item {
  color: var(--font-color-f1);
}
::v-deep .el-tabs__active-bar {
  background-color: var(--font-color-b1);
  height: 2px;
  font-weight: 400;
  font-size: 16px;
}
::v-deep .el-tabs__nav-wrap::after {
  content: none;
}
::v-deep .el-tabs__header {
  margin: 0px;
}
::v-deep .el-button--text {
  background: transparent !important;
  border: none;
}
.caozu {
  display: inline-block;
  height: 40px;
  font-size: 14px;
  width: 656px;
  text-align: right;
  vertical-align: text-bottom;
  line-height: 32px;
  color: var(--font-color-c1);
}
.colseBut {
  font-size: 26px;
  cursor: pointer;
}
.activeBut {
  margin-right: 46px;
  cursor: pointer;
}
.butleft {
  margin-left: 17px;
}
.pageBox {
  margin-top: 10px;
}
::v-deep .el-table {
  background-color:var(--table-background);
}
::v-deep .el-table--border .el-table__cell, .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
    border-right: 1px solid var(--table-border);
}
::v-deep .el-table td.el-table__cell {
  border-bottom: rgba(110, 183, 227, 0.2) 1px solid;
  color: var(--font-color-top);
}
::v-deep .el-table th.el-table__cell{color: var(--font-color-f1);background-color: var(--background-title-skin);}
::v-deep .el-table__body tr > td {
  background-color: var(--table-background);
}
::v-deep .el-table__body tr:hover > td {
  background-color: var(--table-background);
}
::v-deep .el-table__body tr.hover-row > td.el-table__cell {
  background-color: rgba($color: var(--background-hover-skin), $alpha: 0.9);
}
::v-deep .el-table .el-table__cell{
  padding: 0;
}

::v-deep .hover-row {
  background: var(--background-hover-skin);
}
::v-deep .el-table--border::after,
.el-table--group::after,
.el-table::before {
  background-color: var(--background-hover-skin);
}

::v-deep .el-table__fixed-body-wrapper {
  background-color: rgba($color: var(--background-hover-skin), $alpha: 0.9);
}
::v-deep .el-table__fixed-right::before {
  background-color: rgba($color: var(--background-hover-skin), $alpha: 0.9);
}
::v-deep .el-pagination span:not([class*='suffix']),
.defaultTheme .el-pagination button {
  // color: #fff;
}
.dialog-class.el-dialog {
  background: rgba(8, 31, 48, 0.8);
  border: 1px solid #5d99be;
  .el-dialog__header {
    background-image: linear-gradient(to right, RGBA(39, 91, 130, 0.9), rgba(21, 32, 47, 0.8));
    padding: 10px 20px;
  }
  .el-dialog__body {
    padding: 20px;
  }
  .el-dialog__headerbtn {
    top: 11px;
  }
  .el-dialog__headerbtn i {
    content: '\e79d';
    color: #8aabc3;
    font-size: 24px;
  }
  .el-dialog__headerbtn i:before {
    content: '\e79d';
    font-size: 24px;
  }
}
.btn {
  text-align: right;
  button {
    margin: 7px 15px 0 15px;
  }
}
.layerBox {
  position: absolute;
  top: 2.2rem;
  left: 5rem;
  height: 523px;
  width: 1280px;
  // border: 1px solid var(--border-color-skin);
   box-shadow: 0px 1px 6px 0px rgba(4, 57, 138, 0.3);
  background: var(--background-color-skin);
  z-index: 2000;
  padding: 0px 20px 10px 20px;
  opacity: 0.98;
}
</style>
