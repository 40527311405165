<template>
  <div>
    <div v-show="dialogVisible" id="dragBox" ref="dragBox" class="layerBox">

      <div class="title" @mousedown="move">
        <div>
          <span class="title-content">
            {{ form.stationName ? form.stationName : '场站' }}
          </span>
          <span class="caoz">
            <i class="iconfont icon-shanchu2" style="float: right; padding-right: 10px;;color: #fff;" @click="close()"></i>
            <i class="iconfont icon-yidong" style="float: right; padding-right:13px;color: #fff;"></i>
          </span>

        </div>
      </div>
      <div v-if="form.stationType == '1'" style=" overflow: auto;" class="hydrogen">
        <el-form ref="form" :model="form" label-width="75px" class="desform">
          <!-- <el-form-item label="所属图层 :" prop="code">
          <span class="spanDesc">{{ form.layerName }}</span>
        </el-form-item> -->
          <el-form-item label="站点类型 :" prop="type">
            <span class="spanDesc">{{ form.stationTypeName }}</span>
          </el-form-item>
          <!-- <el-form-item label="设计规模 :" prop="type">
              <span class="spanDesc">{{ form.scale }}(Nm³/h)</span>
        </el-form-item> -->
          <el-form-item label="所在位置:" prop="type">
            <label slot="label">所在位置:</label>
            <!-- <span class="spanDesc" :title="form.positionDes">{{ form.positionDes }}</span> -->
            <span class="spanDesc" :title="form.address">{{ form.address }}</span>
          </el-form-item>
          <!-- <div :class="radios == 1 ? 'videoTitle' : 'videoTitle filaty'" @click="changeInfo(1)"><span>◆</span> 重点监测点位
          </div>
          <div :class="radios == 1 ? 'videoTitle filaty' : 'videoTitle'" @click="changeInfo(2)"><span>◆</span> 重点监控视频
          </div> -->
          <div class="videoTitles">
            <div class="active">重点监测点位</div>
            <div  @click="changeInfo(2)">重点监控视频</div>
          </div>
          <div class="ulDom">   
            <template v-if="tableData.length">
              <div v-for="(item,index) in tableData" :key="index" class="liDom">
                <div class="textOver">{{item.pointName}}</div> 
                <div class="textOver">{{item.val}}{{item.unit}}</div> 
              </div>
            </template>
            <template v-else>
              <div class="noUlData">
                暂无数据
              </div>
            </template>
          </div>
          <!-- <el-table v-if="radios == 1" :data="tableData" id="dbM1" style="width:96%;margin:0" :header-cell-style="{
            height: '30px',
            width: '100%',
            color: '#5BD3FF',
            fontSize: '14px',
            border: '0'
          }">
            <el-table-column prop="pointName" show-overflow-tooltip label="点位名称">
            </el-table-column>
            <el-table-column prop="val" label="实时数据">
              <template slot-scope="{row}">
                <div>{{ row.val }} {{ row.unit }}</div>
              </template>
            </el-table-column>
          </el-table> -->


          <!-- <el-row v-else :gutter="10">
          <el-col :span="8">
            <div id="video1" class="grid-content"  @click="openVideoDia('192.168.1.31')">
              <span class="el-icon-caret-right" style="color:#fff"></span>
              <img src="/video/192.168.1.31.png" alt="">
            </div>
          </el-col>
          <el-col :span="8"><div id="video2" class="grid-content" @click="openVideoDia('192.168.1.32')"><span class="el-icon-caret-right" style="color:#fff"></span><img src="/video/192.168.1.32.png" alt="">
            </div></el-col>
          <el-col :span="8"><div id="video3" class="grid-content" @click="openVideoDia('192.168.1.33')"><span class="el-icon-caret-right" style="color:#fff"></span><img src="/video/192.168.1.33.png" alt=""></div></el-col>
          <el-col :span="8"><div id="video4" class="grid-content" @click="openVideoDia('192.168.1.34')"><span class="el-icon-caret-right" style="color:#fff"></span><img src="/video/192.168.1.34.png" alt=""></div></el-col>
          <el-col :span="8"><div id="video5" class="grid-content" @click="openVideoDia('192.168.1.70')"><span class="el-icon-caret-right" style="color:#fff"></span><img src="/video/192.168.1.70.png" alt=""></div></el-col>
          <el-col :span="8"><div id="video6" class="grid-content" @click="openVideoDia('192.168.1.221')"><span class="el-icon-caret-right" style="color:#fff"></span><img src="/video/192.168.1.221.png" alt=""></div></el-col>
        </el-row> -->

          <!-- <el-form-item label="经纬度 :" prop="type">
          <label slot="label">经  纬  度 :</label>
              <span class="spanDesc">{{ postion }}</span>
        </el-form-item> -->
          <!-- <el-form-item label="场站地址 :" prop="type">
              <span class="spanDesc">{{ form.address }}</span>
        </el-form-item> -->
          <!-- <el-form-item label="远传数据 :" prop="type">
          <span class="spanDesc"></span>
        </el-form-item> -->
          <!-- <el-divider content-position="left">{{item.pointName}}</el-divider> -->
          <!-- <el-row v-for="item,index in form.realtimeList" :key="index">
            <div class="pullIn">{{item.pointName}}</div>
            <el-form-item :label="item.channelType+'压力 :'" prop="type">
              <span class="spanDesc">{{ item.pressure }}<el-tag v-if="item.pressureWarn==2" style="float: right;margin-right: 20px;margin-top: 5px;" size="mini" type="danger">数据异常</el-tag></span>
              
            </el-form-item>
            <el-form-item :label="item.channelType+'流量 :'" prop="type">
              <span class="spanDesc">{{ item.flow }}<el-tag v-if="item.flowWarn==2" style="float: right;margin-right: 20px;margin-top: 5px;" size="mini" type="danger">数据异常</el-tag></span>
            </el-form-item>
        </el-row> -->

        </el-form>
        <!-- <div class="btn">
        <el-button type="primary" size="mini" @click="openVideo(form.id)">视频监控</el-button>
      </div> -->
      </div>
      <div v-else-if="form.stationType == '6'" style=" overflow: auto;" class="hydrogen">
        <el-form ref="form" :model="form" label-width="75px" class="desform">

          <el-form-item label="规格:" prop="type">
            <span class="spanDesc">{{ form.spec }}</span>
          </el-form-item>

          <el-form-item prop="type">
            <label slot="label">型号:</label>
            <span class="spanDesc" :title="form.mode">{{ form.mode }}</span>
          </el-form-item>

          <el-form-item label="安装日期:" prop="type">
            <span class="spanDesc">{{ form.installDate }}</span>
          </el-form-item>
          <el-form-item label="生产厂家:" prop="type">
            <span class="spanDesc">{{ form.factory }}</span>
          </el-form-item>
          <el-form-item label="设备状态:" prop="type">
            <span class="spanDesc">{{ form.equipmentStatusName }}</span>
          </el-form-item>
          <el-form-item label="详细地址:" prop="type">
            <span class="spanDesc" :title="form.address">{{ form.address }}</span>
          </el-form-item>
          <div class="videoTitleOne"><div></div> 重点监测点位</div>
          <div class="ulDom">   
            <template v-if="tableData.length">
              <div v-for="(item,index) in tableData" :key="index" class="liDom">
                <div class="textOver">{{item.pointName}}</div> 
                <div class="textOver">{{item.val}}{{item.unit}}</div> 
              </div>
            </template>
            <template v-else>
              <div class="noUlData">
                暂无数据
              </div>
            </template>
          </div>
          <!-- <div class="videoTitle"><span>◆</span> 检测点位
          </div>
          <el-table :data="tableData" style="margin: 0;width:90%" id="dbM1" :header-cell-style="{
            height: '30px',
            width: '100%',
            color: '#5BD3FF',
            fontSize: '14px',
            border: '0'
          }">
            <el-table-column prop="pointName" show-overflow-tooltip label="点位名称">
            </el-table-column>
            <el-table-column prop="val" label="实时数据">
              <template slot-scope="{row}">
                <div>{{ row.val }} {{ row.unit }}</div>
              </template>
            </el-table-column>
          </el-table> -->
        </el-form>
      </div>
      <div v-else style="max-height: 450px; overflow: auto;" class="hydrogen">
        <el-form ref="form" :model="form" label-width="75px" class="desform">
          <!-- <el-form-item label="所属图层 :" prop="code">
          <span class="spanDesc">{{ form.layerName }}</span>
        </el-form-item> -->
          <el-form-item label="站点类型 :" prop="type">
            <span class="spanDesc">{{ form.stationTypeName }}</span>
          </el-form-item>
          <!-- <el-form-item label="设计规模 :" prop="type">
              <span class="spanDesc">{{ form.scale }}(Nm³/h)</span>
        </el-form-item> -->
          <el-form-item label="所在位置:" prop="type">
            <label slot="label">所在位置:</label>
            <!-- <span class="spanDesc" :title="form.positionDes">{{ form.positionDes }}</span> -->
            <span class="spanDesc" :title="form.address">{{ form.address }}</span>
          </el-form-item>
          <!-- <el-form-item label="经纬度 :" prop="type">
          <label slot="label">经  纬  度 :</label>
              <span class="spanDesc">{{ postion }}</span>
        </el-form-item> -->

          <!-- <el-form-item label="场站地址 :" prop="type">
              <span class="spanDesc">{{ form.address }}</span>
        </el-form-item> -->
          <!-- <div v-if="form.stationType==2">
        <el-form-item label="用户数量 :" prop="type">
              <span class="spanDesc">{{ form.userCount }}</span>
        </el-form-item>
        <el-form-item label="居民 :" prop="type">
          <label slot="label">居       民 :</label>
              <span class="spanDesc">{{ form.residentCount }}</span>
        </el-form-item>
        <el-form-item label="商业 :" prop="type">
          <label slot="label">商       业 :</label>
              <span class="spanDesc">{{ form.businessCount }}</span>
        </el-form-item>
        <el-form-item label="工业 :" prop="type">
          <label slot="label">工       业 :</label>
              <span class="spanDesc">{{ form.industryCount }}</span>
        </el-form-item>
        </div> -->
          <!-- <el-form-item label="备注 :" prop="type">
          <label slot="label">备       注 :</label>
              <span class="spanDesc" :title="form.remark">{{ form.remark }}</span>
        </el-form-item> -->
          <!-- <el-form-item label="远传数据 :" prop="type">
          <span class="spanDesc"></span>
        </el-form-item> -->
          <!-- <div class="videoTitle"><span>◆</span> 远传数据</div> -->
          <!-- <el-form-item label="瞬时流量 :" prop="type">
          <span class="spanDesc"></span>
        </el-form-item> -->
          <!-- <el-form-item label="压力 :" prop="type">
          <span class="spanDesc">{{getRandomInt(16,20)}}Mpa,{{getRandomInt(16,20)}}Mpa</span>
        </el-form-item>
        <el-form-item label="温度 :" prop="type">
          <span class="spanDesc">{{getRandomInt2(-8,-3)}}℃</span>
        </el-form-item> -->
          <!-- <el-divider content-position="left">{{item.pointName}}</el-divider> -->
          <!-- <el-row v-for="item,index in form.realtimeList" :key="index">
            <el-form-item :label="item.channelType+'流量 :'" prop="type">
              <span class="spanDesc">{{ item.flow }}<el-tag v-if="item.flowWarn==2" style="float: right;margin-right: 20px;margin-top: 5px;" size="mini" type="danger">数据异常</el-tag></span>
            </el-form-item>
            <el-form-item :label="item.channelType+'压力 :'" prop="type">
              <span class="spanDesc">{{ item.pressure }}<el-tag v-if="item.pressureWarn==2" style="float: right;margin-right: 20px;margin-top: 5px;" size="mini" type="danger">数据异常</el-tag></span>
              
            </el-form-item>
            
        </el-row> -->
          <div class="videoTitleOne"><div></div> 重点监测点位</div>
          <div class="ulDom">   
            <template v-if="tableData.length">
              <div v-for="(item,index) in tableData" :key="index" class="liDom">
                <div class="textOver">{{item.pointName}}</div> 
                <div class="textOver">{{item.val}}{{item.unit}}</div> 
              </div>
            </template>
            <template v-else>
              <div class="noUlData">
                暂无数据
              </div>
            </template>
          </div>
          <!-- <el-table :data="tableData" id="dbM1" style="width:90%" :header-cell-style="{
            height: '30px',
            width: '100%',
            color: '#5BD3FF',
            fontSize: '14px',
            border: '0'
          }">
            <el-table-column prop="pointName" show-overflow-tooltip label="点位名称">
            </el-table-column>
            <el-table-column prop="val" label="实时数据">
              <template slot-scope="{row}">
                <div>{{ row.val }} {{ row.unit }}</div>
              </template>
            </el-table-column>
          </el-table> -->

        </el-form>
      </div>
      <div class="markbox_bottom"></div>
      <!-- </el-dialog> -->
    </div>
    <el-dialog
      :title="url"
      :visible.sync="dialogVisibleVideo"
      width="1520px"
      :close-on-click-modal="false"
      @close="closeHandle('bjform')"
      top="6vh"
      custom-class="dataDialog"
    >
      <template slot="title">
            <div class="dialogTitles">
                <span></span> <b style="color:#fff"> 重点监控视频 </b>
            </div>
        </template>
      <div class="videoDialog">
        <div class="videoBox">
            <div v-for="(item,index) in videoList" :key="index" :style="canClick ? '' : {cursor:'no-drop'}" :class="videoId == item.id ? 'textOver videoDom active' : 'textOver videoDom'" :title="item.videoName" @click="openVideoDia(item)">{{item.videoName}}</div>
        </div>
        <div class="videoRight">
          <!-- <video id="video" autoplay width="100%" height="100%"></video> -->
          <video-player v-show="canClick" ref="videoPlayer" :playsinline="true" :options="player"></video-player>
        </div>
      </div>
    </el-dialog>
    <!-- <video-list ref="videoList"></video-list> -->
  </div>
</template>
<script>
import { stationFindByIds ,selectStationByGisId} from '../apis/tool'
import scadaApi from "@/apis/scadaApi";
import videoApi from "@/apis/videoApi";
import global from "@/global";
// import videoList from './videoList.vue'
export default {
  // components: { videoList },
  props: {
    id: {
      type: String,
      default: () => ''
    },
    postion: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      tableData: [],
      form: {},
      dialogVisible: false,
      url: '',
      dialogVisibleVideo: false,
      player: {
        playbackRates: false,
        autoplay: true,
        muted: false,
        loop: false,
        preload: 'auto',
        language: 'zh-CN',
        aspectRatio: '16:9',
        fluid: true,
        sources: [
          {
            type: '',
            // src: 'http://106.41.119.10:17358/live/1.live.mp4'
            src: ''
          }
        ],
        poster: '', //你的封面地址
        notSupportedMessage: '无法获取视频，请联系相关人员',
        controlBar: {
          timeDivider: false,
          durationDisplay: false,
          remainingTimeDisplay: false,
          fullscreenToggle: true,
          progressControl: false,
        }
      },
      radios: 1,
      webRtcServer: null,
      videoList: [],
      videoId:'',
      canClick:true
    }
  },
  mounted() { },
  beforeDestroy() {
    if (this.webRtcServer) {
      this.webRtcServer.disconnect()
      this.webRtcServer = null
    }
  },
  methods: {
    changeInfo(type) {
      // this.radios = type
      let that = this
      if(type == 2){
        if(that.videoList.length){
          that.dialogVisibleVideo = true
          // if(!window.webRtcServer){
          // window.webRtcServer = new WebRtcStreamer('video', 'http://106.41.119.10:8215/')
          // }
          that.openVideoDia(that.videoList[0])
        }else{
          that.$message.warning('该站暂无监控设备')
        }
      }
    },
    playerOptions(url) {
      // '/video/192.168.1.31.mp4'
      let obj = {
        playbackRates: [0.5, 1.0, 1.5, 2.0],
        autoplay: true,
        muted: false,
        loop: false,
        preload: 'auto',
        language: 'zh-CN',
        aspectRatio: '16:9',
        fluid: true,
        sources: [
          {
            type: '',
            src: '/video/' + url + '.mp4'
          }
        ],
        poster: '', //你的封面地址
        notSupportedMessage: '此视频暂无法播放，请稍后再试',
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true
        }
      }
      return obj
    },
    openVideoDia(data){
      let that = this
      // let path = 'http://106.41.119.10:17358/live/'+(data.id)+'.live.mp4'
      // that.videoId = data.id
      // this.player.sources[0]['src'] = path

      // 获取流信息
      if(that.canClick){
        that.canClick = false
        let infoObj = {
          secret:'f7EqiLYsPfMLRdsTx5jo1g3QrbDkXQdY',
          schema:'rtsp',
          vhost:'__defaultVhost__',
          app:'live',
          stream:data.id,
          url:data.rtspAddress,
          auto_close:true
        }
        videoApi.getMediaInfo(infoObj).then(res => {
          that.videoId = data.id
          // if(that.webRtcServer){
          //   that.webRtcServer.disconnect()
          // }
          let path = global.baseUrlcom + '/zlmediakit/live/'+(data.id)+'.live.mp4'
          console.log('获取流信息',res,path)
          if(res.code == 0){
            that.canClick = true
            // 成功渲染
            that.player.sources[0]['src'] = path
          }else{
            // 失败推流
            videoApi.addStreamProxy(infoObj).then(son => {
              that.canClick = true
              if(son.code == 0){
                console.log('推流信息',son)
                that.player.sources[0]['src'] = path
              }else{
                that.player.sources[0]['src'] = ''
                // that.$message({message:'无法获取视频，请联系相关人员', customClass:'myMessageClass',type:'error'});
              }
            })
          }
        })
      }
      // this.$nextTick(() => {
      //   this.$refs.videoPlayer.player.play()
      // })
      // console.log(this.player)
      // this.url = url 
      // if(that.canClick){
      //   if(data.rtspAddress){
      //     that.canClick = false
      //     that.videoId = data.id
      //     setTimeout(() => {
      //       that.webRtcServer = new WebRtcStreamer('video', 'http://106.41.119.10:8215')
      //       let option = 'rtptransport=tcp'
      //       that.webRtcServer.connect(
      //         data.rtspAddress,
      //         null,
      //         option,
      //         null
      //       )
      //     }, 1000)
      //     setTimeout(() => {
      //       that.canClick = true
      //     }, 3000)
      //   }else{
      //     that.$message.warning('该设备无取流地址')
      //   }
      // }
    },
    closeHandle(){
      // this.url = ''
      this.player.sources[0]['src'] = ''
      if (this.webRtcServer) {
        this.webRtcServer.disconnect()
        this.webRtcServer = null
      }
      this.canClick = true
    },
    getRandomInt(min, max) {
      return (Math.floor(Math.random() * (max - min + 1)) + min) / 100; // 返回[min, max]区间内的随机整数
    },
    getRandomInt2(min, max) {
      return (Math.floor(Math.random() * (max - min + 1)) + min); // 返回[min, max]区间内的随机整数
    },
    close() {
      this.radios = 1
      this.dialogVisible = false
      this.form = {}
      this.videoList = []
    },
    openDesc(flag) {
      let that = this
      this.dialogVisible = flag;
    },
    openD(oldid) {
      let that = this
      selectStationByGisId({id:oldid}).then(res => {
        let id = res.data?.id ? res.data.id : oldid
        that.dialogVisible = true
        if(res.data?.id){
          // 已绑定scada场站
          // that.form = res.data

          // 获取重点监测点位列表
          scadaApi.getselectByScadaStationIdLists({ scadaStationId: id }).then(res => {
            if (res.code == 200) {
              that.tableData = res.data
            }
          })
          
          // 获取场站摄像头
          scadaApi.getVideoUrl({ stationId: id }).then(res => {
            if (res.code == 200) {
              that.videoList = res.data
            }
          })
        }
        // else{
          // 未绑定scada场站
          //获取数据的接口等待对接 
          stationFindByIds({ id: oldid }).then(res => {
            that.form = res.data
          })
        // }
      })
    },
    openWarn(data) {
      let that = this
      // this.form = data
      // this.form.stationName = data.equipmentName
      // this.form.stationType = '6'
      that.dialogVisible = true
      scadaApi.getEquipmentfindByIds({ id: data.id }).then(res => {
        if (res.code == 200) {
          that.form = res.data
          // that.form = data
          that.form.stationName = res.data.equipmentName
          that.form.stationType = '6'
        }
      })
      // 获取重点监测点位列表
      scadaApi.getselectByScadaStationIdLists({ scadaStationId: data.id }).then(res => {
        if (res.code == 200) {
          that.tableData = res.data
        }
      })
    },
    openVideo(id) {
      // this.$refs.videoList.openDialog(id)
    },
    move(e) {
      let odiv = this.$refs.dragBox
      let disX = e.clientX - odiv.offsetLeft
      let disY = e.clientY - odiv.offsetTop
      document.onmousemove = e => {
        let left = e.clientX - disX
        let top = e.clientY - disY
        this.positionX = top
        this.positionY = left

        odiv.style.left = left + 'px'
        odiv.style.top = top + 'px'
      }
      document.onmouseup = e => {
        document.onmousemove = null
        document.onmouseup = null
      }
    },

  }
}
</script>
<style lang="scss" scoped>
.myMessageClass{
  z-index: 6666 !important;
}
::v-deep.el-table .el-table__header .el-table__cell {
  background-color: transparent !important
}

::v-deep.el-table tr {
  background-color: transparent;
}

::v-deep.gis-default .el-table__empty-block {
  background-color: red !important
}

/* ::v-deep .video-js.vjs-fluid {
  font-size: 5px !important;
} */

/* ::v-deep .vjs-has-started .vjs-control-bar {
  display: none;
} */

/* ::v-deep #dialogVideo .video-js.vjs-fluid {
  font-size: 20px !important;
} */

::v-deep .vjs-modal-dialog  .vjs-modal-dialog-content{
  line-height: 630px !important;
}
::v-deep .vjs-error .vjs-error-display:before{
  content: '' !important;
}

::v-deep .el-dialog__header {
  background: #0f3857;
  padding-top: 10px;

  .el-dialog__title {
    color: #fff;
  }

  .el-dialog__headerbtn {
    top: 10px;
  }
}

::v-deep .el-dialog__body {
  background-color: #112541 !important;
  padding: 10px !important;
  .video-js .vjs-big-play-button{
    left:calc(50% - 60px) !important;
    top:300px !important;
    display: none !important;
  }
}
.ulDom{
  height: 156px;
  overflow: auto;
  .liDom{
    display: flex;
    margin-bottom: 2px;
    height: 28px;
    div{
      flex: 1;
      background: rgba(#005997, 0.3);
      padding-left: 10px;
      color: #BEDEFF;
      height: 28px;
      line-height: 28px;
    }
    div:last-child{
      background-color: rgba(#005997, 0.16);
    }
  }
}
.noUlData{
  height: 156px;
  line-height: 156px;
  color: #BEDEFF;
  text-align: center;
  font-size: 20px;
}
.videoDialog{
  height: 720px;
  display: flex;
  justify-content: space-between;
  .videoBox{
    height: 100%;
    padding-right: 10px;
    width: 216px;
    overflow-y: auto;
    
  }
  .videoBox::-webkit-scrollbar-thumb {
    background-color: #385c74;
  }
  .videoRight{
    width: calc(100% - 230px);
    background-image: url('/video/loading.gif');
    background-size: 100% 100%;
  }
}

.videoDom{
  height: 36px;
  line-height: 36px;
  padding: 0 15px;
  border: 1px solid #0070BF;
  border-radius: 4px;
  color: #BEDEFF;
  cursor: pointer;
  margin-bottom: 10px;
}
.videoDom.active{
  background: #0C6093;
  color: #fff;
}
.videoDom:hover{
  background: #0C6093;
  color: #fff;
}
.textOver{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.videoTitleOne{
  display: flex;
  align-items: center;
  height: 24px;
  background: linear-gradient(90deg, rgba(12,155,255,0.38), rgba(12,155,255,0));
  margin: 10px 0;
  color: #70E2FF;
  div{
    width: 1px;
    height: 18px;
    background: linear-gradient(0deg, rgba(112,226,255,0.99) 48%);
    margin-right: 8px;
  }
}
.videoTitles{
  display: flex;
  height: 24px;
  line-height: 24px;
  color: #5BD3FF;
  background: #124374;
  color: #4A9FC2;
  margin: 10px 0;
  border-radius: 4px;
  width: 200px;
  div{
    width: 100px;
    text-align: center;
    cursor: pointer;
  }
  div.active{
    background-color: #0F6DAE;
    color: #A0ECFF;
    border-radius: 4px 0 0 4px;
  }
}
.videoTitle {
  height: 24px;
  /* background: linear-gradient(90deg, rgba(1,108,218,0.9) 0%, rgba(1,108,218,0) 100%); */
  padding-left: 10px;
  color: #5BD3FF;
  margin: 10px 0;
  display: inline-block;
  width: 45%;

  span {
    font-size: 16px;
    color: #E18506;
  }

  cursor: pointer;
}

.videoTitle.filaty {
  filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -webkit-filter: grayscale(1);
}

.grid-content {
  height: 50px;
  margin-bottom: 10px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
  }

  span {
    position: absolute;
    top: 19px;
    left: 34px;
  }
}

.pullIn {
  background: url('../../../assets/br/pullIn.png') no-repeat 0 0;
  height: 24px;
  padding-left: 10px;
  color: #00C0FF;
  font-size: 12px;
  line-height: 24px;
  margin-top: 20px;
}

.btn {
  text-align: right;

  button {
    margin: 7px 15px 0 15px;
  }
}

.spanDesc {
  text-align: left;
  width: 180px;
  color: var(--font-color-c2);
  margin-left: 5px;
  line-height: 20px;
  display: inline-block;
  vertical-align: text-top;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

::v-deep .el-form-item {
  margin-bottom: 0px;
}

::v-deep .el-divider--horizontal {
  margin: 10px 0px;
}

.desform {
  text-align: left;
  margin: 10px 10px 0;
}

.title {
  height: 40px;
  line-height: 40px;
  padding: 0px 15px;
  // background: linear-gradient(90deg, var(--background-title-skin1), var(--background-title-skin2));
}

.title-content {
  color: var(--font-color-f1);
  font-weight: bold;
  font-size: 14px;
}

::v-deep .el-tabs__item.is-active {
  color: var(--font-color-f1);
}

::v-deep .el-dialog__wrapper {
  z-index: 20002 !important;
}

.layerBox {
  position: absolute;
  top: 2.2rem;
  left: 6rem;
  width: 360px;
  box-shadow: 0px 1px 6px 0px rgba(4, 57, 138, 0.3);
  background: url('../../../assets/br/bj1.png') no-repeat -5px -7px;
  background-size: 102.5% 16%;
  ::v-deep .el-form-item {
    margin-bottom: 0;
    width: 96%;
    border-bottom: 1px dashed #184583;
  }
}

.hydrogen {
  background: url('../../../assets/br/bj2.png') repeat 0 0;
  padding: 0 10px;
  background-size: contain;
}

.markbox_bottom {
  background: url('../../../assets/br/bj3.png') no-repeat -5px 0;
  height: 42px;
  background-size: 99.6% 100%;
  width: 370px;

}

::v-deep .el-form-item__label {
  text-align: left;
  color: #00C0FF !important;
}

::v-deep .el-form-item__content {
  color: #666666;
}

::v-deep #dbM1 {
  background: none;
  // width: 100%;
  // height: 100%;
  margin: 0.1rem;

  // border: 0.01rem solid #ffffff;
  .el-table__header {
    colgroup col[name='gutter'] {
      display: none;
      width: 0;
    }

  }

  .el-table__body-wrapper {

    &::-webkit-scrollbar {
      width: 0rem;
      display: none;
    }

    ::-webkit-scrollbar-track {
      // 滚动条轨道
      border: none;
    }

    .el-table__body {
      width: 100% !important;
    }
  }

  .gutter {
    width: 0 !important;
    display: none;

  }

  .el-table__header-wrapper {
    width: 100%;
    /* background: linear-gradient(90deg, rgba(18, 159, 252, 0.48), rgba(18, 159, 252, 0.12)); */
    background: rgba(0, 115, 222, 0.3);
    border: 1px solid #0050AC;
  }

  .el-table__row .el-table__cell {
    .cell {
      color: #ffffff;
    }
  }
}

::v-deep .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 0 !important;
}
</style>
