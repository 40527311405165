<template>
  <div>
    <el-dialog
      title="设备列表"
      :visible.sync="equipmentListVisible"
      width="60%"
      :modal="true"
      :close-on-click-modal="false"
      custom-class="dialog-class"
      center
    >
      <div class="addBtn">
        <el-button size="mini" @click="openAddDia()"> <i class="el-icon-plus"></i> 添加</el-button>
      </div>
      <div>
        <el-table :data="tableData" style="width: 100%" :border="true">
          <el-table-column type="index" label="序号" width="50"></el-table-column>
          <el-table-column prop="eqName" label="设备名称"></el-table-column>
          <el-table-column prop="eqTypeName" label="设备类型"></el-table-column>
    
          <el-table-column prop="eqStatusName" label="设备状态"></el-table-column>
          <el-table-column prop="installDate" label="安装日期" width="100"></el-table-column>
          <el-table-column prop="transmitName" label="是否远传" width="80"></el-table-column>
          <el-table-column prop="onlineName" label="是否在线" width="80"></el-table-column>
          <el-table-column
            prop="point"
            show-overflow-tooltip
            label="经纬度"
            width="70"
          ></el-table-column>
         
          <el-table-column label="操作" width="130" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" class="btnText" size="mini" @click="editEquipment(scope.row)"
                >编辑</el-button
              >
              <el-button
                type="text"
                class="btnText danger"
                size="mini"
                @click="deleteEquipment(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>

    <el-dialog
      title="仪表设备"
      :visible.sync="equipmentVisible"
      :modal="true"
      :close-on-click-modal="false"
      width="700px"
      custom-class="dialog-class"
      @close="closeEquipment"
    >
      <el-form
        ref="formEq"
        :inline="true"
        :model="formEq"
        :rules="rulesEquipment"
        label-position="right"
        label-width="100px"
        size="mini"
      >
        <el-form-item v-if="formEq.id" label="设备编号:">
          <el-input
            v-model="formEq.flowCode"
            :style="labelWidthMax"
            disabled
            placeholder="请输入设备编号"
          ></el-input>
        </el-form-item>

        <el-form-item label="设备名称:" prop="flowName">
          <el-input
            v-model="formEq.flowName"
            :style="labelWidthMax"
            placeholder="请输入设备名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="设备类型:" prop="eqType">
          <el-select
          :style="labelWidth"
            v-model="formEq.eqType"
            :disabled="formEq.id ? true : false"
            popper-class="gis-default"
            placeholder="请选择设备类型"
          >
            <el-option
              v-for="item in eqTypeList"
              :key="item.dictCode"
              :label="item.name"
              :value="item.dictCode"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备状态:" prop="generalStatus">
          <el-select
          :style="labelWidth"
            v-model="formEq.generalStatus"
            popper-class="gis-default"
            placeholder="请选择状态"
          >
            <el-option
              v-for="item in generalStatusList"
              :key="item.dictCode"
              :label="item.name"
              :value="item.dictCode"
            >
            </el-option>
          </el-select>
        </el-form-item>
        
        
        <el-form-item label="规格:">
          <el-input
            v-model="formEq.spec"
            :style="labelWidth"
            maxlength="40"
            placeholder="请输入规格"
          ></el-input>
        </el-form-item>
        <el-form-item label="型号:">
          <el-input
            v-model="formEq.model"
            :style="labelWidth"
            maxlength="40"
            placeholder="请输入型号"
          ></el-input>
        </el-form-item>
        <el-form-item label="安装日期:" prop="installDate">
          <el-date-picker
            v-model="formEq.installDate"
            value-format="yyyy-MM-dd"
            type="date"
            :clearable="false"
            :editable="false"
            :style="labelWidth"
            popper-class="datePickerClass"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
         <el-form-item label="是否远传:" >
          <el-select
          :style="labelWidth"
            v-model="formEq.transmit"
            popper-class="gis-default"
            placeholder="请选择是否远传"
          >
            <el-option
              v-for="item in transmitList"
              :key="item.dictCode"
              :label="item.name"
              :value="item.dictCode"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否在线:" >
          <el-select
          :style="labelWidth"
            v-model="formEq.online"
            popper-class="gis-default"
            placeholder="请选择是否在线"
          >
            <el-option
              v-for="item in onlineList"
              :key="item.dictCode"
              :label="item.name"
              :value="item.dictCode"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="厂家:">
          <el-input
            v-model="formEq.factory"
            :style="labelWidth"
            placeholder="请输入厂家"
            maxlength="40"
          ></el-input>
        </el-form-item>
      
        

        <el-form-item label="附着压力级别:" >
          <el-select
          :style="labelWidthMax"
            v-model="formEq.pressureLevel"
            popper-class="gis-default"
            placeholder="请选择附着压力级别"
          >
            <el-option
              v-for="item in pressureLevelList"
              :key="item.dictCode"
              :label="item.name"
              :value="item.dictCode"
            >
            </el-option>
          </el-select>
        </el-form-item>
       
        <el-form-item label="备注:">
         <el-input
              v-model="formEq.remark"
              type="textarea"
              :rows="2"
              :style="labelWidthMax"
              maxlength="60"
              placeholder="请输入备注"
            ></el-input>
        </el-form-item>
       
        
        <el-form-item label="经纬度:">
          <el-input
            v-model="formEq.point"
            disabled
            :style="labelWidthMax"
            placeholder="请输入经纬度"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="所在地址:" prop="address">
          <el-input
            v-model="formEq.address"
            :style="labelWidthMax"
            placeholder="请输入所在地址"
          ></el-input>
        </el-form-item> -->
      </el-form>
      <div class="btn">
        <el-button size="mini" @click="closeEquipment">取消</el-button>
        <el-button size="mini" type="primary" @click="submitEquipment">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  monitorFindById,
  saveFlow,
  savePressure,
  flowDelete,
  pressureDelete,
  flowFindById,
  pressureFindById,
  flowUpdateById,
  pressureUpdateById
} from '../apis/tool'
import {
  findDictTree,
  findManufacturerOptions,
  findSpecOptions,
  findTypeOptions
} from '../apis/commonType'
import { serverMap, commonDict } from '../utils/dict.js'
import { formatTime, isEmpty } from '../utils/common.js'
import { getCompanyName, getCompanyId } from '../utils/auth'
import SetBase from '../apis/setBase'
export default {
  data() {
   
    return {
      formEq: {
        eqType: serverMap['1001'][0].dictCode,
        installDate: formatTime('yyyy-MM-dd', new Date()),
        powerMode: '',
        manageId: '',
        generalStatus: '',
        pressureLevel: '',
        transmit: '',
        online: ''
      },
      formEqTemp: {
        scopeStart: null,
        scopeEnd: null
      },
      labelWidthMin: 'width:72px',
      labelWidthMin2: 'width:82px',
      labelWidth: 'width:215px',
      labelWidthMax: 'width:550px',
      equipmentListVisible: false,
      equipmentVisible: false,
      rulesEquipment: {
        flowName: [
          { required: true, message: '请输入设备名称', trigger: 'blur' },
          { max: 100, message: '超出最大限制', trigger: 'blur' }
        ],
        eqType: [{ required: true, message: '请选择设备类型', trigger: 'change' }],
      
        generalStatus: [{ required: true, message: '请选择状态', trigger: 'change' }],
        // pressureLevel: [{ required: true, message: '请选择附着压力级别', trigger: 'change' }],
        // transmit: [{ required: true, message: '请选择是否远传', trigger: 'change' }],
        // online: [{ required: true, message: '请选择是否在线', trigger: 'change' }],
        // address: [{ required: true, message: '请输入所在地址', trigger: 'blur' }],
        
      },
      tableData: [],
      headerStyle: {
        color: 'rgba(86, 179, 227, 1)',
        'background-color': '#244162',
        'border-bottom': 'rgba(110, 183, 227, 0.2) 1px solid'
      },
      eqTypeList: serverMap['1001'],
      flowType1List: [], //分类一先空着吧
     
      generalStatusList: serverMap['1009'],
      pressureLevelList: serverMap['1006'],
      transmitList: serverMap['1010'],
      onlineList: serverMap['1011'],
      pressureOffsetList: serverMap['1010'], //温压补偿没有
   
      protectLevelList: [],
      manageCmpList: [],
      cmpList: []
    }
  },
  mounted() {
    this.getCodeName()
    
  },
  methods: {
    getCodeName() {
      let code =
        commonDict[100014].code
      findDictTree({ dictCode: code }).then(res => {
        if (res.data) {
          this.flowType1List = res.data[commonDict[100014].code]
        }
      })
     
    },
  
    getList() {
      monitorFindById({ id: this.formEq.monitorPointId }).then(res => {
        this.tableData = res.data.eqs
      })
    },
    openAddDia() {
      let that = this
      that.formEq = JSON.parse(JSON.stringify(that.formEqTemp))
    
      var geocoder = new AMap.Geocoder();
      let lnglat1 = this.formEq.point.split(' ')
        geocoder.getAddress(lnglat1, function (status, result) {
          if (result.info === "OK") {
            that.$set(that.formEq,'address',result.regeocode.formattedAddress);
            // that.form.address = result.regeocode.formattedAddress;
          }
        });
    
      // that.formEq.generalStatus = this.generalStatusList[0].dictCode
      // that.formEq.pressureLevel = that.pressureLevelList[0].dictCode
      // that.formEq.transmit = that.transmitList[0].dictCode
      // that.formEq.online = that.onlineList[0].dictCode
      that.equipmentVisible = true
    },
    openEquipmentList(formData) {
      let that = this
      that.formEq.monitorPointId = formData.id
      that.formEq.point = formData.point
      that.tableData = formData.eqs

      that.equipmentListVisible = true
      that.formEqTemp = JSON.parse(JSON.stringify(that.formEq))
    },
    editEquipment(row) {
      if (row.eqType == '1') {
        //流量计
        flowFindById({ id: row.eqId })
          .then(res => {
            this.formEq = {
              eqType: serverMap['1001'][0].dictCode,
              point: this.formEqTemp.point,
              ...res.data
            }
            this.equipmentVisible = true
          })
          .catch(err => {
            this.$message.error(err)
          })
      } else {
        pressureFindById({ id: row.eqId })
          .then(res => {
            this.formEq = {
              eqType: serverMap['1001'][1].dictCode,
              point: this.formEqTemp.point,
              flowName: res.data.pressureName,
              flowCode: res.data.pressureCode,
              flowType1: res.data.pressureType1,
              ...res.data
            }
            this.equipmentVisible = true
          })
          .catch(err => {
            this.$message.error(err)
          })
      }
    },
    deleteEquipment(row) {
      this.$confirm('是否删除该设备', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        confirmButtonClass: 'confirmBtnClass',
        cancelButtonClass: 'cancelBtnClass',
        customClass: 'gis-default',
        type: 'warning'
      })
        .then(() => {
          if (row.eqType == '1') {
            //删除流量计
            flowDelete({ id: row.eqId })
              .then(res => {
                if (res.code == 200) {
                  this.$message.success(res.msg)
                } else {
                  this.$message.error(res.msg)
                }
                this.getList()
              })
              .catch(err => {
                this.$message.error(err)
              })
          } else {
            //删除压力计
            pressureDelete({ id: row.eqId })
              .then(res => {
                if (res.code == 200) {
                  this.$message.success(res.msg)
                } else {
                  this.$message.error(res.msg)
                }
                this.getList()
              })
              .catch(err => {
                this.$message.error(err)
              })
          }
        })
        .catch(() => {})
    },
    submitEquipment() {
      let that = this
      that.formEq = {
        ...that.formEq,
        layerId: that.$store.getters.currentLayer
      }
      that.$refs.formEq.validate(valid => {
        if (valid) {
          if (that.formEq.eqType != serverMap['1001'][0].dictCode) {
            //压力计
            let data = {
              pressureName: that.formEq.flowName,
              pressureCode: that.formEq.flowCode,
              pressureType1: that.formEq.flowType1,
              ...that.formEq
            }
            if (that.formEq.id) {
              //编辑压力计
              pressureUpdateById(data)
                .then(res => {
                  if (res.code == 200) {
                    that.$message.success(res.msg)
                    that.closeEquipment()
                  } else {
                    that.$message.error(res.msg)
                  }
                  this.getList()
                })
                .catch(err => {
                  that.$message.error(err)
                })
            } else {
              //新增压力计
              savePressure(data)
                .then(res => {
                  if (res.code == 200) {
                    that.$message.success(res.msg)
                    that.closeEquipment()
                  } else {
                    that.$message.error(res.msg)
                  }
                  this.getList()
                })
                .catch(err => {
                  that.$message.error(err)
                })
            }
          } else {
            //流量计
            if (that.formEq.id) {
              //编辑流量计
              flowUpdateById(that.formEq)
                .then(res => {
                  if (res.code == 200) {
                    that.$message.success(res.msg)
                    that.closeEquipment()
                  } else {
                    that.$message.error(res.msg)
                  }
                  this.getList()
                })
                .catch(err => {
                  that.$message.error(err)
                })
            } else {
              //新增流量计
              saveFlow(that.formEq)
                .then(res => {
                  if (res.code == 200) {
                    that.$message.success(res.msg)
                    that.closeEquipment()
                  } else {
                    that.$message.error(res.msg)
                  }
                  this.getList()
                })
                .catch(err => {
                  that.$message.error(err)
                })
            }
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    closeEquipment() {
      this.equipmentVisible = false
      let id = this.formEq.monitorPointId
      this.formEq = {
        eqType: serverMap['1001'][0].dictCode,
        installDate: formatTime('yyyy-MM-dd', new Date()),
        powerMode: '',
        manageId: '',
        generalStatus: '',
        pressureLevel: '',
        transmit: '',
        online: ''
      }
      this.formEq.monitorPointId = id
      this.resetformEq()
    },
    resetformEq() {
      this.$refs.formEq.resetFields()
    }
  }
}
</script>
<style lang="scss" scoped>
.btn {
  text-align: right;
  button {
    margin: 7px 15px 0 15px;
  }
}
.addBtn {
  margin-bottom: 5px;
}
.btnText {
  color: var(--font-color-f1);
  padding: 4px 5px;
}
/* table按钮颜色 */
.btnText.danger {
  color: #f74b4b;
}
.btnText.danger:hover,
.btnText.danger:focus {
  color: #f78989;
}

/* table列表颜色 */
:deep() .el-table {
  background-color: rgba(21, 38, 57, 1);
}
:deep() .el-table td.el-table__cell {
  border-bottom: rgba(110, 183, 227, 0.2) 1px solid;
  color: #fff;
}

:deep() .el-table__body tr > td {
  background-color: rgba(21, 38, 57, 1);
}
:deep() .el-table__body tr:hover > td {
  background-color: rgba(21, 38, 57, 1);
}
:deep() .el-table__body tr.hover-row > td.el-table__cell {
  background-color: rgba($color: var(--background-hover-skin), $alpha: 0.9);
}
:deep() .hover-row {
  background: var(--background-hover-skin);
}
:deep() .el-table--border::after,
.el-table--group::after,
.el-table::before {
  background-color: var(--background-hover-skin);
}

:deep() .el-table__fixed-body-wrapper {
  background-color: rgba($color: var(--background-hover-skin), $alpha: 0.9);
}
:deep() .el-table__fixed-right::before {
  background-color: rgba($color: var(--background-hover-skin), $alpha: 0.9);
}
:deep() .el-table {
  background-color: var(--table-background);
}
:deep() .el-table--border .el-table__cell,
.el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border-right: 1px solid var(--table-border);
}
:deep() .el-table td.el-table__cell {
  border-bottom: rgba(110, 183, 227, 0.2) 1px solid;
  color: var(--font-color-top);
}
:deep() .el-table th.el-table__cell {
  color: var(--font-color-f1);
  background-color: var(--background-title-skin);
}
:deep() .el-table__body tr > td {
  background-color: var(--table-background);
}
:deep() .el-table__body tr:hover > td {
  background-color: var(--table-background);
}
:deep() .el-table__body tr.hover-row > td.el-table__cell {
  background-color: rgba($color: var(--background-hover-skin), $alpha: 0.9);
}
:deep() .el-table .el-table__cell {
  padding: 10px 0px;
}
</style>
