export function exportMethod(data, fileName, type = 'application/vnd.ms-excel') {
  const link = document.createElement('a')
  let blob = new Blob([data], { type })
  link.style.display = 'none'
  link.href = URL.createObjectURL(blob)
  link.download = fileName //下载的文件名
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export function exportMethodByUrl({ url, name = '未命名文件', fileName }) {
  const link = document.createElement('a')
  link.style.display = 'none'
  link.href = url
  link.download = fileName || name //下载的文件名
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
