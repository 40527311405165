import { render, staticRenderFns } from "./valve.vue?vue&type=template&id=338a775a&scoped=true"
import script from "./valve.vue?vue&type=script&lang=js"
export * from "./valve.vue?vue&type=script&lang=js"
import style0 from "./valve.vue?vue&type=style&index=0&id=338a775a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "338a775a",
  null
  
)

export default component.exports