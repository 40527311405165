<template>
  <div>
    <div v-show="dialogVisible" id="dragBox" ref="dragBox" class="layerBox">
      <div v-if="!form.id">
        <div class="title" @mousedown="move">
          <div>
            <span class="title-content">
              场站信息
            </span>
            <span class="caoz">
              <i
                class="iconfont icon-shanchu2"
                style="float: right; padding-right: 10px;"
                @click="colse()"
              ></i>
              <i class="iconfont icon-yidong" style="float: right; padding-right:13px"></i>
            </span>
          </div>
        </div>
        <div>
          <div class="Feature">
            <el-form
              ref="form"
              :model="form"
              :rules="rules"
              label-position="right"
              label-width="90px"
              :inline="true"
              size="mini"
            >
            
            <el-form-item label="所属图层:" >
                <el-input
                  v-model="form.layerName"
                  class="selectWidth"
                  disabled
                  placeholder="请输入名称"
                ></el-input>
              </el-form-item>
              <el-form-item label="名称:" prop="stationName">
                <el-input
                  v-model="form.stationName"
                  class="selectWidth"
                  maxlength="40"
                  placeholder="请输入用户站名称"
                ></el-input>
              </el-form-item>
              <el-form-item label="场站分类:" prop="stationKind">
                <el-select
                  v-model="form.stationKind"
                  class="selectWidth"
                  popper-class="gis-default"
                  placeholder="请选择场站分类"
                >
                  <el-option
                    v-for="item in stationKindList"
                    :key="item.dictCode"
                    :label="item.name"
                    :value="item.dictCode"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <!-- <el-form-item label="设计规模:" prop="scale">
                <el-input
                  v-model="form.scale"
                  class="selectWidth"
                  maxlength="40"
                  placeholder="请输入设计规模(Nm³/h)"
                >
                <template slot="append">(Nm³/h)</template>
                </el-input>
              </el-form-item> -->
              <el-form-item label="位置:" prop="address">
                <el-input
                  v-model="form.address"
                  maxlength="100"
                  class="selectWidth"
                  style="width:710px"
                  placeholder="请输入位置"
                ></el-input>
              </el-form-item>
              <el-form-item label="经纬度:">
                <el-input
                  v-model="form.point"
                  class="selectWidth"
                  disabled
                  placeholder="请输入经纬度"
                ></el-input>
              </el-form-item>
              <!-- <div v-if="form.stationType=='2'">
              <el-form-item label="用户数量(户):">
                <el-input-number
                  v-model="form.userCount" type="number" :min="0"
                  class="selectWidth"
                  placeholder="请输入用户数量"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="居民(户):">
                <el-input-number
                  v-model="form.residentCount"
                  class="selectWidth"
                  placeholder="请输入居民" @change="getNUm()" type="number" :min="0"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="商业(户):">
                <el-input-number
                  v-model="form.businessCount"
                  class="selectWidth"
                  placeholder="请输入商业" @change="getNUm()" type="number" :min="0"
                >
              </el-input-number>
              </el-form-item>
              <el-form-item label="工业(户):">
                <el-input-number
                  v-model="form.industryCount"
                  class="selectWidth"
                  placeholder="请输入工业" @change="getNUm()" type="number" :min="0"
                ></el-input-number>
              </el-form-item>
              </div> -->
              <el-form-item label="备注:">
                <el-input
                  v-model="form.remark"
                  class="selectWidth"
                  style="width:710px"
                  type="textarea"
                  :rows="2"
                  maxlength="60"
                  placeholder="请输入备注"
                ></el-input>
              </el-form-item>
               
            </el-form>
            <div class="formbtn">
              <el-button size="mini" @click="colse">取消</el-button>
              <el-button size="mini" type="primary" @click="submit">保存</el-button>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div @mousedown="move">
          <div style="width:850px;display:inline-block;position:relative;">
            <el-tabs ref="tabs" v-model="activeName" type="border-card" @tab-click="handleClick">
              <el-tab-pane label="场站信息" name="one"></el-tab-pane>
              <el-tab-pane :disabled="!form.id" label="进出站管线" name="two"></el-tab-pane>
            </el-tabs>
          </div>
          <span class="caozt">
            <i
              class="iconfont icon-shanchu2"
              style="float: right; padding-right: 10px;"
              @click="colse()"
            ></i>
            <i class="iconfont icon-yidong" style="float: right; padding-right:13px"></i>
          </span>
        </div>
        <div v-if="activeName == 'one'">
          <div v-if="!edit" class="Feature">
            <el-form
            :inline="true"
              ref="form"
              :model="form"
              :rules="rules"
              label-position="right"
              label-width="90px"
              size="mini"
              class="blueFormLabelClass"
            >

          


            <el-form-item label="所属图层:">
                <div class="equipment-item2" style="width:700px"> {{ form.layerName }}</div>
              </el-form-item>
              <el-form-item label="名称:">
                 <div class="equipment-item2">{{ form.stationName }}</div>
              </el-form-item>
              <el-form-item label="场站分类:" >
                <div class="equipment-item2"> {{ form.stationKindName }}</div>
              </el-form-item>
              <!-- <el-form-item label="设计规模:">
                <div class="equipment-item2"> {{ form.scale }}(Nm³/h)</div>
              </el-form-item> -->
              
              <el-form-item label="经纬度:">
                <div class="equipment-item2"> {{ form.point }}</div>
              </el-form-item>
              <!-- <div v-if="form.stationType==2">
               <el-form-item label="用户数量(户):">
                 <div class="equipment-item2">{{ form.userCount }}</div>
              </el-form-item>
              <el-form-item label="居民(户):">
                 <div class="equipment-item2">{{ form.residentCount }}</div>
              </el-form-item>
              <el-form-item label="商业(户):">
                <div class="equipment-item2">{{ form.businessCount }}</div>
              </el-form-item>
              <el-form-item label="工业(户):">
                 <div class="equipment-item2">{{ form.industryCount }}</div>
              </el-form-item>
              </div> -->
              <el-form-item label="位置:">
                <div class="equipment-item2" style="width:700px"> {{ form.address }}</div>
              </el-form-item>
              <el-form-item label="备注:">
                <div class="equipment-item2" style="width:700px"> {{ form.remark }}</div>
              </el-form-item>
              
              
            </el-form>
            <div class="formbtn" v-if="!form.cz">
              <!-- <el-button size="mini" type="primary" @click="delStation()">删除</el-button> -->
              <el-button v-if="!form.active" size="mini" type="primary" @click="editStation()"
                >编辑</el-button
              >
            </div>
          </div>
          <div v-if="edit" class="Feature">
            <el-form
              ref="form"
               :inline="true"
              :model="form"
              :rules="rules"
              label-position="right"
              label-width="85px"
              size="mini"
            >
              <el-form-item label="名称:" prop="stationName">
                <el-input
                  v-model="form.stationName"
                  class="selectWidth"
                  maxlength="40"
                  placeholder="请输入名称"
                ></el-input>
              </el-form-item>
              <el-form-item label="场站分类:" prop="stationKind">
                <el-select
                  v-model="form.stationKind"
                  class="selectWidth"
                  popper-class="gis-default"
                  placeholder="请选择场站分类"
                >
                  <el-option
                    v-for="item in stationKindList"
                    :key="item.dictCode"
                    :label="item.name"
                    :value="item.dictCode"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <!-- <el-form-item label="设计规模:" prop="scale">
                <el-input
                  v-model="form.scale"
                  class="selectWidth"
                  maxlength="40"
                  placeholder="请输入设计规模(Nm³/h)"
                >
                <template slot="append">(Nm³/h)</template></el-input>
              </el-form-item> -->
              <el-form-item label="位置:"  prop="address">
                <el-input
                  v-model="form.address"
                  maxlength="100"
                  style="width: 710px;"
                  class="selectWidth"
                  placeholder="请输入位置"
                ></el-input>
              </el-form-item>
              <el-form-item label="经纬度:">
                <el-input
                  v-model="form.point"
                  class="selectWidth"
                  placeholder="请输入经纬度"
                ></el-input><span style="margin-left:10px;color:var(--font-color-c1)">示例：经纬度格式 117.958138 39.635917 </span>
              </el-form-item>
              <!-- <div v-if="form.stationType=='2'">
              <el-form-item label="用户数量(户):">
                <el-input-number
                  v-model="form.userCount" type="number" :min="0"
                  class="selectWidth"
                  placeholder="请输入用户数量"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="居民(户):">
                <el-input-number
                  v-model="form.residentCount"
                  class="selectWidth"
                  placeholder="请输入居民" @change="getNUm()" type="number" :min="0"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="商业(户):">
                <el-input-number
                  v-model="form.businessCount"
                  class="selectWidth"
                  placeholder="请输入商业" @change="getNUm()" type="number" :min="0"
                >
              </el-input-number>
              </el-form-item>
              <el-form-item label="工业(户):">
                <el-input-number
                  v-model="form.industryCount"
                  class="selectWidth"
                  placeholder="请输入工业" @change="getNUm()" type="number" :min="0"
                ></el-input-number>
              </el-form-item>
              </div> -->
              <el-form-item label="备注:">
                <el-input
                 style="width: 710px;"
                  v-model="form.remark"
                  class="selectWidth"
                  type="textarea"
                  :rows="2"
                  maxlength="60"
                  placeholder="请输入备注"
                ></el-input>
              </el-form-item>
            </el-form>
           
            <div class="formbtn">
              <el-button size="mini" @click="cancleEdit">取消</el-button>
              <el-button size="mini" type="primary" @click="submit">保存</el-button>
            </div>
          </div>
        </div>

        <div v-if="activeName == 'two'" style="padding:10px;min-height: 400px;">
          <div class="btnDiv" v-if="!form.cz">
            <el-button v-if="!form.active" class="btn" type="primary" size="mini" @click="stationPointAdd()"
              ><i class="el-icon-plus"></i> 新增</el-button
            >
          </div>
          <el-table
            :data="tableData"
            :highlight-current-row="false"
            :border="true"
            style="width: 100%"
          >
            <el-table-column label="进出站" width="104" align="center">
              <template #default="{ row }">
                <div v-if="!row.addOrEdit">{{ row.channelType == 1 ? '进站' : '出站' }}</div>
                <div v-else>
                  <el-select
                    v-model="row.channelType"
                    popper-class="gis-default"
                    placeholder="请选择进出站" size="mini"
                  >
                    <el-option
                      v-for="item in channelTypeList"
                      :key="item.dictCode"
                      :label="item.name"
                      :value="item.dictCode"
                    >
                    </el-option>
                  </el-select>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="名称" width="185" align="center">
              <template #default="{ row }">
                <div v-if="!row.addOrEdit">{{ row.channelName }}</div>
                <div v-else>
                  <el-input
                    v-model="row.channelName"
                    placeholder="请输入名称"
                    maxlength="40" size="mini"
                  ></el-input>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="经纬度" width="400" align="center">
              <template #default="{ row ,$index}">
                <div v-if="!row.addOrEdit">
                  {{ row.pointArray[0][0] + ' ' + row.pointArray[0][1] }}
                  <el-tag class="selecttag" @click="setlineHight(row.point, $event)">高亮</el-tag>
                </div>
                <div v-else>
                  <el-input v-model="row.lnglat" disabled style="width:200px" size="mini"></el-input>
                  <el-tag
                    v-if="row.point"
                    class="selecttag"
                    @click="setlineHight(row.point, $event)" size="mini"
                    >高亮</el-tag
                  >
                  <el-tag class="selecttag" @click="getNodeIdByMap($index)" size="mini">选取</el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column v-if="!form.cz||$parent.gis" label="操作" width="140" align="center">
              <template #default="{ row,$index}">
                <div v-if="!row.addOrEdit">
                  <el-button type="text" class="btnText" @click="editStationPoint(row)"
                    >编辑</el-button
                  >
                  <el-button type="text" class="btnText danger" @click="deleteStationPoint(row)"
                    >删除</el-button
                  >
                </div>
                <div v-else>
                  <el-button type="text" class="btnText" @click="submitStationPoint(row)"
                    >保存</el-button
                  >
                  <el-button
                    type="text"
                    class="btnText danger"
                    @click="cancleStationPoint(row, $index)"
                    >取消</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getCompanyName, getCompanyId } from '../../utils/auth'
import { findDictTree } from '../../apis/commonType'

import {
  stationFindByIds,
  saveStation,
  stationUpdate,
  saveStationPoint,
  clearStationPoint,
  updateStationPoint
} from '../../apis/tool'
import { centerPointGetFourPoint, filterLayer, formatLocation } from '../../utils/geoTool.js'
import { serverMap, commonLayer, constantDict } from '../../utils/dict.js'
import { queryFeatrue } from '../../apis/geo'
export default {
  data() {
    return {
      dialogVisible: false,
      activeName: 'one',
      edit: true, //场站详情编辑
      form: {
        stationKind: '',
        businessCount:0,
        industryCount:0,
        residentCount:0,
        userCount:0
      },
      stationKindList: [],
      channelTypeList: serverMap['1012'],
      rules: {
        stationName: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        stationKind: [{ required: true, message: '请选择场站分类', trigger: 'change' }],
        scale: [
          {
            pattern: /(^[1-9]\d{0,5}?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: '请输入正确格式'
          }
        ],
        address: [{ required: true, message: '请输入位置', trigger: 'blur' }],
      },
      tableData: [],
      tableIndex: 0, //进出站地图选取index
      headerStyle: {
        color: '#fff',
        'background-color': '#244162',
        'border-right': 'rgba(110, 183, 227, 0.2) 1px solid',
        'border-bottom': 'rgba(110, 183, 227, 0.2) 1px solid'
      }
    }
  },
  mounted() {},
  methods: {
    delStation() {},
    getCodeName(type) {
      let code = type == 2 ? serverMap[1004][1].dictCode : serverMap[1004][0].dictCode
      findDictTree({ dictCode: code }).then(res => {
        if (res.data) {
          this.stationKindList = res.data[code]
          this.form.stationKind = this.stationKindList[0].dictCode
        }
      })
    },
    getNUm(){
      this.form.userCount = parseInt(this.form.businessCount)+parseInt(this.form.residentCount)+parseInt(this.form.industryCount)
    },
    openDialog(obj) {
      this.activeName = 'one'
      if (obj.id) {
        this.form = obj
        delete this.form.pointId
        this.edit = false
        this.tableData = obj.channels
      } else {
        this.edit = true
        this.form.point = obj.point
        this.form.layerId = this.$store.getters.currentLayer
        this.form.layerName = this.$store.getters.currentLayerName
        
        // this.form.companyName = getCompanyName()
        // this.form.deptId = getCompanyId()
        this.form.pointId = obj.pointId
      }

      if (obj.equipmentType == 'userSta') {
        this.form.stationType = 2
        this.getCodeName(2)
      } else {
        this.form.stationType = 1

        this.getCodeName(1)
      }
      this.dialogVisible = true
      if (!obj.cz) {
        this.$parent.$refs.topTool.clearNoRemove()
      }
      if(!obj.id){
        let that = this;
        var geocoder = new AMap.Geocoder();
        let lnglat1 = this.form.point.split(' ')
          geocoder.getAddress(lnglat1, function (status, result) {
            if (result.info === "OK") {
              that.$set(that.form,'address',result.regeocode.formattedAddress);
              // that.form.address = result.regeocode.formattedAddress;
            }
          });
      }
      
    },
    setstationKindName(value) {
      this.stationKindList.forEach(item => {
        if (item.dictCode == value) {
          this.form.stationKindName = item.name
        }
      })
    },
    // 查看页到编辑页
    editStation() {
      // this.form.deptId = getCompanyId()
      if(this.form.stationKindName){
        let station =  this.stationKindList.find(
          el => el.name == this.form.stationKindName
        )
        if(station){
          this.form.stationKind = station.dictCode
        }
        
      }
      
      this.edit = true
    },
    // 新增、更新场站信息
    submit() {
      let that = this
      that.$refs.form.validate(valid => {
        if (valid) {
          that.setstationKindName(that.form.stationKind)
          if (that.form.id) {
            let lonlat = that.form.point.split(' ')
            if (lonlat && lonlat.length != 2) {
              that.$message.warning('请输入正确格式的经纬度')
              return
            }
            const reg = /^(\-|\+)?(((\d|[1-9]\d|1[0-7]\d|0{1,3})\.\d{0,15})|(\d|[1-9]\d|1[0-7]\d|0{1,3})|180\.0{0,15}|180)$/
            if (!reg.test(lonlat[0])) {
              that.$message.warning('请输入正确格式的经纬度')
              return
            }
            const reg1 = /^(\-|\+)?([0-8]?\d{1}\.\d{0,15}|90\.0{0,15}|[0-8]?\d{1}|90)$/
            if (!reg1.test(lonlat[1])) {
              console.log(222)
              that.$message.warning('请输入正确格式的经纬度')
              return
            }

            // 修改场站信息
            stationUpdate(that.form)
              .then(res => {
                if (res.code == 200) {
                  that.$message.success(res.msg)
                  that.cancleEdit()
                  that.$eventBus.$emit('setFilter')
                } else {
                  that.$message.error(res.msg)
                }
              })
              .catch(err => {
                //that.$message.error(err)
              })
          } else {
            // 新增场站信息
            saveStation(that.form)
              .then(res => {
                if (res.code == 200) {
                  that.$message.success(res.msg)
                  // that.form.id = res.data
                  that.getList()
                  that.$eventBus.$emit('setFilter')
                  that.cancleEdit()
                  that.dialogVisible = false
                  that.colse()
                } else {
                  that.$message.error(res.msg)
                }
              })
              .catch(err => {
               // that.$message.error(err)
              })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 编辑页返回查看页
    cancleEdit() {
      this.edit = false
      this.getList()
    },
    // 获取场站详情进出点列表
    getList() {
      if( this.form.id){
        stationFindByIds({ id: this.form.id }).then(res => {
          let point = this.form.point
          this.form = res.data
          delete this.form.pointId
          this.form.point = point
          this.tableData = res.data.channels
        })
      }
      
    },
    // 添加进出点
    stationPointAdd() {
      let obj = {
        addOrEdit: 'add',
        channelType: 1,
        channelName: '',
        pointId: '',
        lnglat: ''
      }
      this.tableData.push(obj)
    },
    // 地图上选取节点
    getNodeIdByMap(index) {
      this.$parent.$refs.topTool.clearNoRemove()
      this.$parent.isSelectNode = true
      this.tableIndex = index
    },
    // 进出点设为高亮
    setlineHight(pointStr, e) {
      let point = formatLocation(pointStr, 'Point')
      this.$parent.pointHighlight(point)
      //e.target.style.background = 'rgba(42, 130, 176 ,0.8)'
    },
    selectStationNode(lnglat) {
      let that = this
      let msg = ''
      if (this.form.equipmentType == 'userSta') {
        msg = '请选择次高压、中压管线的末端节点' //user
      } else {
        msg = '请选择中压及以上管线的末端节点'
      }
      that.getRangeFeature(lnglat, commonLayer['basePoint']).then(function(res) {
        let features = res.features
        let distances = { id: -1, dt: 0, index: 0 }
        if (features.length > 0) {
          let i = 0
          let idList = []
          features.forEach((item, index) => {
            if (
              item.properties.node_type2 == constantDict.jiedian.dictCode &&
              item.properties.is_free == 1
            ) {
              let point = item.properties.location_str
              let location = formatLocation(point, 'LineString')
              let distance = AMap.GeometryUtil.distanceToLine(lnglat, location)
              console.log(distance)
              if (distances.dt == 0 || distances.dt >= distance) {
                i++
                distances.id = item.properties.id
                distances.dt = distance
                distances.index = index
                idList.push(item.properties.id)
              }
            }
          })
          distances.ids = idList
          if (i > 1) {
            let pointStr = features[distances.index].properties.location_str
            let point = formatLocation(pointStr, 'Point')
            that.$parent.pointHighlight(point, pointStr)
            that.$parent.$refs.editVector.openLineSelect(distances.ids, point, pointStr)
          } else {
            if (distances.id != -1) {
              let channel_type = features[distances.index].properties.channel_type
              if (channel_type == 1 || channel_type == 2) {
                that.$message.warning('该节点已被绑定，请重新选取')
              } else {
                let pointStr = features[distances.index].properties.location_str
                let point = formatLocation(pointStr, 'Point')
                that.$parent.pointHighlight(point)
                that.tableData[that.tableIndex].point = pointStr
                that.tableData[that.tableIndex].lnglat = point[0] + ' ' + point[1]
                that.tableData[that.tableIndex].pointId = distances.id
              }
            } else {
              that.$message.warning(msg)
            }
          }
        } else {
          that.$message.warning(msg)
        }
      })
    },
    selectPoit(item, pointStr) {
      let point = formatLocation(pointStr, 'Point')
      this.$parent.pointHighlight(point)
      this.tableData[this.tableIndex].point = pointStr
      this.tableData[this.tableIndex].lnglat = point[0] + ' ' + point[1]
      this.tableData[this.tableIndex].pointId = item.id
    },
    async getRangeFeature(lnglat, layerName) {
      let slq = filterLayer(this.$store)
      let filter = {}
      if (slq) {
        filter = { CQL_FILTER: slq }
      }
      let points = centerPointGetFourPoint(this.$parent.map, lnglat)
      let a = points.leftBottomPoint[0]
      let b = points.leftBottomPoint[1]
      let c = points.upRightPoint[0]
      let d = points.upRightPoint[1]
      let STYLES = ''
      let parames = {
        SERVICE: 'WMS',
        VERSION: '1.1.0',
        REQUEST: 'GetFeatureInfo',
        FORMAT: 'image/png',
        TRANSPARENT: true,
        QUERY_LAYERS: layerName,
        LAYERS: layerName,
        exceptions: 'application,Fvnd.ogc.se_inimage',
        INFO_FORMAT: 'application/json',
        FEATURE_COUNT: 50,
        X: 50,
        y: 50,
        SRS: 'EPSG:4326',
        WIDTH: 101,
        HEIGHT: 101,
        STYLES,
        BBOX: a + ',' + b + ',' + c + ',' + d,
        ...filter
      }
      return await queryFeatrue(parames)
    },

    submitStationPoint(row) {
      if (row.channelName == '') {
        this.$message.warning('请输入名称')
      } else if (row.pointId == '') {
        this.$message.warning('经纬度不能为空')
      } else {
        if (row.addOrEdit == 'add') {
          row.id = this.form.id
          row.stationType = this.form.stationType
          saveStationPoint(row)
            .then(res => {
              if (res.code == 200) {
                this.$message.success('保存成功')
                this.getList()
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch(err => {
             // this.$message.error(err)
            })
        } else {
          row.stationType = this.form.stationType
          updateStationPoint(row)
            .then(res => {
              if (res.code == 200) {
                this.$message.success('编辑成功')
                this.getList()
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch(err => {
             // this.$message.error(err)
            })
        }
      }
    },
    cancleStationPoint(row, index) {
      if (row.addOrEdit == 'add') {
        this.tableData.splice(index, 1)
      } else {
        row.addOrEdit = ''
      }
    },
    editStationPoint(row) {
      this.$set(row, 'lnglat', row.pointArray[0][0] + ' ' + row.pointArray[0][1])
      this.$set(row, 'addOrEdit', 'edit')
    },
    // 删除进出点
    deleteStationPoint(row) {
      this.$confirm('是否确认删除该用户站绑定的的进出站管线信息', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: 'gis-default',
        type: 'warning',
        closeOnClickModal: false,
        distinguishCancelAndClose: true
      })
        .then(() => {
          clearStationPoint({ pointId: row.pointId })
            .then(res => {
              if (res.code == 200) {
                this.$message.success('删除成功')
                this.getList()
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch(e => {
             // this.$message.error(e.message)
            })
        })
        .catch(() => {})
    },
    handleClick() {
      // if (this.activeName == 'two' && !this.form.id) {
      //   this.$message.warning('请先保存场站信息')
      // }
    },
    colse() {
      this.$parent.isSelectNode = false

      this.dialogVisible = false
      // this.form = { stationKind: this.stationKindList[0].dictCode }

      this.$parent.$refs.topTool.clearTopTool()
      this.form = {}
     // this.form.stationKind = this.stationKindList[0].dictCode
     this.$set(this.form,'stationKind', this.stationKindList[0].dictCode)
    },
    move(e) {
      let odiv = this.$refs.dragBox
      let disX = e.clientX - odiv.offsetLeft
      let disY = e.clientY - odiv.offsetTop
      document.onmousemove = e => {
        let left = e.clientX - disX
        let top = e.clientY - disY
        this.positionX = top
        this.positionY = left

        odiv.style.left = left + 'px'
        odiv.style.top = top + 'px'
      }
      document.onmouseup = e => {
        document.onmousemove = null
        document.onmouseup = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.equipment-item2 {
  text-align: left;
  width: 299px;
  color: var(--font-color-c2);
  line-height: 20px;
    display: inline-block;
    vertical-align: text-top;

}
::v-deep .el-tabs__item.is-active {
  color: var(--font-color-f1);
  background-color: var(--background-color-skin);
}
::v-deep .el-tabs__item {
  color: var(--font-color-c1);
}
::v-deep .el-tabs__active-bar {
  background-color: #6eb7e3;
}
::v-deep .el-tabs__nav-wrap::after {
  content: none;
}
::v-deep .el-tabs__header {
  margin: 0px;
  background-color:var(--background-color-skin)
}
::v-deep .el-button--text {
  background: transparent !important;
  border: none;
}
::v-deep .formItemSmall.el-form-item {
  margin-bottom: 2px;
}
.caozu {
  display: inline-flex;
  height: 40px;
  font-size: 14px;
  text-align: right;
  vertical-align: text-bottom;
  line-height: 36px;
  color: #a5b4c4;
  float: right;
}
.colseBut {
  font-size: 26px;
  vertical-align: middle;
  cursor: pointer;
}

.layerBox {
  width: 850px;
  position: absolute;
  top: 4rem;
  left: 10rem;
  min-height: 3rem;
  min-width: 3rem;
  //   border: 1px solid var(--border-color-skin);
  box-shadow: 0px 1px 6px 0px rgba(4, 57, 138, 0.3);
  z-index: 2000;
  background: var(--background-color-skin);
  opacity: 0.98;
}
.Feature {
  padding: 20px 10px 10px;
  line-height: 0.28rem;
  // text-align: center;
  width: 100%;
  color: var(--font-color-f1);
}
.equipment-item {
  text-align: left;
  width: 380px;
  word-break: break-word;
}
.btnDiv {
  margin-bottom: 15px;
  .btn {
    margin-right: 15px;
  }
}
.formbtn {
  text-align: right;
  margin-right: 40px;
  button {
    margin: 7px 0px 10px 15px;
  }
}
.btnText {
  color: var(--font-color-f1);
  padding: 4px 5px;
}
/* table按钮颜色 */
.btnText.danger {
  color: #f74b4b;
}
.btnText.danger:hover,
.btnText.danger:focus {
  color: #f78989;
}
.selecttag {
  margin-left: 5px;
  cursor: pointer;
}
.selectWidth {
  width:300px;
}
.title {
  height: 40px;
  line-height: 40px;
  background: linear-gradient(90deg, var(--background-title-skin1), var(--background-title-skin2));
}
::v-deep .el-tabs__nav-scroll {
  background: linear-gradient(90deg, var(--background-title-skin1), var(--background-title-skin2));
  padding-left: 10px;
  padding-top: 6px;
}
::v-deep .el-tabs__item {
  height: 39px;
  line-height: 39px;
  text-align: center;
  min-width: 110px;
  color: var(--font-color-f1);
}
::v-deep .el-tabs__item.is-active {
  border-radius: 4px 4px 0px 0px;
  text-align: center;
  color: var(--font-color-f1);
}
::v-deep .el-tabs__active-bar {
  background-color: #fff;
}
.title-content {
  color: var(--font-color-f1);
  font-weight: bold;
  padding: 13px 9px;
  font-size: 14px;
}
.caozt {
  position: absolute;
  right: 0px;
  top: 10px;
}
::v-deep .el-tabs--border-card {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
::v-deep .el-tabs--border-card > .el-tabs__content {
  padding: 0px;
}
::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item{
 // color: var(--font-color-f1)
}
.blueFormLabelClass{
  ::v-deep .el-form-item{margin-bottom: 0px;}
}
// .blueFormLabelClass{
//   margin: 20px;
//   border-left: 1px solid #BECBD2;
//   border-right: 1px solid #BECBD2;
//   border-bottom: 1px solid #BECBD2;
//   width: 780px;
// }
// .blueFormLabelClass{
//   ::v-deep .el-form-item{margin-right: 0px;position: relative;margin-bottom: 0px;}
//   ::v-deep .el-form-item__label{border-top: 1px solid #BECBD2;border-right: 1px solid #BECBD2;background-color: #e7ebed;padding-right: 10px;
//   // display: inline-block;position:absolute;bottom: 0px;right: 0px;
//  }
//   ::v-deep .el-form-item__content{border-top: 1px solid #BECBD2;min-height: 30px;}
// }


::v-deep .el-table {
  background-color:var(--table-background);
  
}
::v-deep .el-table .cell{line-height: 33px;}
::v-deep .el-table--border .el-table__cell, .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
    border-right: 1px solid var(--table-border);
}
::v-deep .el-table td.el-table__cell {
  border-bottom: rgba(110, 183, 227, 0.2) 1px solid;
  color: var(--font-color-top);
}
::v-deep .el-table th.el-table__cell{color: var(--font-color-f1);background-color: var(--background-title-skin);}
::v-deep .el-table__body tr > td {
  background-color: var(--table-background);
}
::v-deep .el-table__body tr:hover > td {
  background-color: var(--table-background);
}
::v-deep .el-table__body tr.hover-row > td.el-table__cell {
  background-color: rgba($color: var(--background-hover-skin), $alpha: 0.9);
}
::v-deep .el-table .el-table__cell{
  padding: 0;
}

::v-deep .hover-row {
  background: var(--background-hover-skin);
}
::v-deep .el-table--border::after,
.el-table--group::after,
.el-table::before {
  background-color: var(--background-hover-skin);
}

::v-deep .el-table__fixed-body-wrapper {
  background-color: rgba($color: var(--background-hover-skin), $alpha: 0.9);
}
::v-deep .el-table__fixed-right::before {
  background-color: rgba($color: var(--background-hover-skin), $alpha: 0.9);
}
</style>
