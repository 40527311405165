<template>
  <div></div>
</template>

<script>
export default {
  name: 'ZOOM',
  components: {},
  props: {
    showCount: false
  },
  data() {
    return {
      startZoom: 14,
      count: 2
    }
  },
  computed: {},
  watch: {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    init() {
      let that = this

      this.$parent.map.on('zoomend', function(e) {
        //2.0zoom变化量小问题
        let map = that.$parent.map
        let nowZoom = map.getZoom()
        let old  = nowZoom
        let zoom = nowZoom

        if (nowZoom > that.startZoom) {
          zoom = Math.ceil(nowZoom)
        } else if (nowZoom < that.startZoom) {
          zoom = parseInt(nowZoom)
        }
        map.setZoom(zoom)

        that.startZoom = nowZoom
        if(zoom==old){
          that.$parent.mapZoom(zoom)
        }
        
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
