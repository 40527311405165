//  引入拦截器
import axios from '@/common/js/requestVideo';

export default {
    //  获取流信息
    getMediaInfo(params) {
        return axios({
            url:'/index/api/getMediaInfo',
            method:'GET',
            params
        })
    },
    //  拉取流
    addStreamProxy(params) {
        return axios({
            url:'/index/api/addStreamProxy',
            method:'GET',
            params
        })
    }
}