<template>
  <div></div>
</template>
<script>
import { commonLayer, constantDict } from '../../utils/dict'
export default {
  name: 'Wms',
  components: {},
  props: {
    layerName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      wms: null,
      refresh: false,
      wmsOver: null,
      wmsCH: null
    }
  },
  computed: {},
  watch: {},
  mounted() {
    this.setEventBut()
  },
  beforeDestroy() {
    this.clearEvent()
  },
  methods: {
    clearEvent() {
      this.$eventBus.$off('lineLayerGis')
      this.$eventBus.$off('clearWms')
      this.$eventBus.$off('fxLayer')
    },

    setEventBut() {
      this.$eventBus.$on('lineLayerGis', data => {
        // if (this.wms) {
        //   this.$parent.map.remove(this.wms)
        // }

        this.lineLayer(data.filter, data.name || this.layerName, data.type)
      })
      this.$eventBus.$on('analysisLayer', data => {
        // if (this.wms) {
        //   this.$parent.map.remove(this.wms)
        // }
        this.analysisLayer(data)
      })
      this.$eventBus.$on('overlapping', data => {
        // if (this.wms) {
        //   this.$parent.map.remove(this.wms)
        // }
        this.overlapping(data)
      })
      this.$eventBus.$on('clearWms', data => {
        // if (this.wms) {
        //   this.$parent.map.remove(this.wms)
        // }
      })
    },

    /*
      功能：加载瓦片
      参数：line：管网过滤条件 style：管网样式 name 图层名称
     */

    lineLayer(line, name, type) {
      // if (this.wms) {
      //   this.$parent.map.remove(this.wms)
      // }
      // if (this.wmsOver) {
      //   this.$parent.map.remove(this.wmsOver)
      // }
      let isLegendSelect = this.$store.getters.isLegendSelect
      var groupC = isLegendSelect.toString()

      let lineP = {}
      if (line) {
        let refresh = ''
        if (this.refresh && !type) {
          let a = Math.random()
          refresh = ' and ' + a + ' = ' + a
        }
        if (groupC) {
          line += ' and line_color_id not in (' + groupC + ')'
        }
        lineP = { CQL_FILTER: line + refresh }
      }

      let layer = this.layerName
      if (name) {
        layer = name
        //  this.layerName = name
      }
      this.refresh = !this.refresh
      //   this.getOverLayer(lineP)
      let legendSelect = this.$store.getters.legendSelect
      let isOverlapping = false
      legendSelect.forEach(el => {
        if (el == constantDict.chonghejiedian.dictCode) {
          isOverlapping = true
        }
      })
      if (isOverlapping) {
        this.overlapping()
      } else {
        if (this.wmsCH) {
          this.$parent.map.remove(this.wmsCH)
        }
      }
      console.log(layer)
      let heightLayerId = this.$parent.$refs.layerManage.heightLayerId?this.$parent.$refs.layerManage.heightLayerId:0
      let p = {
          LAYERS: layer,
          VERSION: '1.3.0',
          viewparams: 'highlight:'+heightLayerId,
          ...lineP
        }
        if(this.wms&& this.$parent.map.hasLayer(this.wms)){
          this.wms.setParams(p)
          this.wms.reload()
        }else{
          this.wms = new AMap.TileLayer.WMS({
            url: '/geoserver/wms',
            blend: false,
            tileSize: 512,
            dataZooms: [0, 25],
            zooms: [0, 25],
            zIndex: 10,
            detectRetina: false,
            //  visible:false,
            params: p
          })
          this.wms.setMap(this.$parent.map)
        }
      
    },

    getOverLayer(lineP) {
      let sketchType = this.$store.getters.legendSelect
      if (sketchType.indexOf(constantDict.chonghejiedian.dictCode) == -1) {
        // if (this.wmsOver) {
        //   this.$parent.map.remove(this.wmsOver)
        // }
        return
      }
      let layer = commonLayer['overlapping']
      let p = {
              LAYERS: layer,
              VERSION: '1.1.0',
              ...lineP
            }
      if(this.wmsOver&& this.$parent.map.hasLayer(this.wmsOver)){
          this.wmsOver.setParams(p)
          this.wmsOver.reload()
        }else{
          this.wmsOver = new AMap.TileLayer.WMS({
            url: '/geoserver/wms',
            blend: false,
            tileSize: 900,
            dataZooms: [0, 25],
            zooms: [0, 25],
            zIndex: 11,
            //  visible:false,
            params: p
          })
          this.wmsOver.setMap(this.$parent.map)
        }
    },
    overlapping() {
      let groupLayer = this.$store.getters.groupLayer
      let notGroupLayer = this.$store.getters.notGroupLayer
      let group = []
      let groupsql = '1=2'
      if (notGroupLayer && notGroupLayer.length ==1&&notGroupLayer[0].id=='-100') {
        groupsql = '1=1'
      }else{
        if(groupLayer && notGroupLayer&&groupLayer.length<=notGroupLayer.length){
          groupsql = '1=2'
          groupLayer.forEach(item => {
            groupsql += ' or layer_id=' + item.id
          })
        }
        if(groupLayer && notGroupLayer&&groupLayer.length>notGroupLayer.length){
          groupsql = '1=1'
          notGroupLayer.forEach(item => {
            groupsql += ' and layer_id !=' + item.id
          })
          
        }
      }
      
      let layersVue1 = groupsql

      // if (this.wms) {
      //   this.$parent.map.remove(this.wms)
      // }
      if (this.wmsCH) {
        this.$parent.map.remove(this.wmsCH)
      }
      let p= {
          LAYERS: commonLayer.overlapping,
          VERSION: '1.1.0',
          viewparams: 'layer_id:' + layersVue1
        }
      // if(this.wmsCH&& this.$parent.map.hasLayer(this.wmsCH)){
      //     this.wmsCH.setParams(p)
      //     this.wmsCH.reload()
      //   }else{
          this.wmsCH = new AMap.TileLayer.WMS({
            url: '/geoserver/wms',
            blend: false,
            tileSize: 900,
            dataZooms: [0, 25],
            zooms: [0, 25],
            zIndex: 10,
            //  visible:false,
            params:p
          })
          this.wmsCH.setMap(this.$parent.map)
        // }
    },
    /*
      功能：加载瓦片
      参数： name 图层名称
     */

    analysisLayer(name) {
      // let groupLayer = this.$store.getters.groupLayer
      // let group = []
      // if (groupLayer && groupLayer.length > 0) {
      //   groupLayer.forEach(item => {
      //     group.push(item.id)
      //   })
      // }
      // let layersVue1 = group.toString()

      let groupLayer = this.$store.getters.groupLayer
      let notGroupLayer = this.$store.getters.notGroupLayer
      let group = []
      let sqlwhere = ''
      if (notGroupLayer && notGroupLayer.length ==1&&notGroupLayer[0].id=='-100') {

      }else{
        if(groupLayer && notGroupLayer&&groupLayer.length<=notGroupLayer.length){
          groupLayer.forEach(item => {
            group.push(item.id)
          })
          sqlwhere += `  layer_id  in (${group.toString()})`
        }
        if(groupLayer && notGroupLayer&&groupLayer.length>notGroupLayer.length){
          notGroupLayer.forEach(item => {
            group.push(item.id)
          })
          sqlwhere += `  layer_id not in (${group.toString()})`
        }
      }

      // if (this.wms) {
      //   this.$parent.map.remove(this.wms)
      // }
      // if (this.wmsOver) {
      //   this.$parent.map.remove(this.wmsOver)
      // }
        let p = {
          LAYERS: name.name,
          VERSION: '1.1.0',
          // CQL_FILTER: sqlwhere
        }
        if(sqlwhere){
          p.CQL_FILTER = sqlwhere
        }
        if(this.wms&& this.$parent.map.hasLayer(this.wms)){
          this.wms.setParams(p)
          this.wms.reload()
        }else{
          this.wms = new AMap.TileLayer.WMS({
            url: '/geoserver/wms',
            blend: false,
            tileSize: 900,
            dataZooms: [0, 25],
            zooms: [0, 25],
            zIndex: 10,
            //  visible:false,
            params: p
          })
          this.wms.setMap(this.$parent.map)
      }
    },

    init(line, name) {
      this.lineLayer(line, name)
    }
  }
}
</script>
