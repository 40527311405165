<template>
  <div>
    <el-dialog
      :title="addOrEdit == 'add' ? '新增节点' : '编辑节点'"
      :visible.sync="dialogVisible"
      :inline="true"
      :modal="false"
      :close-on-click-modal="false"
      width="333px"
      custom-class="dialog-class"
      @close="close"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="70px" size="mini" label-position="right" style="padding-right:10px">
        <el-form-item label="经纬度:" prop="point">
          <el-input
            v-model="form.point"
            :disabled="addOrEdit == 'add' || (addOrEdit == 'edit' && form.isFree != 1)"
            placeholder="请输入经纬度"
          ></el-input>
        </el-form-item>
        <el-form-item label="节点名称:" prop="pointName">
          <el-input
            v-model="form.pointName"
            placeholder="请输入节点名称"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="高程:" prop="elevation">
          <el-input
            v-model="form.elevation"
            placeholder="请输入高程"
            @input="onTableNub($event, form, 'elevation')"
          >
            <template slot="append">m</template>
          </el-input>
        </el-form-item>
        <el-form-item label="埋深:" prop="depth">
          <el-input
            v-model="form.depth"
            placeholder="请输入埋深"
            @input="onTableNub($event, form, 'depth')"
          >
            <template slot="append">m</template>
          </el-input>
          
        </el-form-item>
        <el-form-item label="备注:">
          <el-input
            v-model="form.remark"
            type="textarea"
            placeholder="请输入备注"
          >
          </el-input>
        </el-form-item>
        <!-- <el-form-item label="所在地址:" prop="address">
          <el-input
            v-model="form.address" 
            type="textarea"
            placeholder="请输入所在地址"
          >
          </el-input>
        </el-form-item> -->
      </el-form>
      <div class="btn">
        <el-button size="mini" @click="close">取消</el-button>
        <el-button v-if="addOrEdit === 'add'" type="primary" size="mini" @click="submit"
          >保存</el-button
        >
        <el-button v-else type="primary" size="mini" @click="update()">保存</el-button>
        
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { saveNode, updateById } from '../apis/tool'
import { lineRangePoint, formatLocation } from '../utils/geoTool.js'
import { constantDict, commonLayer } from '../utils/dict.js'
import { queryFeatrue } from '../apis/geo'
export default {
  data() {
    let checkElevation = (rule, value, callback) => {
      if (value === null || value === undefined || value === '') {
        callback()
      } else if (!/^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$|(^(0){1}$)/.test(value)) {
        callback(new Error('高程必须为数值'))
      } else {
        callback()
      }
    }
    let checkDepth = (rule, value, callback) => {
      if (value === null || value === undefined || value === '') {
        callback()
      } else if (!/^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$|(^(0){1}$)/.test(value)) {
        callback(new Error('埋深必须为数值'))
      } else {
        callback()
      }
    }
    return {
      addOrEdit: 'add',
      form: {
        elevation: '',
        depth: ''
      },
      rules: {
        pointName:[{ required: true, message: '请输入节点名称', trigger: 'blur' }],
        point: [{ required: true, message: '请输入经纬度', trigger: 'blur' }],
        elevation: [{ validator: checkElevation, trigger: 'blur' }],
        depth: [{ validator: checkDepth, trigger: 'blur' }],
        
      },
      dialogVisible: false,
      pipelineLnglat: []
    }
  },
  mounted() {},
  methods: {
    openDialog(data) {
      console.log(data)
      if (data.addOrEdit == 'edit') {
        // 编辑
        this.addOrEdit = data.addOrEdit
        this.form = {
          ...data
        }
      } else {
        // 新增
        this.form.pipeId = data.id
        this.form.point = data.lnglat
        this.pipelineLnglat = data.pipelineLnglat
        let that = this;
        var geocoder = new AMap.Geocoder();
      let lnglat1 = this.form.point.split(' ')
        geocoder.getAddress(lnglat1, function (status, result) {
          if (result.info === "OK") {
            that.$set(that.form,'address',result.regeocode.formattedAddress);
          }
        });
      }
      this.dialogVisible = true
    },
    close() {
      this.dialogVisible = false
      this.$parent.$parent.editTool.close(true)
      this.$parent.$parent.pointHighlightRemove()
      if (this.addOrEdit == 'add') {
        this.$parent.startDraw('node')
      }
    },
    update() {
      let that = this
      this.$refs['form'].validate(valid => {
        if (valid) {
          if (!that.form.elevation) that.form.elevation = 0
          updateById(that.form)
            .then(res => {
              that.$message.success(res.msg)
              that.$eventBus.$emit('setFilter')
              that.close()
            })
            .catch(err => {
              that.$message.error(err)
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    /*
     *@description: 数值改变
     *@author: 庞红川
     */
    onTableNub(nub, row, prop) {
      if (nub == '') {
        return
      }
      let arr = nub.split('')
      let index = arr.findIndex(el => el != 0)
      if (nub > 99999999) {
        row[prop] = 99999999
      } else if (index != -1) {
        if (arr[1] != '.') {
          arr.splice(0, index)
          row[prop] = arr.join('')
        }
      } else {
        row[prop] = 0
      }
    },
    submit() {
      let that = this
      this.$refs['form'].validate(valid => {
        if (valid) {
          let startPoint = that.pipelineLnglat[0]
          let endPoint = that.pipelineLnglat[that.pipelineLnglat.length - 1]
          let amapEnd = new AMap.LngLat(endPoint[0], endPoint[1])
          let lineend = lineRangePoint(that.$parent.$parent.map, amapEnd)
          var lnglat = new AMap.LngLat(that.form.point.split(' ')[0], that.form.point.split(' ')[1])
          let linestart = lineRangePoint(that.$parent.$parent.map, lnglat)

          // let sqlLine =
          //   linestart[0].join(',') +
          //   ' ' +
          //   linestart[1].join(',') +
          //   ' ' +
          //   lineend[1].join(',') +
          //   ' ' +
          //   lineend[0].join(',') +
          //   ' ' +
          //   linestart[0].join(',')
          this.params = {
            SERVICE: 'wfs',
            VERSION: '1.0.0',
            REQUEST: 'GetFeature',
            typeName: commonLayer.monitoPoint,
            outputFormat: 'application/json',
            CQL_FILTER:'pipe_id = '+ this.form.pipeId 
        //    filter: this.getFiles(sqlLine)
          }
          queryFeatrue(this.params).then(res => {


            let features = res.features
            let monitorPointIds = []
            if (features&&features.length > 0) {
              features.forEach(itemsub => {
                let p1=formatLocation(itemsub.properties.location_str, 'Point')
                if (AMap.GeometryUtil.isPointOnSegment(p1, lnglat, amapEnd, 0.01)) {
                  monitorPointIds.push(itemsub.properties.id)
                }
              })
            }
            let midPoint = that.form.point.split(' ')
            let pipeLength1 = AMap.GeometryUtil.distance(startPoint, midPoint)
            let pipeLength2 = AMap.GeometryUtil.distance(midPoint, endPoint)
            that.form = {
              ...that.form,
              layerId: that.$store.getters.currentLayer,
              line1: startPoint.join(' ') + ', ' + that.form.point,
              line2: that.form.point + ', ' + endPoint.join(' '),
              pipeLength1: pipeLength1.toFixed(2),
              pipeLength2: pipeLength2.toFixed(2),
              nodeType: constantDict.jiedian.dictCode,
              monitorPointIds
            }
            if (!that.form.elevation) that.form.elevation = 0
            saveNode(that.form)
              .then(res => {
                if (res.code == 200) {
                  that.$message.success(res.msg)
                  that.$eventBus.$emit('setFilter')
                  that.close()
                } else {
                  that.$message.error(res.msg)
                }
              })
              .catch(err => {
                that.$message.error(err)
              })
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    getFiles(pos) {
      //线上的点
      let file =
        '<Filter xmlns="http://www.opengis.net/ogc" xmlns:gml="http://www.opengis.net/gml">' +
        '<Intersects><PropertyName>location</PropertyName><gml:Polygon>' +
        '<gml:outerBoundaryIs>' +
        '<gml:LinearRing>' +
        '<gml:coordinates>' +
        pos +
        '</gml:coordinates>' +
        '</gml:LinearRing>' +
        '</gml:outerBoundaryIs>' +
        '</gml:Polygon></Intersects></Filter>'
      return file
    }
  }
}
</script>
<style lang="scss" scoped>
.btn {
  text-align: right;

  button {
    margin: 7px 15px 0 15px;
  }
}
</style>
