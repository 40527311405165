<template>
  <div class="toolbar" :style="{ left: propsPosition + 'px', bottom: bottomPosition + 'px' }">
    <!-- <div class="disk">
      <span class="diskLeft" @click="panBy(100, 0)"></span>
      <span class="diskTop" @click="panBy(0, 50)"></span>
      <span class="diskRight" @click="panBy(-100, 0)"></span>
      <span class="diskBottom" @click="panBy(0, -50)"></span>
    </div> -->
    <div class="setZoom">
      <span class="setCommon" @click="setCenter()">
        <img src="../../../../assets/gis/tool/center.png" />
      </span>
      <span class="setCommon" @click="setZoom('out')">
        <img src="../../../../assets/gis/tool/jia.png" />
      </span>
      <!-- <div class="setCommon c">
        <span class="bg" :alt="zoom">
          <span class="value" :style="{ height: height }"></span>
        </span>
      </div> -->
      <span class="setCommon last" @click="setZoom('in')">
        <img src="../../../../assets/gis/tool/jian.png" />
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Toolbar',
  components: {},
  props: {
    propsPosition: {
      type: Number,
      default: function() {
        return 0
      }
    },
    bottomPosition: {
      type: Number,
      default: 0.3
    }
  },
  data() {
    return {
      zoom: 14,
      postionVal: 4.62
    }
  },
  computed: {
    height() {
      //  console.log(this.zoom)
      return (this.zoom / 20) * 100 + '%'
    }
  },
  watch: {
    '$store.getters.fullState'(val) {
      if (val) {
        this.$parent.propsPosition = 4.62
      } else {
        this.$parent.propsPosition = 0.1
      }
    }
  },
  mounted() {},
  methods: {
    panBy(lon, lat) {
      this.$parent.map.panBy(lon, lat)
    },
    setVal() {
      this.propsPosition = 4.62
    },
    setCenter() {
      let Position = [125.790604,41.677467]
      this.$parent.map.setCenter(Position)
    },
    setZoom(type) {
      let zoom = this.$parent.map.getZoom()
      if (type == 'out') {
        zoom += 1
      } else {
        zoom -= 1
      }
      this.$parent.map.setZoom(zoom)
    }
  }
}
</script>

<style lang="scss" scoped>
.toolbar {
  position: absolute;
  bottom: 0.3rem;
  left: 4.62rem;
  z-index: 1;
  transition: left 0.5s;
  .disk {
    background: url('../../../../assets/gis/tool/disk.png') no-repeat;
    background-size: 100% 100%;
    width: 0.57rem;
    height: 0.57rem;
    position: relative;
    span {
      width: 0.21rem;
      height: 0.21rem;
      position: absolute;
      cursor: pointer;
    }
    .diskLeft {
      top: 0.2rem;
      left: 0rem;
    }
    .diskTop {
      top: 0rem;
      left: 0.2rem;
    }
    .diskRight {
      top: 0.2rem;
      right: 0rem;
    }
    .diskBottom {
      bottom: 0rem;
      left: 0.2rem;
    }
  }
  .setZoom {
    background:var(--tool-bg-color);
box-shadow: 0px 1px 6px 0px rgba(4, 57, 138, 0.3);
opacity: 0.9;
  //   background: rgba(41, 60, 79, 0.94);
    width: 0.4rem;
    text-align: center;
    margin-left: 0.08rem;
    margin-top: 0.12rem;
  }
  .setCommon {
    display: block;
    padding-top: 0.08rem;
    border-bottom: 2px solid rgba(200,200,200,0.3);
    text-align: center;
    cursor: pointer;
  }
  .setCommon.last {
    border: none;
  }
  .c {
    cursor: default;
  }
  .bg {
    height: 1rem;
    width: 5px;
    display: block;
    margin: 0 auto;
    background: #0c5888;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 0.12rem;
    position: relative;
  }
  .value {
    background: #01baff;
    width: 5rem;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: height 0.5s;
  }
}
</style>
