<template>
  <div>
    <div v-show="displayList.length" class="reveal-box" :style="$parent.topWidth&&$parent.gis?'left: 590px;':'left: 328px;'">
      <div class="reveal">
        <div
          v-for="(item, index) in displayList"
          :key="item.id"
          class="reveal-item"
          @click="changeStatu(displayList, index)"
        >
          <div class="reveal-ions" :style="{ background:  item.select ? item.colour : '#9fa6af'}"></div>
          <span :class="item.select ? '' : 'active'">{{ item.dictName }}</span>
        </div>
      </div>
    </div>
    <div v-show="pipeDisplayList.length" class="reveal-box" :style="$parent.topWidth&&$parent.gis?'left: 590px;':'left: 328px;'">
      <div class="reveal">
        <div
          v-for="(item, index) in pipeDisplayList[0]"
          :key="item.id"
          class="reveal-item"
          @click="changeStatu(pipeDisplayList[0], index)"
        >
          <div class="reveal-ions" :style="{ background: item.select ?  item.colour : '#9fa6af' }"></div>
          <span :class="item.select ? '' : 'active'">{{ item.showName }}</span>
        </div>
      </div>
      <div class="reveal">
        <div
          v-for="(item, index) in pipeDisplayList[1]"
          :key="item.id"
          class="reveal-item"
          @click="changeStatu(pipeDisplayList[1], index)"
        >
          <div class="reveal-ions" :style="{ background:  item.select ?  item.colour : '#9fa6af' }"></div>
          <span :class="item.select ? '' : 'active'">{{ item.showName }}</span>
        </div>
        
      </div>
      <div class="reveal">
        <div
          v-for="(item, index) in pipeDisplayList[2]"
          :key="item.id"
          class="reveal-item"
          @click="changeStatu(pipeDisplayList[2], index)"
        >
          <div class="reveal-ions" :style="{ background:  item.select ?  item.colour : '#9fa6af' }"></div>
          <span :class="item.select ? '' : 'active'">{{ item.showName }}</span>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Bottom',
  components: {},
  props: {
    displayList: {
      type: Array,
      default: () => []
    },
    pipeDisplayList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  },
  methods: {
    changeStatu(list, index) {
      if(!this.$parent.isClick){
        this.$message.warning('当前状态不允许操作管线显示状态')
        return
      }
      let legendSelectArr = this.$store.getters.isLegendSelect
      let flag = list[index].select ? false : true
      list[index].select = flag
      if (flag) {
        let i = legendSelectArr.findIndex(v => v === list[index].dictCode)
        legendSelectArr.splice(i, 1)
      } else {
        legendSelectArr.push(list[index].dictCode)
      }
      this.$store.commit('gis/isLegendSelectChange', legendSelectArr)
      this.$eventBus.$emit('setFilter')
    }
  }
}
</script>
 
<style lang="scss" scoped>
.reveal-box {
  position: absolute;
  bottom: 10px;
  left: 590px;
  transform: translate(-200px);
  text-align: left;
  .reveal {
    background: var(--background-color-skin);
    padding: 0.1rem;
    font-size: 0.14rem;
    display: block;

    // width: 700px;
    .reveal-item {
      margin-right: 10px;
      display: inline-block;
      align-items: center;
      cursor: pointer;
      .reveal-ions {
        display: inline-block;
        width: 10px;
        height: 10px;
        background: red;
        margin-right: 0.05rem;
      }
      .active {
        color: #9fa6af;
      }
    }
  }
}
</style>
