import { Message, MessageBox } from 'element-ui'
function showMsg(message, type = 'success', duration = 3000, showClose = true, offset = 200) {
  Message({
    message,
    type,
    duration,
    showClose,
    offset,
    customClass: 'vueTopMessage'
  })
}

function showMsgBox(msg, isTrue) {
  return MessageBox.confirm(isTrue ? msg : `确定要${msg}吗?`, '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  })
}

export function Try(load) {
  return function(tar, name, dec) {
    let fn = dec.value
    dec.value = async function(...args) {
      try {
        if (load) this[load] = true
        await fn.call(this, ...args)
      } catch (e) {
        if (load) this[load] = false
        e.message && showMsg(e.message, 'error')
      }
      if (load) this[load] = false
    }
  }
}

export function Confirm(msg, isTrue) {
  return function(tar, name, dec) {
    let fn = dec.value
    dec.value = async function(...args) {
      try {
        if (msg) await showMsgBox(msg, isTrue)
      } catch (e) {
        return
      }
      try {
        let confirm = showMsgBox
        await fn.call(this, ...args, confirm)
      } catch (e) {
        e.message && !e.type && showMsg(e.message, 'error')
      }
    }
  }
}
