import { makeList, selectCountStationPointGroup, selectCountValvePointGroup } from '../apis/commonType'
import markIcon from '@/assets/gis/mark_b.png'
import { queryFeatrue } from '../apis/geo'
import { filterLayer } from '../utils/geoTool.js'
import userSta from '@/assets/gis/map/2.png'
import valueImg from '@/assets/gis/map/21.png'
import {  getMapCenterLocation } from '@/apis/commonType'

export const intMixin = {
  data() {
    return {
      activatedFlag: 2,
      map: null, //主map
      AmapType: { 'AMap.Marker': 'point' },
      maxZoom: 16,
      zoom:11,
      mapConfig: {
        //高德基础参数配置
        viewMode: '3D',
        pitch: 0,
        rotation: 0,
        zoom: 15,
        center: [125.7680815,41.6770017],
        zooms: [0, 23],
       // layers: [],
        maxZoom: 20,
        labelzIndex: 1,
        pitchEnable: false,
        rotateEnable: false,
        expandZoomRange: true, //调整最大缩放级数
        showBuildingBlock: false,
        animateEnable: false,
        jogEnable: false,
        mapStyle: 'amap://styles/normal'
        
      },
      drawType: '', //绘制类型
      drawPositon: [],
      markForm: {
        //工具箱  窗口
        remark: '',
        type: '',
        line: [],
        point: []
      },
      clickListener: null,
      drawlays: [],
      showTip: false,
      tipMessege: '',
      tipInfo: null,
      tipPoint: [],
      overlayGroups: null,
      LabelsLayer: null,
      mouseTool: null,
      drawState: false,
      sketchColor: '#3e8bff',
      sketchImg: {
        mark_b: require('@/assets/gis/point/mark_b.png'),
        ding: require('@/assets/gis/point/ding.png'),
        hongqi: require('@/assets/gis/point/hongqi.png'),
        miao: require('@/assets/gis/point/miao.png'),
        shigong: require('@/assets/gis/point/shigong.png'),
        shigong2: require('@/assets/gis/point/shigong2.png'),
        text: require('@/assets/gis/point/text.png'),
        weizhi: require('@/assets/gis/point/weizhi.png'),
        xing: require('@/assets/gis/point/xing.png'),
        marker_check: require('@/assets/gis/point/check.png'),
        marker_hidden: require('@/assets/gis/point/gongcheng.png')
      },
      sketchActive: 'mark_b',
      polylineHighlight: null, //高亮管线
      markerHighlight: null, //高亮点
      rectangleHighlight: null,
      circleHighlight: null,
      noclose: false,
      layerShow:true,
      LabelsLayerHighlight:[]
    }
  },
  watch: {},
  async mounted() {
    let that = this
    if( this.$store.getters.color=="black"){
      that.mapConfig.mapStyle='amap://styles/grey'
    }
    await getMapCenterLocation().then(res => {
      if(res.data!=null&&res.data.length>0){
      let area = res.data.split(',')
      that.mapConfig.center = area
      }
    })
    await this.$eventBus.$on('initMap', data => {
       that.initMap()
      this.setEventBut()
    })
    if (this.$store.getters.pulginLoadGis) {
      await that.initMap()
      await this.setEventBut()
    }
  },

  methods: {
    /*
     *********************
     * 功能描述：刷新wms
     *********************
     */
    setEventBut() {
      let that = this
      this.$eventBus.$on('setFilter', data => {
        let filter = filterLayer(this.$store)
        this.$eventBus.$emit('lineLayerGis', { filter, name: this.layerName })
        if (this.queryLayer) {
          this.getBoundsFeat(this.queryLayer)
        }
      })
      this.$eventBus.$on('setMarker', data => {
        that.setMarker()
      })
    },

    clearEvent() {
      this.$eventBus.$off('setFilter')
      this.$eventBus.$off('setMarker')
    },

    /*
     *********************
     *    功能描述：离线地图
     *********************
     */
    setlayers() {
      let xyzTileLayer = new AMap.TileLayer({
        zIndex: 1,
        tileSize: 512,
        opacity: 1,
        zooms: [0, 20],
        getTileUrl: function(x, y, z) {
          //123//312
          var url = '/tiles/' + z + '/' + x + '/' + y + '.png'
          return url
        }
      })
    },
    setMarker() {
      let sketchType = this.$store.getters.sketchType
      let parmas = {}
      if (sketchType && sketchType.length > 0) {
        parmas.geometryTypes = sketchType.toString()
        let layerId = this.$store.getters.groupLayer
        if (layerId && layerId.length > 0) {
          let group = []
          layerId.forEach(item => {
            group.push(item.id)
          })
          parmas.layerIds = group.toString()
        }
        this.getMark(parmas)
      } else {
        if (this.overlayGroups) {
          this.overlayGroups.clearOverlays()
        }
        if (this.LabelsLayer) {
          this.LabelsLayer.clear()
        }
      }
    },
    /*
     *********************
     *    功能描述：工具箱标记
     *    开发人：孙志萍
     *    开发时间：
     *    重点参数含义：type  ---  rectangle（矩形）circle（圆）point（点）polygon（多边形）line（线）
     *********************
     */
    getMark(parmas) {
      var p = parmas
      let that = this
      if (that.overlayGroups) {
        that.overlayGroups.clearOverlays()
      } else {
        that.overlayGroups = new AMap.OverlayGroup({
          collision: false,
          allowCollision: false
        })
        that.map.add(that.overlayGroups)
      }
      if (that.LabelsLayer) {
        that.LabelsLayer.clear()
      } else {
        that.LabelsLayer = new AMap.LabelsLayer({
          collision: false,
          allowCollision: false
        })

        that.map.add(that.LabelsLayer)
      }
      makeList(p).then(res => {
        let list = res.data
        let group = []
        let label = []
        for (var i = 0; i < list.length; i++) {
          let extData = list[i]
          if (list[i].geometryType == 'line') {
            // 创建线实例
            let poly = new AMap.Polyline({
              path: list[i].geometryArray,
              strokeColor: list[i].labelType || '#3e8bff',
              strokeWeight: 3,
              zIndex: 160,
              extData
            })
            group.push(poly)
          } else if (list[i].geometryType == 'polygon') {
            // 创建线实例
            let poly = new AMap.Polygon({
              path: list[i].geometryArray,
              fillColor: list[i].labelType || '#3e8bff',
              strokeColor: list[i].labelType || '#3e8bff',
              zIndex: 160,
              extData
            })
            group.push(poly)
          } else if (list[i].geometryType == 'point') {
            let poly1 = new AMap.LabelMarker({
              position: list[i].geometryArray[0],
              icon: {
                type: 'image',
                image: that.sketchImg[list[i].labelType] || markIcon,
                anchor: 'bottom-center'
              },
              zIndex: 160,
              extData
            })

            label.push(poly1)
            that.LabelsLayer.add(poly1)
            poly1.on('click', function(e) {
              if ((that.$refs.topTool&&!that.$refs.topTool.toolActive)||!that.$parent.gis) {
                const obj = e.target.getExtData()
                that.markForm = obj
                that.drawInfo()
              }
            })
          } else if (list[i].geometryType == 'circle') {
            // 创建线实例
            let point = list[i].point
            let poly = new AMap.Circle({
              center: list[i].geometryArray[0],
              radius: list[i].radius,
              fillColor: list[i].labelType || '#3e8bff',
              strokeColor: list[i].labelType || '#3e8bff',
              zIndex: 160,
              extData
            })
            group.push(poly)
          } else if (list[i].geometryType == 'rectangle') {
            // 创建线实例
            let bounds = new AMap.Bounds(list[i].geometryArray[0], list[i].geometryArray[1])
            let poly = new AMap.Rectangle({
              bounds: bounds,
              fillColor: list[i].labelType || '#3e8bff',
              strokeColor: list[i].labelType || '#3e8bff',
              zIndex: 160,
              extData
            })
            group.push(poly)
          }
        }

        if (group && group.length > 0) {
          that.overlayGroups.addOverlays(group)
          that.overlayGroups.on('click', function(e) {
            if ((that.$refs.topTool&&!that.$refs.topTool.toolActive)) {
              that.$refs.topTool.clearTopTool()
              const obj = e.target.getExtData()

              that.markForm = obj

              that.drawInfo()
            }
          })
        }
      })
    },

    /*
     *********************
     *    功能描述：工具箱绘制
     *    开发人：孙志萍
     *    开发时间：
     *    重点参数含义：type  ---  rectangle（矩形）circle（圆）point（点）polygon（多边形）line（线）
     *********************
     */
    drawEnd() {
      let that = this

      this.mouseTool.on('draw', function(event) {
        if (that.drawType == 'rule') {
          // 测距重新计算长度
          event.obj.setPath()
          that.ruleObj = []
          that.ruleM = 0
        } else {
          that.drawType = ''
        }
        let obj = event.obj
        let type = obj.className
        that.tipPoint = []
        that.drawlays.push(obj)
        let point = []
        that.markForm = {
          remark: '',
          type: '',
          line: [],
          point: []
        }
        let queryName = event.obj.getExtData()
        if (type == 'AMap.Marker') {
          that.markForm.point = obj.getPosition()
          that.markForm.type = 'point'
          that.markForm.line = []
          point = obj.getPosition()
          if(queryName){
            that.markForm.qType = queryName.type
          }

          // that.map.remove(that.drawlays)
          // that.drawlays = [obj]
          // that.map.add(that.drawlays)
        } else if (type == 'Overlay.Polyline') {
          that.markForm.point = []
          that.markForm.type = 'line'
          that.markForm.line = obj.getPath()
          point = obj.getPath()[obj.getPath().length - 1]
        } else if (type == 'Overlay.Polygon') {
          that.markForm.point = []
          that.markForm.type = 'polygon'
          that.markForm.line = obj.getPath()
          point = obj.getPath()[obj.getPath().length - 1]
        } else if (type == 'Overlay.Rectangle') {
          let north = obj.getBounds().northEast
          let south = obj.getBounds().southWest
          that.markForm.point = []
          that.markForm.type = 'rectangle'
          that.markForm.line = [north, south]
          point = south
        } else if (type == 'Overlay.Circle') {
          let position = obj.getCenter()
          that.markForm.point = [position.lng, position.lat, obj.getRadius().toFixed(2)]
          that.markForm.type = 'circle'
          that.markForm.line = []
          point = position
        }
        that.drawTipmouse = ''
        
        if (queryName && queryName.type == 'polygonQuery') {
          that.noclose = true
          that.drawTipmouse = ''
          that.$refs.queryInfo.initialize(that.markForm)
          that.mouseTool.close(false)
        }else if (queryName && queryName.type == 'polygonQueryTC') {
          that.noclose = true
          that.mouseTool.close(false)
          let treeData = JSON.parse(JSON.stringify(that.$refs.layerManage.treeData))
          that.$refs.editFeature.setTreeDataDisabled(treeData)
          that.$refs.markInfo.openEquipmentInfo(
            { act: 'all', options: treeData, line: that.markForm.line },
            point
          )
          this.drawTipmouse = ''
        }else if (queryName && queryName.type == 'polygonQueryDele') {
          that.noclose = true
          that.mouseTool.close(false)
          let treeData = JSON.parse(JSON.stringify(that.$refs.layerManage.treeData))
          that.$refs.editFeature.setTreeDataDisabled(treeData);
          let groupLayer = that.$store.getters.groupLayer
          that.$refs.markInfo.openEquipmentInfo(
            { act: 'allDele', options: treeData, line: that.markForm.line,newlayerName:groupLayer[0].name },
            point
          )
          this.drawTipmouse = ''
        } else {
          if (event.obj.name != 'recrdLine') {
            that.saveMark(type)
          }
        }
      })
    },
    saveMark(type) {
      const that = this
      if (type == 'AMap.Marker') {
        that.drawInfo()
      }
      if (
        type == 'Overlay.Polyline' ||
        type == 'Overlay.Polygon' ||
        type == 'Overlay.Rectangle' ||
        type == 'Overlay.Circle'
      ) {
        const item = []
        var data = {}
        if (type == 'Overlay.Circle') {
          item[0] = that.markForm.point[0]
          item[1] = that.markForm.point[1]
          data = {
            geometry: item.join(' '),
            geometryType: that.markForm.type,
            remark: '',
            labelType: that.sketchColor
          }
          data.radius = that.markForm.point[2]
        } else {
          that.markForm.line.forEach(element => {
            item.push(element.lng + ' ' + element.lat)
          })
          if (type == 'Overlay.Polygon') {
            item.push(item[0])
          }
          data = {
            geometry: item.join(','),
            geometryType: that.markForm.type,
            remark: '',
            labelType: that.sketchColor
          }
        }
        that.$refs.markInfo.saveAdd(data)
        that.mouseTool.close(true)
      }
    },
    /*
     *********************
     *    功能描述：工具箱绘制 结束弹出层
     *    开发人：孙志萍
     *    开发时间：
     *    重点参数含义：
     *********************
     */
    drawInfo() {
      let that = this
      if (that.markForm.id) {
        this.$refs.markInfo.openFeatrueInfo(
          {
            id: that.markForm.id,
            geometryType: that.markForm.geometryType,
            geometry: that.markForm.geometryArray[0][0] + ' ' + that.markForm.geometryArray[0][1],
            remark: that.markForm.remark,
            geometryName: that.markForm.geometryName,
            signType:'',
            qType:that.markForm.qType,
            address:''
          },
          that.markForm.geometryArray[0]
        )
        
        if (that.markForm.geometryType == 'polygon' || that.markForm.geometryType == 'line') {
          this.lineHighlight(that.markForm.geometryArray)
        }
        if (that.markForm.geometryType == 'point') {
          this.pointHighlight(that.markForm.geometryArray[0])
        }
        if (that.markForm.geometryType == 'rectangle') {
          this.rectangleHighlightF(that.markForm.geometryArray, that.markForm)
        }
        if (that.markForm.geometryType == 'circle') {
          this.circleHighlightF(that.markForm.geometryArray[0], that.markForm.radius, that.markForm)
        }
      } else {
        this.$refs.markInfo.openFeatrueInfo(
          {
            geometry: that.markForm.point.lng + ' ' + that.markForm.point.lat,
            geometryType: that.markForm.type,
            remark: '',
            imgKeys: [],
            labelType: that.sketchActive,
            qType:that.markForm.qType,
            address:''
          },
          that.markForm.point
        )
      }
      this.mouseTool.close(false)
    },
    clearDraw() {
      this.drawTipmouse = ''
      this.map.remove(this.drawlays)
      this.drawlays = []
      //this.getMark()
      this.setMarker()
      this.drawState = false
      this.closeDraw()
      if(this.$refs.topTool){
        this.$refs.topTool.toolActive = ''
      }
      
    },
    mapZoom(zoom) {
      this.$refs.Toolbar.zoom = zoom
      // this.$refs.rightTool.zoom = zoom
      this.zoom=zoom
      // this.$refs.rightTool.zoom = zoom
      if (zoom <= this.maxZoom) {
        this.queryVector = false
        this.queryVectorGeo = []
      } else {
        this.queryVector = true
        if (this.$store.getters.activeOptionItem == 'adsorption') {
          this.getBoundsFeat(this.queryLayer)
        }
      }
      this.getMarkStation()
    },
    getMarkStation(decimalCount) {
      let that = this;
      let groupLayer = this.$store.getters.groupLayer
      let notGroupLayer = this.$store.getters.notGroupLayer
      let group = []
      let sqlwhere = ''
      let layerContain = ''
      if(groupLayer.length==0){
        layerContain = 2
      }
      if (notGroupLayer && notGroupLayer.length ==1&&notGroupLayer[0].id=='-100') {
        layerContain = 1
      }else{
        if(groupLayer && notGroupLayer&&groupLayer.length<=notGroupLayer.length){
          groupLayer.forEach(item => {
            group.push(item.id)
          })
          layerContain = 3
          // sqlwhere += `  layer_id  in (${group.toString()})`
        }
        if(groupLayer && notGroupLayer&&groupLayer.length>notGroupLayer.length){
          notGroupLayer.forEach(item => {
            group.push(item.id)
          })
          layerContain = 4
          // sqlwhere += `  layer_id not in (${group.toString()})`
        }
      }

      let legendSelect = this.$store.getters.legendSelect
      if (legendSelect) {
        if (!legendSelect.find(el => el == 2)&&!legendSelect.find(el => el == 21)) {
          if (this.LabelsLayer) {
            this.LabelsLayer.hide()
          }
          return
        }
      } else {
        return
      }
      let decimalCountX = ''
      let decimalCountY = ''
      let zoom = this.map.getZoom()
      console.log(zoom)
      if (zoom <= 11) {
        decimalCountX = 1
        decimalCountY = 1
      }
      if (zoom > 11 && zoom <=12) {
        decimalCountX = 1
        decimalCountY = 2
      }
      if (zoom > 12 && zoom <= 13) {
        decimalCountX = 2
        decimalCountY = 1
      }
      if (zoom > 13 && zoom <= 14) {
        decimalCountX = 2
        decimalCountY = 2
      }
      if (zoom > 14) {
        if (this.LabelsLayer) {
          this.LabelsLayer.clear()
        }
        return
      }
      if (that.LabelsLayer) {
        that.LabelsLayer.clear()
      }
      that.LabelsLayer = new AMap.LabelsLayer({
        collision: false,
        allowCollision: false
      })
      if(legendSelect.find(el => el == 2)){
          selectCountStationPointGroup({decimalCountX: decimalCountX,decimalCountY: decimalCountY,layerContain:layerContain,layerIdList:group}).then(res=>{
           
        let list = res.data

      
        

        that.map.add(that.LabelsLayer)

        list.forEach(el => {
            let num = el.stationCount.toString()
          let position = el.averagePoint&&el.averagePoint.split(',')
          let poly1 = new AMap.LabelMarker({
            position:position,
            icon: {
              type: 'image',
              image: userSta,
              anchor: 'bottom-center'
            },
            text: {
              content: num,
              direction: 'right',
              offset:[-5,-15],
              style: {
                  fontSize: 15,
                  fillColor: '#fff',
                  // strokeColor: 'rgba(255,0,0,0.5)',
                  // strokeWidth: 2,
                    padding: [3, 3,3,5],
                    backgroundColor: '#061c77',
                  // borderColor:'#409eff',
                  // borderWidth: 0,
              }
          },
            zIndex: 160
          })

          that.LabelsLayer.add(poly1)

          poly1.on('click', function(e) {
            that.map.setZoom(15)
          })
        })
      })
      }
      if(legendSelect.find(el => el == 21)){
      //  if(false){
      selectCountValvePointGroup({decimalCountX: decimalCountX,decimalCountY: decimalCountY,layerContain:layerContain,layerIdList:group}).then(res=>{
        let that = this
        let list = res.data

    

      that.map.add(that.LabelsLayer)

      list.forEach(el => {
          let num = el.valveCount.toString()
        let position = el.averagePoint&&el.averagePoint.split(',')
        let poly1 = new AMap.LabelMarker({
          position:position,
          icon: {
            type: 'image',
            image: valueImg,
            anchor: 'bottom-center'
          },
          text: {
            content: num,
            direction: 'right',
            offset:[-5,-15],
            style: {
                fontSize: 15,
                fillColor: '#fff',
                // strokeColor: 'rgba(255,0,0,0.5)',
                // strokeWidth: 2,
                  padding: [3, 3,3,5],
                  backgroundColor: '#fd0000',
                // borderColor:'#409eff',
                // borderWidth: 0,
            }
        },
          zIndex: 160
       })

       that.LabelsLayer.add(poly1)

       poly1.on('click', function(e) {
         that.map.setZoom(15)
       })
     })
   })
  }
    },
    //图层dom
    toolLayerChange() {
      this.$refs.toolLayer.changeShow()
    },
    closeDraw() {
      this.mouseTool.close(true)
    },
    //

    //绘制矢量图形
    draw(type, img) {
      this.drawType = type
      this.drawState = true
      let status = true
      let that = this
      let icon = {}
      if (img) {
        that.sketchActive = img
        let tu = new AMap.Icon({ image: that.sketchImg[img], imageOffset: new AMap.Pixel(0, 0) })
        icon.icon = tu
      }
      switch (type) {
      case 'marker': {
        this.mouseTool.marker({
          ...icon,
          anchor: 'bottom-center',
          offset: new AMap.Pixel(0, 0)
        })
        status = false
        that.drawTipmouse = '单击，完成绘制'
        break
      }
      case 'marker_check': {
        this.mouseTool.marker({
          ...icon,
          anchor: 'bottom-center',
          offset: new AMap.Pixel(0, 0),
          extData: { type: 'marker_check' }
        })
        status = false
        that.drawTipmouse = '单击，完成绘制'
        break
      }
      case 'marker_hidden': {
        this.mouseTool.marker({
          ...icon,
          anchor: 'bottom-center',
          offset: new AMap.Pixel(0, 0),
          extData: { type: 'marker_hidden' }
        })
        status = false
        that.drawTipmouse = '单击，完成绘制'
        break
      }
      case 'polyline': {
        this.mouseTool.polyline({
          strokeColor: that.sketchColor,
          strokeWeight: 3
        })
        that.drawTipmouse = '单击确定起点，双击结束绘制'
        that.tipMessege = '移动鼠标，双击结束绘制'
        break
      }
      case 'polygon': {
        this.mouseTool.polygon({
          fillColor: that.sketchColor,
          strokeColor: that.sketchColor
        })
        that.tipMessege = '移动鼠标，双击结束绘制'
        that.drawTipmouse = '单击确定起点，双击结束绘制'
        break
      }
      case 'polygonQuery': {
        this.mouseTool.polygon({
          fillColor: '#000000',
          strokeColor: '#000000',
          strokeStyle: 'dashed',
          fillOpacity: 0,
          extData: { type: 'polygonQuery' }
        })
        that.tipMessege = '移动鼠标，双击结束绘制'
        that.drawTipmouse = '单击确定起点，双击结束绘制'
        break
      }
      case 'polygonQueryTC': {
        this.mouseTool.polygon({
          fillColor: '#000000',
          strokeColor: '#000000',
          strokeStyle: 'dashed',
          fillOpacity: 0,
          extData: { type: 'polygonQueryTC' }
        })
        that.tipMessege = '移动鼠标，双击结束绘制'
        that.drawTipmouse = '单击确定起点，双击结束绘制'
        break
      }
      case 'polygonQueryDele': {
        this.mouseTool.polygon({
          fillColor: '#000000',
          strokeColor: '#000000',
          strokeStyle: 'dashed',
          fillOpacity: 0,
          extData: { type: 'polygonQueryDele' }
        })
        that.tipMessege = '移动鼠标，双击结束绘制'
        that.drawTipmouse = '单击确定起点，双击结束绘制'
        break
      }
      case 'circle': {
        this.mouseTool.circle({
          fillColor: that.sketchColor,
          strokeColor: that.sketchColor
        })
        that.tipMessege = '按住鼠标左键移动，松开鼠标左键结束绘制'
        that.drawTipmouse = '按住鼠标左键移动，松开鼠标左键结束绘制'
        break
      }
      case 'rectangle': {
        this.mouseTool.rectangle({
          fillColor: that.sketchColor,
          strokeColor: that.sketchColor
        })
        that.tipMessege = '按住鼠标左键移动，松开鼠标左键结束绘制'
        that.drawTipmouse = '按住鼠标左键移动，松开鼠标左键结束绘制'
        break
      }
      case 'rule': {
        this.ruleObj = []
        this.ruleM = 0
        this.mouseTool.rule({
          d: 1,
          lineOptions: {
            strokeStyle: 'solid',
            strokeColor: '#ffcc33',
            strokeOpacity: 1,
            strokeWeight: 2
          }
        })
        that.tipMessege = '单击确定起点'
        that.drawTipmouse = '单击确定起点，双击结束绘制'
        status = false
        break
      }
      case 'zoomIn': {
        this.mouseTool.rectZoomIn({
          strokeColor: '#80d8ff',
          fillColor: '#80d8ff',
          fillOpacity: 0.3
          //同 Polygon 的 Option 设置
        })
        break
      }
      case 'zoomOut': {
        this.mouseTool.rectZoomOut({
          strokeColor: '#80d8ff',
          fillColor: '#80d8ff',
          fillOpacity: 0.3
          //同 Polygon 的 Option 设置
        })
        break
      }
      }
      this.showTip = status
      this.tipPoint = []
    },
    onClick(e) {
      let that = this
      that.tipPoint.push(e.lnglat)
      if (this.showTip && that.tipPoint.length < 2) {
        var infoWindowContent = `<div class="drawTip">${that.tipMessege}</div>`
        this.tipInfo = new AMap.InfoWindow({
          anchor: 'top-left',
          content: infoWindowContent
        })
        that.tipInfo.open(that.map, e.lnglat)
        setTimeout(() => {
          that.tipInfo.close()
        }, 1500)
      }
    },
    getFeatrue(lnglat) {
      this.$refs.markInfo.getFeatrue(lnglat)
    },
    /*
     ****************************************************************************************************
     * 功能描述： 添加高亮线
     * 开发人： 黄丽玲
     * 开发时间： 2022-6-9
     * pos 线的位置
     ****************************************************************************************************
     */
    lineHighlight(pos) {
      if (this.markerHighlight) {
        this.map.remove(this.markerHighlight)
      }
      if (this.polylineHighlight) {
        this.map.remove(this.polylineHighlight)
      }
      if (this.circleHighlight) {
        this.map.remove(this.circleHighlight)
      }
      if (this.rectangleHighlight) {
        this.map.remove(this.rectangleHighlight)
      }
      this.polylineHighlight = new AMap.Polyline({
        path: pos,
        isOutline: true,
        outlineColor: '#1A5F48',
        borderWeight: 3,
        strokeColor: '#00FF7F',
        strokeOpacity: 1,
        strokeWeight: 3,
        strokeStyle: 'solid',
        lineJoin: 'round',
        lineCap: 'round',
        zIndex: 150,
        extData: ''
      })
      this.map.add(this.polylineHighlight)
      //this.map.setCenter(pos[0])
    },
    /*
     ****************************************************************************************************
     * 功能描述： 删除高亮线
     * 开发人： 黄丽玲
     * 开发时间： 2022-6-9
     ****************************************************************************************************
     */
    lineHighlightRemove() {
      if (this.polylineHighlight) {
        this.map.remove(this.polylineHighlight)
      }
    },
    /*
     ****************************************************************************************************
     * 功能描述： 添加高亮点
     * 开发人： 黄丽玲
     * 开发时间： 2022-6-9
     * pos 位置信息
     ****************************************************************************************************
     */
    pointHighlight(pos,icon) {
      if (this.markerHighlight) {
        this.map.remove(this.markerHighlight)
      }
      if (this.polylineHighlight) {
        this.map.remove(this.polylineHighlight)
      }
      if (this.circleHighlight) {
        this.map.remove(this.circleHighlight)
      }
      if (this.rectangleHighlight) {
        this.map.remove(this.rectangleHighlight)
      }
      let content = ''
      if(icon){
        content ='<div> <span class="icon iconfont '+icon+'" style="color:red"></span>' +
        '</div>'
      }else{
        content = '<div> <div class="scatterPoint scatterPoint2"></div>' +
        '<div class="scatterPoint scatterPoint3" ></div></div>'
      }
      
      this.markerHighlight = new AMap.Marker({
        content: content,
        anchor:'center',
        offset: new AMap.Pixel(0, 0),
        position: pos,
        extData: ''
      })

      this.map.add(this.markerHighlight)
      //this.map.setCenter(pos)
    },
    /*
     ****************************************************************************************************
     * 功能描述： 删除高亮点
     * 开发人： 黄丽玲
     * 开发时间： 2022-6-9
     ****************************************************************************************************
     */
    pointHighlightRemove() {
      if (this.markerHighlight) {
        this.map.remove(this.markerHighlight)
      }
    },
    circleHighlightF(center, radius, extData) {
      if (this.markerHighlight) {
        this.map.remove(this.markerHighlight)
      }
      if (this.polylineHighlight) {
        this.map.remove(this.polylineHighlight)
      }
      if (this.circleHighlight) {
        this.map.remove(this.circleHighlight)
      }
      if (this.rectangleHighlight) {
        this.map.remove(this.rectangleHighlight)
      }
      this.circleHighlight = new AMap.Circle({
        center: center,
        radius: radius,
        strokeColor: '#00FF7F',
        strokeWeight: 3,
        zIndex: 160,
        extData
      })
      this.map.add(this.circleHighlight)
      this.map.setCenter(center)
    },
    circleHighlightRemove() {
      if (this.circleHighlight) {
        this.map.remove(this.circleHighlight)
      }
    },
    rectangleHighlightF(pos, extData) {
      if (this.markerHighlight) {
        this.map.remove(this.markerHighlight)
      }
      if (this.polylineHighlight) {
        this.map.remove(this.polylineHighlight)
      }
      if (this.circleHighlight) {
        this.map.remove(this.circleHighlight)
      }
      if (this.rectangleHighlight) {
        this.map.remove(this.rectangleHighlight)
      }
      let bounds = new AMap.Bounds(pos[0], pos[1])
      this.rectangleHighlight = new AMap.Rectangle({
        bounds: bounds,
        strokeColor: '#00FF7F',
        strokeWeight: 3,
        zIndex: 160,
        extData
      })
      this.map.add(this.rectangleHighlight)
      this.map.setCenter(bounds[0])
    },
    pointHighlightAll(list,showdesc){
      let that = this;
      this.removeHighligAll()
      list.forEach(el=>{
        let pos = new AMap.Marker({
          content:
            '<div> <div class="scatterPoint scatterPoint2"></div>' +
            '<div class="scatterPoint scatterPoint3" ></div></div>',
          offset: new AMap.Pixel(0, 0),
          position: el.pointArray[0],
          extData: el
        })
        pos.on('click', function(e) {
          if(showdesc){
            const obj = e.target.getExtData()
            that.$refs.editFeature.openUserStion(obj.id,obj.pointArray[0])
          }
        })

        this.map.add(pos)
        this.LabelsLayerHighlight.push(pos)
      })

    },
    removeHighligAll(){
      if (this.LabelsLayerHighlight) {
        this.LabelsLayerHighlight.forEach(el=>{
          this.map.remove(el)
        })
        this.LabelsLayerHighlight =[]
      } 
    },
    
    rectangleHighlightFRemove() {
      if (this.rectangleHighlight) {
        this.map.remove(this.rectangleHighlight)
      }
    },
    getBoundsFeat(typeName) {
      if (!typeName || this.$refs.topTool.activeOptionItem != 'adsorption' || !this.queryVector) {
        return
      }
      let that = this
      let bounds = that.map.getBounds()
      this.params = {
        SERVICE: 'WMS',
        VERSION: '1.1.0',
        REQUEST: 'GetMap',
        FORMAT: 'image/png',
        LAYERS: typeName,
        SRS: 'EPSG:4326',
        format: 'geojson',
        WIDTH: 768,
        HEIGHT: 330,
        STYLES: '',
        BBOX:
          bounds.southWest.lng +
          ',' +
          bounds.southWest.lat +
          ',' +
          bounds.northEast.lng +
          ',' +
          bounds.northEast.lat,
        CQL_FILTER: filterLayer(this.$store)
      }

      queryFeatrue(this.params).then(res => {
        let features = res.features
        that.queryVectorGeo = features
      })
    }
  },
  beforeDestroy() {
    // if (this.map) {
    this.map.destroy()
    this.clearEvent()
    // }
  }
}
