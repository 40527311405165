<template>
  <div>
    <el-dialog
      :title="addOrEdit == 'add' ? '新增监测点' : '编辑监测点'"
      :visible.sync="dialogVisible"
      :inline="true"
      :modal="false"
      :close-on-click-modal="false"
      width="356px"
      custom-class="dialog-class"
      @close="close"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="100px" size="mini">
        <el-form-item label="经纬度:">
          <el-input v-model="form.point" disabled placeholder="请输入经纬度"></el-input>
        </el-form-item>
        <el-form-item label="监测点名称:" prop="pointName">
          <el-input
            v-model="form.pointName"
            placeholder="请输入监测点名称"
            maxlength="50"
          ></el-input>
        </el-form-item>
        <el-form-item label="所在地址:" prop="address">
          <el-input
            v-model="form.address"
            placeholder="请输入所在地址"
            maxlength="50" 
          ></el-input>
        </el-form-item>
      </el-form>
      <div v-if="addOrEdit == 'add'" class="btn">
      <el-button size="mini" @click="close">取消</el-button>
        <el-button size="mini" type="primary" @click="submit">保存</el-button>
        
      </div>
      <div v-else class="btn">
        <el-button size="mini" @click="close">取消</el-button>
        <el-button size="mini" type="primary" @click="update">保存</el-button>
        
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { saveMonitor, updateMonitor } from '../apis/tool'
export default {
  name: 'TopTool',
  data() {
    return {
      addOrEdit: 'add',
      form: {},
      dialogVisible: false,
      rules: {
        pointName: [{ required: true, message: '请输入监测点名称', trigger: 'blur' }],
        address: [{ required: true, message: '请输入所在地址', trigger: 'blur' }],
      },
      tableData: []
    }
  },
  mounted() {},
  methods: {
    openDialog(data) {
      if (data.addOrEdit == 'edit') {
        // 编辑
        this.addOrEdit = data.addOrEdit
        this.form = {
          ...data
        }
        this.tableData = data.eqs
      } else {
        // 新增
        this.form.pipeId = data.id
        this.form.point = data.lnglat
        let that = this;
        var geocoder = new AMap.Geocoder();
        let lnglat1 = this.form.point.split(' ')
          geocoder.getAddress(lnglat1, function (status, result) {
            if (result.info === "OK") {
              that.$set(that.form,'address',result.regeocode.formattedAddress);
            }
          });
      }
      this.dialogVisible = true
    },
    close() {
      this.dialogVisible = false
      this.$parent.$parent.editTool.close(true)
      this.$parent.$parent.pointHighlightRemove()
      if (this.addOrEdit == 'add') {
        this.$parent.startDraw('monitoring')
      }
    },
    update() {
      this.form = {
        ...this.form,
        layerId: this.$store.getters.currentLayer
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          updateMonitor(this.form)
            .then(res => {
              if (res.code == 200) {
                this.$message.success(res.msg)
                this.$eventBus.$emit('setFilter')
                this.close()
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch(err => {
              this.$message.error(err)
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    submit() {
      let that = this
      that.form = {
        ...that.form,
        layerId: that.$store.getters.currentLayer
      }
      that.$refs.form.validate(valid => {
        if (valid) {
          saveMonitor(that.form)
            .then(res => {
              if (res.code == 200) {
                that.$message.success(res.msg)
                that.$eventBus.$emit('setFilter')
                that.close()
              } else {
                that.$message.error(res.msg)
              }
            })
            .catch(err => {
              that.$message.error(err)
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm() {
      this.$refs.form.resetFields()
    }
  }
}
</script>
<style lang="scss" scoped>
.btn {
  text-align: right;
  button {
    margin: 7px 15px 0 15px;
  }
}
</style>
