
import axios from '@/common/js/request'

export function warningPage(params) {
  return axios({
    url: '/scada/warning/page',
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    },
    params
  })
}
//停止声光告警
export function stopLightVoice(params) {
  return axios({
    url: '/scada/warning/stopLightVoice',
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    },
    params
  })
}

//告警处置
export function handleWarning(data) {
  return axios({
    url: '/scada/warning/handleWarning',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}


