/*
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-07-22 08:57:14
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-09-16 16:34:59
 * @FilePath: \linqingpc\src\views\Gis\apis\base.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '../utils/request'
export function drawing_queryConfig(params) {
  return request({
    url: '/config/pipe/drawing/queryConfig',
    method: 'get',
    params
  })
}
export function set_queryConfig(params){
    return request({
        url: '/config/threshold/set/queryConfig',
        method: 'get',
        params
      })
}
export function set_update(params){
    return request({
        url: '/config/threshold/set/update',
        method: 'post',
        data:params
      })
}
export function set_setGeneralStatus(params){
    return request({
        url: '/config/threshold/set/setGeneralStatus',
        method: 'get',
        params
      })
}
export function drawing_getNotUsedDictList(params) {
    return request({
      url: '/config/pipe/drawing/getNotUsedDictList',
      method: 'get',
      params
    })
  }
  export function drawing_save(params) {
    return request({
        url: '/config/pipe/drawing/save',
        method: 'post',
        data:params
    })
}
export function drawing_update(params) {
    return request({
        url: '/config/pipe/drawing/update',
        method: 'post',
        data:params
    })
}
export function drawing_delete(params) {
    return request({
        url: '/config/pipe/drawing/delete',
        method: 'get',
        params
    })
}
export function attribute_delete(params) {
    return request({
        url: '/config/free/attribute/delete',
        method: 'get',
        params
    })
}
export function attribute_queryConfig(params) {
    return request({
        url: '/config/free/attribute/queryConfig',
        method: 'get',
        params
    })
}
export function attribute_save(params) {
    return request({
        url: '/config/free/attribute/save',
        method: 'post',
        data:params
    })
}
export function attribute_update(params) {
    return request({
        url: '/config/free/attribute/update',
        method: 'post',
        data:params
    })
}
export function selectLineRealtimeList(params) {
    return request({
        url: '/config/scada/lineConfig/selectLineRealtimeList',
        method: 'post',
        data:params
    })
}
export function selectMonitorPointList(params) {
    return request({
        url: '/config/scada/lineConfig/selectMonitorPointList',
        method: 'post',
        data:params
    })
}
export function bindMonitorPoint(params) {
    return request({
        url: '/config/scada/lineConfig/bindMonitorPoint',
        method: 'post',
        data:params
    })
}
export function unBindMonitorPoint(params) {
    return request({
        url: '/config/scada/lineConfig/unBindMonitorPoint',
        method: 'post',
        data:params
    })
}

export function selectStationRealtimeList(params) {
    return request({
        url: '/config/scada/stationConfig/selectStationRealtimeList',
        method: 'post',
        data:params
    })
}

export function selectStationGisPointList(params) {
    return request({
        url: '/config/scada/stationConfig/selectStationGisPointList',
        method: 'post',
        data:params
    })
}

export function unBindGisPoint(params) {
    return request({
        url: '/config/scada/stationConfig/unBindGisPoint',
        method: 'post',
        data:params
    })
}
export function bindGisPoint(params) {
    return request({
        url: '/config/scada/stationConfig/bindGisPoint',
        method: 'post',
        data:params
    })
}

export function selectScadaLineWarn(params) {
    return request({
        url: '/monitorPoint/selectScadaLineWarn',
        method: 'get',
        params
    })
}
export function selectScadaStationWarn(params) {
    return request({
        url: 'station/selectScadaStationWarn',
        method: 'get',
        params
    })
}
// 获取车辆信息列表
export function getlistCarInfo(params) {
    return request({
        url: '/patrolCar/listCarInfo',
        method: 'get',
        params
    })
}
// 获取车辆信息（轨迹）
export function getCarInfo(params) {
    return request({
        url: '/patrolCar/getCarInfo',
        method: 'get',
        params
    })
}
// 获取BI展示人员列表
export function getbiUserLists(params) {
    return request({
        url: '/patrolCard/biUserList',
        method: 'get',
        params
    })
}
// 获取BI人员详细信息
export function getBiUserInfo(params) {
    return request({
        url: '/patrolCard/getBiUserInfo',
        method: 'get',
        params
    })
}