<template>
  <div class="patrolLine" onselectstart="return false;">
    <div class="mapLeft" :class="{ 'hidden': !open }">
      <div class="map-title">
        <div class="tabs">
          <div class="tab" @click="handleClick(1)" :class="{ 'actie': pageParams.installType == 1 }">场站</div>
          <div class="tab" @click="handleClick(2)" :class="{ 'actie': pageParams.installType == 2 }">管网</div>
        </div>
        <div style="cursor: pointer;" @click="open = !open">
          <i v-if="open" class="el-icon-arrow-up"></i>
          <i v-else class="el-icon-arrow-down"></i>
        </div>
      </div>
      <div class="list-box" v-if="open">
        <div class="list" v-for="(item, index) in list" :key="item.id" :class="{ 'activeClick': selected == item.id }"
          @click="onClickList(item, 1,index)">
          <div>
            报警器编码：<span>{{ item.equipmentCode }}</span>
          </div>
          <div>
            报警器名称：<span>{{ item.equipmentName }}</span>
          </div>
          <div style="display: flex;justify-content: space-between;">
            <div>
              报警值：<span>{{ item.val || item.val ? item.val : '-' }}</span>
            </div>
            <div>
              <el-button size="mini" @click.stop="onDialog(item.id)"
                style="background: rgba(255,0,0,0.2);color: #FFE3E3; border: 0;">处理</el-button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="open">
        <div class="pages">
          <el-pagination small :current-page="pageParams.current" :page-size="pageParams.size" :total="total"
            @current-change="currentChange" layout="total, prev, pager, next">
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="mapRight" ref="mapRightRef" id="mapRightId">
      <subMap ref="subMap" @markerClick="onClickList" @warnHandle="onDialog" :warnList="mapList" :modeal="'1'" />
    </div>
    <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false" width="470px" class="bounddialog">
      <template slot="title">
        <div class="dialogTitles">
          <span></span> <b>告警处置</b>
        </div>
      </template>
      <el-form v-if="dialogVisible" :model="formParams" class="demo-form-inline" label-position="right">
        <el-form-item label="备注">
          <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="formParams.handleNote">
          </el-input>
        </el-form-item>
      </el-form>
      <div class="search-q" style="text-align: center;margin-top: 20px;">
        <el-button type="info" plain @click="dialogVisible = false" size="mini">取消</el-button>
        <el-button type="primary" @click="submit()" size="mini">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { warningPage,handleWarning } from '@/apis/warn/alarmManagement/pendingAlarm'
// import subMap from '@/views/mapTool/subMapbi'
import subMap from '@/views/Gis/gisbi'
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'PatrolHouse',
  components: { subMap },
  props: {},
  data: () => ({
    open: true,
    dialogVisible: false,
    pageParams: {
      current: 1,
      size: 100,
      queryType: 1,
      installType: 1,
      equipmentType: 4
    },
    mapList: [],
    list: [

    ],
    formParams: {

    },
    total: 0,
    selected: null,
    currentArea: null,
    bubbles: null,
    chart: null,
    MenuArray: {}
  }),
  computed: {
  },
  destroyed() {
    document.body.classList.remove('black')
  },
  created() {
    this.loadList(this.pageParams)
  },
  mounted() {
    let rightTool = document.getElementsByClassName('rightTool')[0]
    rightTool.style.right = '0'
    rightTool.style.bottom = '70px'
  },
  methods: {
    handleClick(e) {
      this.list = []
      this.pageParams = {
        current: 1,
        size: 100,
        queryType: 1,
        equipmentType: 4
      }
      if (this.pageParams.installType != e) {
        this.pageParams.installType = e
      }
      this.loadList(this.pageParams)
    },
    onClickList(e, type,index) {
      this.selected = e.id
      if (type == 1 && e.point) {
        let str = (e.point).slice(6, -1)
        let arr = str.split(' ')
        console.log(arr)
        this.$refs.subMap.setZoom(arr,index)
      }
    },
    currentChange(current) {
      this.selected = null;
      this.pageParams.current = current
      this.loadList(this.pageParams)
    },
    loadList(data) {
      let obj = JSON.parse(JSON.stringify(data))
      obj.handleStatus = 0
      warningPage(obj).then(res => {
        if (res.code == 200) {
          this.list = res.data.list
          this.total = res.data.total
          let mapList = this.list.map(el => {
            if (el.point) {
              let str = (el.point).slice(6, -1)
              let arr = str.split(' ')
              let obj = {
                id: el.id,
                title: el.equipmentName,
                position: arr,
                data: el
              }
              return obj
            } else {
              return
            }
          })
          this.mapList = mapList.filter(el => el != null)
        }
      })
    },
    onDialog(id) {
      this.formParams = {
        handleNote: "",
        id
      }
      this.dialogVisible = true
    },
    submit() {
      handleWarning(this.formParams).then(res => {
        if (res.code == 200) {
          this.$message.success(res.msg)
          this.dialogVisible = false
          this.loadList(this.pageParams)
        } else {
          this.dialogVisible = false
          this.$message.error(res.msg)
          this.loadList(this.pageParams)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-pager li {
  color: #FFFFFF;
  background: #47080C;
}

::v-deep .el-pagination .btn-prev {
  color: #FFFFFF;
  background: #47080C;
}

::v-deep .el-pagination .btn-next {
  color: #FFFFFF;
  background: #47080C;
}

::v-deep .el-pagination__total {
  color: #FFFFFF;
}


.patrolLine {
  /* height: calc(100vh - 1rem);
  margin-top: 1rem; */
  height: calc(100vh - 152px);
  position: relative;
}

.mapLeft {
  position: absolute;
  left: 20px;
  top: 80px;
  width: 325px;
  // height: 689px;
  height: calc(100% - 200px);
  min-height: 400px;
  background: rgba(40, 4, 4, 0.96);
  border: 2px solid #BC1414;
  box-shadow: 0px 0px 6px #BC1414;
  border-radius: 4px;
  z-index: 200;

  &.hidden {
    height: auto;
    min-height: 0;
  }

  .map-title {
    margin: 12px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(90deg, #BC1414 0%, rgba(188, 20, 20, 0) 100%);
    padding-right: 10px;

    .tabs {
      display: flex;

      .tab {
        padding: 3px 0;
        margin: 0 15px;
        cursor: pointer;
        color: #FFAEAE;

        &.actie {
          color: #fff;
          border-bottom: 1px solid #fff;
        }
      }
    }
  }

  .list-box {
    padding: 0 14px;
    height: calc(100% - 95px);
    overflow: auto;

    .list {
      padding: 10px 6px;
      color: #FFE3E3;
      border-bottom: 1px solid #4C080C;
      cursor: pointer;

      &.activeClick {
        color: #fff;
        background: linear-gradient(90deg, #BC1414 0%, rgba(188, 20, 20, 0) 100%);
      }
    }
  }

  .pages {
    position: absolute;
    bottom: 0;
  }
}

.mapRight {
  width: auto;
  margin-left: 0;
  position: relative;
  overflow: hidden;
  /* height:calc(91vh); */
  height: calc(100vh - 72px);

}
</style>
