<template>
  <div class="bubbleBox">
    <div ref="selectLine" class="Bubble">
      <div class="markbox">
        <div class="markTitle">
          <p>
            <span
              >请选择现有{{
                (imgList[selectEq] && imgList[selectEq].text) || '设备'
              }}保留在哪条管线？</span
            >
          </p>
          <i class="el-icon-error" @click="closeWindow()"></i>
        </div>
        <div class="mark-con">
          <li
            v-for="(item, index) in selectLine"
            :key="index"
            class="li"
            @mouseout="selectClear()"
            @mouseover="selectFeatrue(item)"
            @click="saveBreak(item.id)"
          >
            名称：{{ item.pipeName }}
          </li>
        </div>
      </div>
    </div>

    <div ref="selectLineStation" class="Bubble">
      <div class="markbox">
        <div class="markTitle">
          <p>
            <span>请选择要绑定的管线。</span>
          </p>
          <!-- <i class="el-icon-error" @click="closeWindow()"></i> -->
        </div>
        <div class="mark-con">
          <li
            v-for="(item, index) in aloneLine"
            :key="index"
            class="li"
            @click="saveSelectLine(item)"
          >
            <span v-for="itemsub in item.pipes" :key="itemsub + 'ch'" style="padding-right:10px">{{
              itemsub.pipeName
            }}</span>
          </li>
        </div>
      </div>
    </div>
    <div ref="selectDragLine" class="Bubble">
      <div class="markbox">
        <div class="markTitle">
          <p>
            <span>请选择要移动的管线/设备，再拖拽节点。</span>
          </p>
          <i class="el-icon-error" @click="closeWindow()"></i>
        </div>
        <div class="mark-con">
          <li
            v-for="(item, index) in aloneLine"
            :key="index"
            class="li"
            @mouseout="selectClear()"
            @mouseover="selectDragFeatrue(item)"
            @click="saveSelectDrag(item)"
          >
            <span v-for="itemsub in item.pipes" :key="itemsub" style="padding-right:10px">{{
              itemsub.pipeName
            }}</span>
          </li>
          <li
            v-for="(item, index) in alonePoint"
            :key="index"
            class="li"
            @mouseout="selectClear()"
            @mouseover="selectPointFeatrue(item)"
            @click="saveSelectPoint(item)"
          >
            <span v-if="item.node_type2 == constantDict.gongqizhan.dictCode">供气场站</span>
            <span v-if="item.node_type2 == constantDict.yonghuzhan.dictCode">用户场站</span>
            <span v-if="item.node_type2 == constantDict.jiancedian.dictCode">监测点</span>
            <span v-if="item.node_type2 == constantDict.xieloubaojing.dictCode">泄露报警器</span>
            <span v-if="item.node_type2 == constantDict.shexiangtou.dictCode">摄像头</span>
          </li>
        </div>
      </div>
    </div>

    <div ref="dragPoint" class="Bubble">
      <div class="markbox">
        <div class="markTitle">
          <p>
            <span>提示</span>
          </p>
          <i class="el-icon-error" @click="closeWindow()"></i>
        </div>
        <div class="mark-con">
          <p>请选择保留哪种节点？</p>
          <div class="btn">
            <span v-for="(item, index) in buttonList" :key="index + 'node'">
              <el-button
                v-if="dragForm.data.item && dragForm.data.item.node_type2 == item.dictCode"
                type="primary"
                style="margin-left:10px"
                @click="selectDragButton(1, item.dictCode)"
                >被拖拽{{ item.name }}</el-button
              >
            </span>
            <span v-for="(item, index) in buttonList" :key="index">
              <el-button
                v-if="dragForm.queryPoint.node_type2 == item.dictCode"
                type="primary"
                style="margin-left:10px"
                @click="selectDragButton(2, item.dictCode)"
                >原{{ item.name }}</el-button
              >
            </span>
            <span>
              <el-button
                v-if="saveGetForm.line > 2"
                type="primary"
                style="margin-left:10px"
                @click="selectDragButton(3, constantDict.santong.dictCode)"
                >转为{{ constantDict.santong.name }}</el-button
              >
              <el-button
                v-else-if="saveGetForm.bore"
                type="primary"
                style="margin-left:10px"
                @click="selectDragButton(3, constantDict.gangsuzhuanhuan.dictCode)"
                >转为{{ constantDict.gangsuzhuanhuan.name }}</el-button
              >
              <el-button
                v-else-if="saveGetForm.material"
                type="primary"
                style="margin-left:10px"
                @click="selectDragButton(3, constantDict.bianjin.dictCode)"
                >转为{{ constantDict.bianjin.name }}</el-button
              >
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import pl from '@/assets/gis/pl.png'
import {
  centerPointGetFourPoint,
  filterLayer,
  lineRangePoint,
  formatLocation
} from '../../utils/geoTool.js'
import { queryFeatrue, regionList } from '../../apis/geo'
import { commonLayer, constantDict, serverMap } from '../../utils/dict'

import {
  pipeDrag,
  pipePointBreak,
  pipeLink,
  pipeLineBreak,
  cameraUpdatePoint,
  warningUpdatePoint,
  pointDragPoint,
  pointDragStation,
  monitorPointDragPoint
} from '../../apis/tool.js'

export default {
  data() {
    return {
      aloneLine: [],
      alonePoint: [],
      lineVector: null,
      editorLineGroups: null,
      lineConnet: [],
      lineData: [],
      infoWindow: null,
      selectLine: [],
      selectEq: '',
      hoverLine: null,
      breakForm: {},
      dragForm: {
        data: {},
        queryPoint: {}
      },
      buttonList: [
        constantDict.jiedian,
        constantDict.qitaguanjian,
        constantDict.guanxiandian,
        constantDict.yonghuzhan,
        ...serverMap[10031],
        ...serverMap[1002]
      ],
      constantDict: constantDict,
      saveGetForm: {
        line: 0, //拖拽到某个点所关联的线的总条数
        bore: false,
        material: false
      },
      lineLinkmonitor: {
        monitor: [],
        have: true,
        num: 0
      },
      imgList: {
        0: { img: require('../../../../assets/gis/map/0.png'), text: '节点' },
        9000: { img: require('../../../../assets/gis/map/9000.png'), text: '监测点' },
        25: { img: require('../../../../assets/gis/map/25.png'), text: '其他管件' },
        '-1': { img: require('../../../../assets/gis/map/-1.png'), text: '管线点' },
        9001: { img: require('../../../../assets/gis/map/9001.png'), text: '重合节点' },
        9002: { img: require('../../../../assets/gis/map/9002.png'), text: '泄露报警' },
        9003: { img: require('../../../../assets/gis/map/9003.png'), text: '摄像头' },
        // 9004:require('../../../../assets/gis/map/9004.png'),
        1: { img: require('../../../../assets/gis/map/1.png'), text: '供气站' },
        2: { img: require('../../../../assets/gis/map/2.png'), text: '用户站' },
        21: { img: require('../../../../assets/gis/map/21.png'), text: '阀门' },
        24: { img: require('../../../../assets/gis/map/24.png'), text: '钢塑转换' },
        22: { img: require('../../../../assets/gis/map/22.png'), text: '三通' },
        23: { img: require('../../../../assets/gis/map/23.png'), text: '变径' }
      },
      pointStr: ''
    }
  },
  beforeDestroy() {
    this.clearEvent()
  },
  mounted() {
    this.setEventBut()
  },
  methods: {
    /*
     ****************************************************************************************************
     * 功能描述：选择管线、节点
     * 开发人： 孙志萍
     * 开发时间： 2022-9-30
     *****************************************************************************************************
     */
    saveSelectDrag(item) {
      this.drawLineVector([item])
      this.drawVector([item], [])
      this.closeWindow()
    },
    saveSelectPoint(item) {
      this.drawVector([], [item])
      this.closeWindow()
    },
    clearEvent() {
      this.$eventBus.$off('clearDrag')
    },
    setEventBut() {
      this.$eventBus.$on('clearDrag', data => {
        if (this.lineVector && this.lineVector.length > 0) {
          this.$parent.map.remove(this.lineVector)
          this.lineVector = []
        }
        if (this.editorLineGroups) {
          this.editorLineGroups.clearOverlays()
        }
      })
    },

    selectClear() {
      if (this.hoverLine) {
        this.$parent.map.remove(this.hoverLine)
        this.hoverLine = null
      }
    },
    selectPointFeatrue(item) {
      if (this.hoverLine) {
        this.$parent.map.remove(this.hoverLine)
        this.hoverLine = null
      }
      let imgList = this.imgList
      let pl =
        imgList[item.node_type2] && imgList[item.node_type2].img ? imgList[item.node_type2].img : pl
      let icon = new AMap.Icon({ image: pl, imageOffset: new AMap.Pixel(0, 0) })

      this.hoverLine = new AMap.Marker({
        icon,
        draggable: true,
        position: item.location,
        anchor: 'middle-center',
        offset: [-13, -10]
      })

      this.$parent.map.add(this.hoverLine)
    },

    selectDragFeatrue(item) {
      if (this.hoverLine) {
        this.$parent.map.remove(this.hoverLine)
        this.hoverLine = null
      }
      let arr = []
      item.pipes.forEach(pipe => {
        let p = new AMap.Polyline({
          path: pipe.lineArray,
          strokeColor: '#3ba1fc',
          strokeWeight: 4,
          strokeOpacity: 0.9,
          isOutline: true,
          outlineColor: '#ffffff',
          borderWeight: 1,
          zIndex: 180,
          extData: { ...pipe },
          bubble: true
        })
        arr.push(p)
      })
      this.hoverLine = arr

      this.$parent.map.add(this.hoverLine)
    },
    selectFeatrue(item) {
      if (this.hoverLine) {
        this.$parent.map.remove(this.hoverLine)
        this.hoverLine = null
      }
      this.hoverLine = new AMap.Polyline({
        path: item.lineArray,
        strokeColor: '#3ba1fc',
        strokeWeight: 4,
        strokeOpacity: 0.9,
        isOutline: true,
        outlineColor: '#ffffff',
        borderWeight: 1,
        zIndex: 180,
        extData: { ...item },
        bubble: true
      })

      this.$parent.map.add([this.hoverLine])
    },
    closeWindow() {
      if (this.infoWindow) {
        this.infoWindow.close()
      }
    },
    /*
     ****************************************************************************************************
     * 功能描述：拖拽/打断
     * 开发人： 孙志萍
     * 开发时间： 2022-5-27
     ****************************************************************************************************
     */
    getVectorQuery(lnglat, fun) {
      let that = this
    
      let points = centerPointGetFourPoint(that.$parent.map, lnglat)
      let a = points.leftBottomPoint[0]
      let b = points.leftBottomPoint[1]
      let c = points.upRightPoint[0]
      let d = points.upRightPoint[1]

      let line = filterLayer(this.$store)
      let lineP = {}
      if (line) {
        lineP = { CQL_FILTER: line }
      }
      let parames = {
        SERVICE: 'WMS',
        VERSION: '1.1.0',
        REQUEST: 'GetFeatureInfo',
        FORMAT: 'image/png',
        TRANSPARENT: true,
        QUERY_LAYERS: that.$parent.layerName || commonLayer['pressure'],
        LAYERS: that.$parent.layerName || commonLayer['pressure'],
        exceptions: 'application,Fvnd.ogc.se_inimage',
        INFO_FORMAT: 'application/json',
        FEATURE_COUNT: 50,
        X: 50,
        y: 50,
        SRS: 'EPSG:4326',
        WIDTH: 101,
        HEIGHT: 101,
        BBOX: a + ',' + b + ',' + c + ',' + d,
        ...lineP
      }
      queryFeatrue(parames).then(res => {
        let features = res.features
        if (features.length > 0) {
          if (fun != 'drag') {
            //打断
            that.breakPoint(features, lnglat)
          } else {
            //拖拽
            that.setFeatrue(features)
          }
        }
      })
    },
    breakPoint(features, lnglat) {
      let that = this
      let point = []
      let line = []
      features.forEach(item => {
        if (item.geometry.type == 'LineString') {
          item.properties.location = formatLocation(item.properties.location_str, 'LineString')
          line.push(item.properties)
        } else if (item.geometry.type == 'Point') {
          //供气站，安全设备(摄像头，泄露报警)单独点不需要打断
          let node_type2 = item.properties.node_type2
          if (
            node_type2 == constantDict.shexiangtou.dictCode ||
            node_type2 == constantDict.xieloubaojing.dictCode ||
            node_type2 == constantDict.gongqizhan.dictCode ||
            node_type2 == constantDict.jiancedian.dictCode
          ) {
          } else {
            //节点，阀门，用户站，其他管件，管线点  需要查询管线拖动的
            item.properties.location = formatLocation(item.properties.location_str, 'Point')
            point.push(item.properties)
          }
        }
      })

      if (point.length > 0) {
        pipeLink({ pointIds: point[0].id })
          .then(res => {
            if (res.data.length > 0 && res.data[0].pipes.length > 1) {
              let html = that.$refs.selectLine
              that.infoWindow = new AMap.InfoWindow({
                position: point[0].location,
                offset: new AMap.Pixel(0, 0),
                anchor: 'top-left',
                content: html
              })

              that.selectEq = point[0].node_type2
              that.selectLine = res.data[0].pipes
              that.breakForm = {}
              that.breakForm.pointId = point[0].id
              that.breakForm.layerId = point[0].layer_id
              that.infoWindow.open(that.$parent.map)
            }
          })
          .catch(err => {
          //  that.$message.error(err)
          })
      } else if (line.length > 0) {
        that
          .$confirm('是否对该管线进行打断操作?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            confirmButtonClass: 'confirmBtnClass',
            cancelButtonClass: 'cancelBtnClass',
            customClass: 'gis-default',
            type: 'warning'
          })
          .then(() => {
            that.saveBearkLine(line[0], lnglat)
          })
          .catch(() => {
            that.$message({
              type: 'info',
              message: '已取消'
            })
          })
      }
    },
    saveBearkLine(item, point) {
      //管线打断  不包含多个折线的情况
      let that = this
      let startPoint = item.location[0]
      let endPoint = item.location[item.location.length - 1]

      let amapEnd = new AMap.LngLat(endPoint[0], endPoint[1])
      let lineend = lineRangePoint(that.$parent.map, amapEnd)
      let linestart = lineRangePoint(that.$parent.map, point)
      let sqlLine =
        linestart[0].join(',') +
        ' ' +
        linestart[1].join(',') +
        ' ' +
        lineend[1].join(',') +
        ' ' +
        lineend[0].join(',') +
        ' ' +
        linestart[0].join(',') //lineRangePoint(that.$parent.map,lnglat)+' '+lineRangePoint(that.$parent.map,point)
      this.params = {
        SERVICE: 'wfs',
        VERSION: '1.0.0',
        REQUEST: 'GetFeature',
        typeName: commonLayer.monitoPoint,
        outputFormat: 'application/json',
        filter: this.getFiles(sqlLine)
      }

      queryFeatrue(this.params).then(res => {
        let features = res.features
        let monitorPointIds = []
        if (features.length > 0) {
          features.forEach(itemsub => {
            if (item.id == itemsub.properties.pipe_id) {
              monitorPointIds.push(itemsub.properties.id)
            }
          })
        }

        let p = {
          id: item.id,
          line1: startPoint.join(' ') + ', ' + point.lng + ' ' + point.lat,
          line2: point.lng + ' ' + point.lat + ', ' + endPoint.join(' '),
          point: point.lng + ' ' + point.lat,
          pipeLength1: AMap.GeometryUtil.distanceOfLine([startPoint, point]).toFixed(2),
          pipeLength2: AMap.GeometryUtil.distanceOfLine([endPoint, point]).toFixed(2),
          monitorPointIds
        }
        pipeLineBreak(p)
          .then(res => {
            that.$message.success(res.msg)
            that.$eventBus.$emit('setFilter')
          })
          .catch(err => {
         //   that.$message.error(err)
          })
      })
    },
    getFiles(pos) {
      //线上的点
      let file =
        '<Filter xmlns="http://www.opengis.net/ogc" xmlns:gml="http://www.opengis.net/gml">' +
        '<Intersects><PropertyName>location</PropertyName><gml:Polygon>' +
        '<gml:outerBoundaryIs>' +
        '<gml:LinearRing>' +
        '<gml:coordinates>' +
        pos +
        '</gml:coordinates>' +
        '</gml:LinearRing>' +
        '</gml:outerBoundaryIs>' +
        '</gml:Polygon></Intersects></Filter>'
      //多个图形查询
      // <Or>
      // <Intersects><PropertyName>location</PropertyName><gml:Polygon><gml:outerBoundaryIs><gml:LinearRing><gml:coordinates>117.73016,39.755328+117.668362,39.590433+117.783718,39.592549+117.808438,39.733154+117.73016,39.755328</gml:coordinates></gml:LinearRing></gml:outerBoundaryIs></gml:Polygon></Intersects>
      // <Intersects><PropertyName>location</PropertyName><gml:Polygon><gml:outerBoundaryIs><gml:LinearRing><gml:coordinates>117.7178,39.523729+117.753506,39.437872+117.948513,39.504659+117.845517,39.608421+117.7178,39.523729</gml:coordinates></gml:LinearRing></gml:outerBoundaryIs></gml:Polygon></Intersects>
      // </Or>
      return file
    },
    saveBreak(id) {
      //节点打断
      let that = this
      that
        .$confirm('是否对该管线进行打断操作?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          confirmButtonClass: 'confirmBtnClass',
          cancelButtonClass: 'cancelBtnClass',
          customClass: 'gis-default',
          type: 'warning'
        })
        .then(() => {
          that.breakForm.id = id
          pipePointBreak(this.breakForm)
            .then(res => {
              that.closeWindow()
              that.$message.success(res.msg)
              that.$eventBus.$emit('setFilter')
            })
            .catch(err => {
            //  that.$message.error(err)
            })
        })
        .catch(() => {
          that.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    setFeatrue(features) {
      let that = this
      let point = []
      let line = []
      let alonePoint = []
      features.forEach(item => {
        if (item.geometry.type == 'LineString') {
          line.push(item.properties)
        } else if (item.geometry.type == 'Point') {
          //供气站，安全设备(摄像头，泄露报警)单独拖拽点
          let node_type2 = item.properties.node_type2
          if (
            node_type2 == constantDict.shexiangtou.dictCode ||
            node_type2 == constantDict.xieloubaojing.dictCode ||
            node_type2 == constantDict.gongqizhan.dictCode ||
            node_type2 == constantDict.jiancedian.dictCode ||
            node_type2 == constantDict.yonghuzhan.dictCode
          ) {
            item.properties.noLine = true
            item.properties.location = formatLocation(item.properties.location_str, 'Point')
            alonePoint.push(item.properties)
          } else {
            //节点，阀门，其他管件，管线点  需要查询管线拖动的
            point.push(item.properties)
          }

          //其余的不允许拖动
        }
      })
      console.log(alonePoint)
      let lineConnet = []
      if (point.length > 0) {
        //此处去调用接口查点关联的线，根据点分组，然后去重线，保留的线是点组里多的线
        let ids = []
        point.forEach(point => {
          ids.push(point.id)
        })
        pipeLink({ pointIds: ids.toString() })
          .then(res => {
            if (res.data.length > 0) {
              let newPoint = res.data
              let newline = []

              let pointass = []
              newPoint.forEach((item, index) => {
                point.forEach(itemP => {
                  if (item.pointId == itemP.id) {
                    itemP.location = formatLocation(itemP.location_str, 'Point')
                    item = { ...item, ...itemP }
                  }
                })
                pointass.push(item)
              })
              //去重

              let aloneLine = []
              pointass.forEach(item => {
                let length = item.pipes.length
                let bigLength = true
                item.pipes.forEach(itemSub => {
                  itemSub.pointId = item.pointId
                  for (let i = 0; i < aloneLine.length; i++) {
                    aloneLine[i].pipes.forEach(haveitemSub => {
                      if (itemSub.id == haveitemSub.id) {
                        if (aloneLine[i].pipes.length > length) {
                          bigLength = false
                        } else {
                          aloneLine.splice(i, 1)
                          ++i
                        }
                      }
                    })
                  }
                })
                item.length = length
                if (bigLength) {
                  aloneLine.push(item)
                }
              })
              let l = alonePoint.length + aloneLine.length
              that.getLineMonitor(aloneLine)
              if (l > 1) {
                let html = that.$refs.selectDragLine
                that.infoWindow = new AMap.InfoWindow({
                  position: point[0].location,
                  offset: new AMap.Pixel(0, 0),
                  anchor: 'top-left',
                  content: html
                })
                that.infoWindow.open(that.$parent.map)
                that.aloneLine = aloneLine
                that.alonePoint = alonePoint
              } else {
                that.drawLineVector(aloneLine)
                that.drawVector(aloneLine, alonePoint)
              }

              // that.drawLineVector(aloneLine)
              // that.drawVector(aloneLine, alonePoint)
            }
          })
          .catch(err => {
         //   that.$message.error(err)
          })
      } else {
        this.drawVector([], alonePoint)
      }
    },
    formatLocation(val, type) {
      let pathObj = []
      if (type == 'LineString') {
        let location_str = val.substring(11, val.length - 1)
        let location = location_str.split(',')
        location.forEach(item => {
          pathObj.push(item.split(' '))
        })
      } else {
        let location_str = val.substring(6, val.length - 1)
        pathObj = location_str.split(' ')
      }
      return pathObj
    },
    drawLineVector(lineConnet) {
      if (this.lineVector && this.lineVector.length > 0) {
        this.$parent.map.remove(this.lineVector)
        this.lineVector = []
      }
      this.lineConnet = lineConnet
      let lineVector = []

      console.log(lineConnet)
      lineConnet.forEach(item => {
        item.pipes.forEach(itemSub => {
          console.log(itemSub.lineArray)

          let vector = new AMap.Polyline({
            path: itemSub.lineArray,
            strokeColor: '#3ba1fc',
            strokeWeight: 4,
            strokeOpacity: 0.9,
            isOutline: true,
            outlineColor: '#ffffff',
            borderWeight: 1,
            zIndex: 180,
            extData: { ...itemSub },
            bubble: true
          })
          lineVector.push(vector)
        })
      })

      this.lineVector = lineVector

      this.$parent.map.add(this.lineVector)
    },
    clearVector() {
      this.editorLineGroups.clearOverlays()
      if (this.lineVector && this.lineVector.length > 0) {
        this.$parent.map.remove(this.lineVector)
        this.lineVector = []
      }
    },
    drawVector(path, alonePoint) {
      console.log(path)
      let that = this

      if (!that.editorLineGroups) {
        that.editorLineGroups = new AMap.OverlayGroup({
          zIndex: 1000
        })
        that.$parent.map.add(that.editorLineGroups)
      }
      that.editorLineGroups.clearOverlays()

      let imgList = that.imgList
      path.forEach((item, index) => {
        let pl2 =
          imgList[item.node_type2] && imgList[item.node_type2].img
            ? imgList[item.node_type2].img
            : pl
        let icon = new AMap.Icon({ image: pl2, imageOffset: new AMap.Pixel(0, 0) })

        let point = new AMap.Marker({
          icon,
          draggable: true,
          position: item.location,
          extData: { index, item },
          anchor: 'middle-center',
          offset: [-13, -10]
        })
        that.editorLineGroups.addOverlay(point)
      })
      alonePoint.forEach((item, index) => {
        let pl =
          imgList[item.node_type2] && imgList[item.node_type2].img
            ? imgList[item.node_type2].img
            : pl
        let icon = new AMap.Icon({ image: pl, imageOffset: new AMap.Pixel(0, 0) })

        let point = new AMap.Marker({
          icon,
          draggable: true,
          position: item.location,
          extData: { index, item },
          anchor: 'middle-center',
          offset: [-13, -10]
        })
        that.editorLineGroups.addOverlay(point)
      })
      that.editorLineGroups.on('dragging', function(e) {
        let data = e.target.getExtData()
        if (data.item.noLine) {
          //保存单独的点  此处不处理
        } else {
          let lineVector = that.lineVector
          lineVector.forEach(item => {
            let lineData = item.getExtData()
            if (data.item.id == lineData.pointId) {
              let index = lineData.pointId == lineData.startPointId ? 0 : 1
              let linePath = item.getPath()
              linePath[index] = e.lnglat
              item.setPath(linePath)
            }
          })
        }
      })
      that.editorLineGroups.on('dragend', function(e) {
        let data = e.target.getExtData()
        console.log(data)

        if (data.item.node_type2 == constantDict.shexiangtou.dictCode) {
          //摄像头
          let sxt = {
            id: data.item.id,
            point: e.lnglat.lng + ' ' + e.lnglat.lat
          }
          cameraUpdatePoint(sxt)
            .then(res => {
              that.$message.success(res.msg)
              that.$eventBus.$emit('setFilter')
              that.clearVector()
            })
            .catch(err => {
            //  that.$message.error(err)
            })
        } else if (data.item.node_type2 == constantDict.xieloubaojing.dictCode) {
          //泄漏报警
          let xlbj = {
            id: data.item.id,
            point: e.lnglat.lng + ' ' + e.lnglat.lat
          }
          warningUpdatePoint(xlbj)
            .then(res => {
              that.$message.success(res.msg)
              that.$eventBus.$emit('setFilter')
              that.clearVector()
            })
            .catch(err => {
           //   that.$message.error(err)
            })
        } else if (
          data.item.node_type2 == constantDict.gongqizhan.dictCode ||
          data.item.node_type2 == constantDict.yonghuzhan.dictCode
        ) {
          //场站
          let xlbj = {
            id: data.item.id,
            point: e.lnglat.lng + ' ' + e.lnglat.lat
          }
          pointDragStation(xlbj)
            .then(res => {
              that.$message.success(res.msg)
              that.$eventBus.$emit('setFilter')
              that.clearVector()
            })
            .catch(err => {
             // that.$message.error(err)
            })
        } else {
          console.log(data)
          let lines = data.item.pipes
          let adsorption = that.$store.getters.activeOptionItem
          console.log(adsorption)
          if (
            (lines && lines.length > 2) ||
            (adsorption != 'adsorption' &&
              data.item.node_type2 != constantDict.jiancedian.dictCode) ||
            data.item.node_type2 == constantDict.yonghuzhan.dictCode ||
            data.item.channel_type
          ) {
            //三条以上管线不吸附 或不开启吸附 //data.item.node_type2==constantDict.yonghuzhan.dictCode||  监测点必须吸附
            let parames = that.setForm(e.lnglat, lines, data)
            that.saveDragSubmit(parames)
          } else {
            //开启吸附
            that.getRangeFeature(e.lnglat, lines, data)
          }
        }
      })
    },
    setForm(lnglat, lines, data) {
      let pipes = []
      lines.forEach(line => {
        let location = line.lineArray
        let index = line.pointId == line.startPointId ? 0 : 1
        location[index] = lnglat
        let newlocation = []
        location.forEach(item => {
          if (item.lat) {
            item = item.lng + ' ' + item.lat
          } else {
            item = item.join(' ')
          }
          newlocation.push(item)
        })

        var dis = AMap.GeometryUtil.distanceOfLine(location)
        let pipesItem = {
          id: line.id, //管线id
          pipeLength: dis.toFixed(2), //管线长度（m）
          line: newlocation.join(', '), //管线坐标
          startPointId: line.startPointId, //起点坐标id
          endPointId: line.endPointId
        }
        pipes.push(pipesItem)
      })
      let parames = {
        oldPointId: data.item.id, //被拖拽的节点
        newValueId: '', //被留下的阀门id
        oldValueId: '', //被删除的阀门id
        newPointId: '', //不用动的节点id
        newNodeType: '', //被留下的节点属性
        point: lnglat.lng + ' ' + lnglat.lat,
        pipes
      }
      return parames
    },

    selectDragButton(type, code) {
      let data = this.dragForm.data.item
      let queryPoint = this.dragForm.queryPoint
      let newValueId, oldValueId, newPointId, newNodeType, oldPointId
      if (type == 1) {
        //保留被拖拽
        if (data.node_type2 == serverMap[1002].dictCode) {
          newValueId = data.id
        }
        if (queryPoint.node_type2 == serverMap[1002].dictCode) {
          oldValueId = queryPoint.id
        }
        newPointId = queryPoint.id
        newNodeType = data.node_type
        oldPointId = data.id
      } else if (type == 2) {
        //保留被吸附点
        if (queryPoint.node_type2 == serverMap[1002].dictCode) {
          newValueId = queryPoint.id
        }
        if (data.node_type2 == serverMap[1002].dictCode) {
          oldValueId = data.id
        }
        newPointId = queryPoint.id
        newNodeType = queryPoint.node_type
        oldPointId = data.id
      } else {
        //自动转换为 三通、钢塑转换
        let famen = []
        if (data.node_type2 == serverMap[1002].dictCode) {
          famen.push(data.id)
        }
        if (queryPoint.node_type2 == serverMap[1002].dictCode) {
          famen.push(queryPoint.id)
        }
        if (famen.length > 0) {
          oldValueId = famen.toString()
        }
        newNodeType = code
        newPointId = queryPoint.id
        oldPointId = data.id
      }
      let parames = {
        ...this.setForm(this.dragForm.lnglat, this.dragForm.lines, this.dragForm.data),
        newValueId, //被留下的阀门id
        oldValueId, //被删除的阀门id
        newPointId, //不用动的节点id
        newNodeType, //被留下的节点属性
        oldPointId //被拖拽的点
      }

      this.saveDragSubmit(parames)
    },
    saveDragSubmit(parames, msg) {
      let that = this
      if (parames.pipes.length > 0) {
        if (this.lineLinkmonitor.have) {
          let monitors = this.lineLinkmonitor.monitor
          let newMonitor = []
          console.log(monitors)
          monitors.forEach(monitor => {
            parames.pipes.forEach(line => {
              if (line.id == monitor.lineId) {
                let path1 = line.line
                let path2 = path1.split(', ')
                let path = []
                path2.forEach(p => {
                  let n = p.split(' ')
                  path.push(n)
                })
                let pointObj = AMap.GeometryUtil.closestOnLine(monitor.monitorLocation, path)
                let item = {
                  id: monitor.monitorId,
                  point: pointObj[0] + ' ' + pointObj[1]
                }
                newMonitor.push(item)
              }
            })
          })
          parames.monitorPoints = newMonitor
        } else {
          this.lineLinkmonitor.num = this.lineLinkmonitor.num + 1
          if (this.lineLinkmonitor.num > 10) {
            this.lineLinkmonitor.have = true
          }
          setTimeout(() => {
            this.saveDragSubmit(parames, msg)
          }, 300)

          return
        }
      }

      pipeDrag(parames)
        .then(res => {
          that.$message.success(res.msg + (msg || ''))
          that.$eventBus.$emit('setFilter')

          that.editorLineGroups.clearOverlays()
          if (that.lineVector && that.lineVector.length > 0) {
            that.$parent.map.remove(that.lineVector)
            that.lineVector = []
          }

          that.closeWindow()
        })
        .catch(err => {
         // that.$message.error(err)
        })
    },
    async monitoPointFeature(lnglat, lines, data) {
      let res = await queryFeatrue(parames)
    },
    async getRangeFeature(lnglat, lines, data) {
      let that = this
      let line = filterLayer(this.$store)
      let lineP = {}
      if (line) {
        lineP = { CQL_FILTER: line }
      }
      let points = centerPointGetFourPoint(this.$parent.map, lnglat, 100)
      let a = points.leftBottomPoint[0]
      let b = points.leftBottomPoint[1]
      let c = points.upRightPoint[0]
      let d = points.upRightPoint[1]
      let STYLES = ''
      let parames = {
        SERVICE: 'WMS',
        VERSION: '1.1.0',
        REQUEST: 'GetFeatureInfo',
        FORMAT: 'image/png',
        TRANSPARENT: true,
        QUERY_LAYERS: that.$parent.layerName || commonLayer['pressure'],
        LAYERS: that.$parent.layerName || commonLayer['pressure'],
        exceptions: 'application,Fvnd.ogc.se_inimage',
        INFO_FORMAT: 'application/json',
        FEATURE_COUNT: 50,
        X: 50,
        y: 50,
        SRS: 'EPSG:4326',
        WIDTH: 101,
        HEIGHT: 101,
        STYLES,
        BBOX: a + ',' + b + ',' + c + ',' + d
        //   ...lineP
      }
      let res = await queryFeatrue(parames)

      let features = res.features

      if (data.item.node_type2 == constantDict.jiancedian.dictCode) {
        let netLine = []
        features.forEach(item => {
          if (item.geometry.type == 'LineString') {
            netLine.push(item.properties)
          }
        })
        let lineInfo = {}
        if (netLine.length > 0) {
          lineInfo = netLine[0]
        } else {
          this.$message.error('监测点必须在线上！')
          return
        }
        let path = formatLocation(lineInfo.location_str, 'LineString')
        let obj = AMap.GeometryUtil.closestOnLine(lnglat, path)
        //监测点
        let xlbj = {
          id: data.item.id,
          point: obj.join(' '), //吸附后的点
          pipeId: lineInfo.id
        }
        monitorPointDragPoint(xlbj)
          .then(res => {
            that.$message.success(res.msg)
            that.$eventBus.$emit('setFilter')
            that.clearVector()
          })
          .catch(err => {
            //that.$message.error(err)
          })
      } else if (features.length > 0) {
        let line = []
        let point = []
        features.forEach(item => {
          if (item.geometry.type == 'LineString') {
            line.push(item.properties)
          } else if (item.geometry.type == 'Point') {
            //供气站，安全设备(摄像头，泄露报警)不吸附 用户站，
            let node_type2 = item.properties.node_type2
            if (
              node_type2 == constantDict.shexiangtou.dictCode ||
              node_type2 == constantDict.xieloubaojing.dictCode ||
              node_type2 == constantDict.gongqizhan.dictCode ||
              node_type2 == constantDict.yonghuzhan.dictCode ||
              item.properties.channel_type
            ) {
            } else {
              //节点，阀门，其他管件，管线点  需要查询管线拖动的
              if (data.item.id != item.properties.id) {
                point.push(item.properties)
              }
            }
          }
        })
        let xfPoint = null
        console.log(data.item.pipes)
        if (point.length > 0) {
          let obj = formatLocation(point[0].location_str, 'Point')
          xfPoint = new AMap.LngLat(obj[0], obj[1]) //{ lng: , lat:  }
          lnglat = xfPoint
          pipeLink({ pointIds: point[0].id })
            .then(res => {
              if (res.data.length > 0) {
                let l = 0
                if (res.data[0].pipes) {
                  l = res.data[0].pipes.length
                }

                that.saveGetForm.line = l + data.item.pipes.length
                if (data.item.pipes.length == 1) {
                  that.saveGetForm.bore = data.item.pipes[0].diameter == res.data[0].pipes.diameter
                  that.saveGetForm.material =
                    data.item.pipes[0].pipeType == res.data[0].pipes.pipeType
                }
                if (that.saveGetForm.line < 4) {
                  let html = that.$refs.dragPoint
                  that.infoWindow = new AMap.InfoWindow({
                    position: obj,
                    offset: new AMap.Pixel(0, 0),
                    anchor: 'top-left',
                    content: html
                  })
                  that.dragForm = {
                    lnglat,
                    lines,
                    data,
                    queryPoint: point[0]
                  }
                  that.infoWindow.open(that.$parent.map)
                } else {
                  //  that.$message.warning('不支持同一点连接3条以上管线')
                  let msg = '，不支持同一点连接3条以上管线的吸附'
                  let parames = that.setForm(lnglat, lines, data)
                  that.saveDragSubmit(parames, msg)
                }
              }
            })
            .catch(err => {
             // that.$message.error(err)
            })
        } else if (line.length > 0) {
          if (data.item.pipes.length > 1) {
            // let closestPositionOnLine = AMap.GeometryUtil.closestOnLine(lnglat, path)
            let parames = that.setForm(lnglat, lines, data)
            that.saveDragSubmit(parames)
            return
          }
          let path = formatLocation(line[0].location_str, 'LineString')
          let obj = AMap.GeometryUtil.closestOnLine(lnglat, path)
          //  xfPoint = {lng:obj[0],lat:obj[1]}

          //管线打断  不包含多个折线的情况
          let item = line[0]
          item.location = path

          let queryparams = this.queryparams(item, obj)
          let startPoint = item.location[0]
          let endPoint = item.location[item.location.length - 1]
          queryFeatrue(queryparams).then(res => {
            let features = res.features
            let monitorPointIds = []
            if (features&&features.length > 0) {
              features.forEach(itemsub => {
                if (item.id == itemsub.properties.pipe_id) {
                  monitorPointIds.push(itemsub.properties.id)
                }
              })
            }
            let p = {
              pipeId: item.id,
              line1: startPoint.join(' ') + ', ' + obj.join(' '),
              line2: obj.join(' ') + ', ' + endPoint.join(' '),
              pipeLength1: AMap.GeometryUtil.distanceOfLine([startPoint, obj]).toFixed(2),
              pipeLength2: AMap.GeometryUtil.distanceOfLine([endPoint, obj]).toFixed(2),
              point: obj[0] + ',' + obj[1],
              monitorPointIds
            }
            lnglat = new AMap.LngLat(obj[0], obj[1])

            let parames = that.setForm(lnglat, lines, data)
            let index = lines.find(line => line.id == item.id)
            if (index == -1) {
              parames.dragLink = p
            }
            that.saveDragSubmit(parames)
          })
        } else {
          let parames = that.setForm(lnglat, lines, data)
          that.saveDragSubmit(parames)
        }
      } else {
        let parames = that.setForm(lnglat, lines, data) //没有查到点或线
        that.saveDragSubmit(parames)
      }
    },
    queryparams(item, obj) {
      let that = this
      let endPoint = item.location[item.location.length - 1]
      let amapEnd = new AMap.LngLat(endPoint[0], endPoint[1])
      let lineend = lineRangePoint(that.$parent.map, amapEnd)
      let linestart = lineRangePoint(that.$parent.map, new AMap.LngLat(obj[0], obj[1]))
      let sqlLine =
        linestart[0].join(',') +
        ' ' +
        linestart[1].join(',') +
        ' ' +
        lineend[1].join(',') +
        ' ' +
        lineend[0].join(',') +
        ' ' +
        linestart[0].join(',') //lineRangePoint(that.$parent.map,lnglat)+' '+lineRangePoint(that.$parent.map,point)
      let queryparams = {
        SERVICE: 'wfs',
        VERSION: '1.0.0',
        REQUEST: 'GetFeature',
        typeName: commonLayer.monitoPoint,
        outputFormat: 'application/json',
        filter: this.getFiles(sqlLine)
      }
      return queryparams
    },
    getLineMonitor(pointObj) {
      let lines = []
      let ids = []
      pointObj.forEach(item => {
        item.pipes.forEach(line => {
          ids.push(line.id)
          lines.push(line)
        })
      })
      if (lines.length < 1) {
        return
      }
      this.lineLinkmonitor = {
        monitor: [],
        have: false,
        num: 0
      }

      let parames = {
        SERVICE: 'wfs',
        VERSION: '1.0.0',
        REQUEST: 'GetFeature',
        typeName: commonLayer.monitoPoint,
        outputFormat: 'application/json',
        CQL_FILTER: `pipe_id in (${ids.toString()})`
      }
      regionList(parames)
        .then(res => {
          let features = res.features
          let monitor = []
          if (features.length > 0) {
            lines.forEach(line => {
              features.forEach(itemSub => {
                let point = itemSub.properties
                if (line.id == point.pipe_id) {
                  let item = {
                    monitorId: point.id,
                    lineId: line.id,
                    monitorLocation: formatLocation(point.location_str, 'Point')
                  }
                  monitor.push(item)
                }
              })
            })
          }
          this.lineLinkmonitor = {
            monitor: monitor,
            have: true,
            num: 0
          }
        })
        .catch(err => {
          this.lineLinkmonitor = {
            monitor: [],
            have: true,
            num: 0
          }
        })
    },
    openLineSelect(ids, point, pointStr) {
      let id = ids.toString()
      let that = this
      pipeLink({ pointIds: id }).then(res => {
        this.aloneLine = res.data
        let html = that.$refs.selectLineStation
        that.infoWindow = new AMap.InfoWindow({
          position: point,
          offset: new AMap.Pixel(0, 0),
          anchor: 'top-left',
          content: html
        })
        that.infoWindow.open(that.$parent.map)
        that.pointStr = pointStr
      })
    },
    saveSelectLine(item) {
      this.infoWindow.close()
      console.log(this.pointStr)
      console.log(item)
      this.$parent.$refs.stationInfo.selectPoit(item, this.pointStr)
    }
  }
}
</script>
<style lang="scss" scoped>
.bubbleBox {
  position: absolute;
  z-index: -1;
}
.Bubble {
  div {
    font-size: 14px;
  }
  position: relative;
  .lj {
    display: block;
    position: absolute;
    top: 0.5rem;
    left: -21px;
    transform: rotate(-90deg);
    z-index: 1;
    .tip {
      width: 0;
      height: 0;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 12px solid #222;
      position: relative;
    }
    .tip:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      bottom: -10px;
      left: -12px;
      z-index: -1;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 12px solid rgba(0, 204, 255, 0.5);
    }
    // border-bottom: 12px solid rgba(8, 31, 48, 1);
  }
}
.markbox {
  background: var(--background-color-skin);
  border: 1px solid #6eb7e3;

  min-width: 290px;
  min-height: 115px;

  // background-size: 100% 100%;
  font-size: 18px;
  // margin-top: -0.6rem;
  margin-left: 0.1rem;
  overflow: visible;
  position: relative;
}
.markTitle {
  height: 42px;
  display: flex;
  // line-height: 1;
  justify-content: space-between;
  align-items: center;
  //background-image: linear-gradient(to right, #0a608c, #081f30);
  background-color: var(--background-title-skin);
  opacity: 1;
  p {
    display: flex;
    line-height: 1;
    justify-content: flex-start;
    align-items: center;
    margin-left: 10px;
    img {
      margin-right: 10px;
      height: 24px;
      width: auto;
    }
  }
  i {
    font-size: 18px;
    color: #679db4;
    cursor: pointer;
    margin-right: 10px;
  }
  i:hover {
    color: #548aa1;
  }
}
.mark-con {
  margin: 5px 10px;
  line-height: 2;
  .li:hover,
  .li.active {
    color: var(--font-color-b1);
    cursor: pointer;
  }
}
.btn {
  text-align: right;
  margin-top: 10px;
  margin-bottom: 10px;
}
/* 确认框按钮 */

::v-deep .cancelBtnClass {
  background: #1b5776 !important;
  border-color: #1b5776 !important;
}
::v-deep .cancelBtnClass:hover {
  background: #154d69 !important;
  border-color: #154d69 !important;
}
::v-deep .confirmBtnClass {
  background: #0d6db1 !important;
  border-color: #0d6db1 !important;
}
::v-deep .confirmBtnClass:hover {
  background: #3390d1 !important;
  border-color: #3390d1 !important;
}
</style>
