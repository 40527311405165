<template>
  <div>
    <el-dialog
      :title="addOrEdit == 'add' || addOrEdit == 'nodeToValve' ? '新增阀门' : '编辑阀门'"
      :visible.sync="dialogVisible"
      :inline="true"
      :modal="false"
      :close-on-click-modal="false"
      width="630px"
      custom-class="dialog-class"
      @close="close"
    >
      <el-form ref="form" :model="form" :rules="rule" label-width="100px"  size="mini">
        <el-form-item label="所属图层:">
          <el-input
            v-model="form.layerName"
            placeholder="请输入所属图层"
            disabled
            maxlength="40"
          ></el-input>
        </el-form-item>
        <el-form-item label="设备编号:" v-if="form.id">
          <el-input
            v-model="form.valveCode"
            placeholder="请输入设备编号"
            disabled
            maxlength="40"
          ></el-input>
        </el-form-item>
        <el-form-item label="经纬度:">
          <el-input v-model="form.lnglat" disabled placeholder="请输入经纬度"></el-input>
        </el-form-item>
        <el-form-item label="设备名称:" prop="valveName">
          <el-input v-model="form.valveName" placeholder="请输入设备名称"></el-input>
        </el-form-item>

        <div class="flex-box">
          <el-form-item label="阀门种类:" >
            <el-select
              v-model="form.valveKind"
              popper-class="gis-default"
              placeholder="请选择阀门种类"
            >
              <el-option
                v-for="item in valueKindDict"
                :key="item.dictCode"
                :label="item.name"
                :value="item.dictCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="敷设方式:">
            <el-select
              v-model="form.layType"
              popper-class="gis-default"
              clearable
              placeholder="请选择敷设方式"
            >
              <el-option
                v-for="item in layTypeDict"
                :key="item.dictCode"
                :label="item.name"
                :value="item.dictCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="flex-box">
        <el-form-item label="阀门设施:" prop="valveFacilities">
            <el-select
              v-model="form.valveFacilities"
              popper-class="gis-default"
              clearable
              placeholder="请选择阀门设施"
            >
              <el-option
                v-for="item in valveFacilitiesDict"
                :key="item.dictCode"
                :label="item.name"
                :value="item.dictCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
            <el-form-item label="埋深(m):" prop="depth">
            <el-input v-model="form.depth" placeholder="请输入埋深"></el-input>
          </el-form-item>
        </div>
        <div class="flex-box">
           <el-form-item label="阀井深度:" prop="valveWellDepth" v-if="form.valveFacilities =='2'">
            <el-input v-model="form.valveWellDepth" placeholder="请输入阀井深度"></el-input>
          </el-form-item>
        <el-form-item label="阀井类型:" v-if="form.valveFacilities =='2'">
            <el-select
              v-model="form.valveType"
              popper-class="gis-default"
              clearable
              placeholder="请选择阀井类型"
            >
              <el-option
                v-for="item in valveTypeDict"
                :key="item.dictCode"
                :label="item.name"
                :value="item.dictCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
      
        </div>
        <div class="flex-box">
         
          <el-form-item label="规格:">
            <el-input v-model="form.spec" maxlength="40" placeholder="请输入规格"></el-input>
            
          </el-form-item>
       
        
          <el-form-item label="型号:">
            <el-input v-model="form.model" maxlength="40" placeholder="请输入型号"></el-input>
          </el-form-item>
           </div>
            <div class="flex-box">
           <el-form-item label="设备状态:" prop="generalStatus">
            <el-select
              v-model="form.generalStatus"
              popper-class="gis-default"
              placeholder="请选择设备状态"
            >
              <el-option
                v-for="item in optionsStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
       
          <el-form-item label="连接方式:">
            <el-select
              v-model="form.connectType"
              popper-class="gis-default"
              clearable
              placeholder="请选择连接方式"
            >
              <el-option
                v-for="item in connectTypeDict"
                :key="item.dictCode"
                :label="item.name"
                :value="item.dictCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
         
        </div>
        <div class="flex-box">
          <el-form-item label="安装时间:" prop="installDate">
            <el-date-picker
              v-model="form.installDate"
              type="date"
              :clearable="false"
              :editable="false"
              popper-class="datePickerClass"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="附着压力级别:" prop="pressureLevel">
            <el-select
              v-model="form.pressureLevel"
              popper-class="gis-default"
              placeholder="请选择压力级别"
            >
              <el-option
                v-for="item in pressureLevelDict"
                :key="item.dictCode"
                :label="item.name"
                :value="item.dictCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div >
          <el-form-item label="所在街道:">
            <el-input v-model="form.road" placeholder="请输入备注"></el-input>
          </el-form-item>
          
        </div>
        <div>
          <el-form-item label="生产厂家:">
            <el-input v-model="form.factory" maxlength="40" placeholder="请输入备注"></el-input>
          </el-form-item></div>
        <div >
          <el-form-item label="备注:">
            <el-input v-model="form.remark" maxlength="60" placeholder="请输入备注"></el-input>
          </el-form-item></div>
          <div >
          <el-form-item label="所在地址:" prop="">
            <el-input v-model="form.address" maxlength="60"></el-input>
          </el-form-item></div>
        
          


        
      </el-form>
      <div class="btn">
        <el-button size="mini" @click="close">取消</el-button>
        <el-button
          v-if="addOrEdit === 'add' || addOrEdit === 'nodeToValve'"
          type="primary"
          size="mini"
          @click="submit"
        >
          保存
        </el-button>
        <el-button v-else type="primary" size="mini" @click="updateData">保存</el-button>
        
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { saveValve, valueUpdate, pipeLink } from '../apis/tool'
import { serverMap, commonDict, commonLayer } from '../utils/dict.js'
import { formatLocation, lineRangePoint } from '../utils/geoTool.js'
import { queryFeatrue } from '../apis/geo'
import {
  findDictTree,
  findManufacturerOptions,
  findTypeOptions,
  findSpecOptions
} from '../apis/commonType'
import { formatTime } from '../utils/common.js'
import { getCompanyName, getCompanyId } from '../utils/auth'
export default {
  data() {
    return {
      eqType: 21,
      addOrEdit: 'add',
      form: {
        installDate: formatTime('yyyy-MM-dd', new Date()),
        pressureLevel:4,
        generalStatus:1
      },
      dialogVisible: false,
      valueKindDict: [],
      layTypeDict: [],
      controlTypeDict: [],
      connectTypeDict: [],
      switchTypeDict: [],
      pressureLevelDict: [],
      factoryDict: [],
      modelDict: [],
      specDict: [],
      valveFacilitiesDict:serverMap[1014],
      valveTypeDict:[],
      optionsAideo: [
        {
          value: 0,
          label: '否'
        },
        {
          value: 1,
          label: '是'
        }
      ],
      optionsStatus: [
        {
          value: 1,
          label: '启用'
        },
        {
          value: 0,
          label: '停用'
        }
      ],
      rule: {
        valveName: [
          { required: true, message: '请输入设备名称', trigger: 'change' },
          { max: 100, message: '超出最大限制', trigger: ['change', 'blur'] }
        ],
        useAccord: [{ max: 100, message: '超出最大限制', trigger: ['change', 'blur'] }],
        pressureLevel: [{ required: true, message: '请选择压力级别', trigger: 'change' }],
        generalStatus: [{ required: true, message: '请选择设备状态', trigger: 'change' }],
      
        controlType: [{ required: true, message: '请选择控制器类型', trigger: 'change' }],
        transmit: [{ required: true, message: '请选择是否远传', trigger: 'change' }],
        online: [{ required: true, message: '请选择是否在线', trigger: 'change' }],
        valveFacilities:[{ required: true, message: '请选择阀门设施', trigger: 'change' }],
        valveWellDepth:[{
            pattern: /(^[1-9]\d{0,5}?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: '请输入正确格式,可保留2位小数'
          }],
        depth: [
          {
            pattern: /(^[1-9]\d{0,5}?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: '请输入正确格式,可保留2位小数'
          }
        ],
        address: [{ required: true, message: '请输入所在地址', trigger: 'blur' }],

      }
    }
  },
  mounted() {
    this.form.companyName = getCompanyName()
    this.form.companyId = getCompanyId()
  },
  methods: {
    // 获取型号
    getFindTypeOptions(manufacturerCode) {
      findTypeOptions({ eqType: this.eqType, manufacturerCode }).then(res => {
        if (res.data) {
          this.modelDict = res.data
        }
      })
    },
    // 获取规格
    getFindSpecOptions(manufacturerCode) {
      findSpecOptions({ eqType: this.eqType, manufacturerCode }).then(res => {
        if (res.data) {
          this.specDict = res.data
        }
      })
    },
    factoryCodeChge(value) {
      this.$set(this.form, 'model', null)
      this.$set(this.form, 'spec', null)
      if (value) {
        this.getFindTypeOptions(value)
        this.getFindSpecOptions(value)
      }
    },
    // 获取字典
    getCodeName(type) {
      let code =
        commonDict[100003].code +
        ',' +
        commonDict[100004].code +
        ',' +
        commonDict[100005].code +
        ',' +
        commonDict[100006].code +
        ',' +
        commonDict[100007].code+
        ',' +
        commonDict[300001].code+
        ',' +
        commonDict[300002].code
      findDictTree({ dictCode: code }).then(res => {
        if (res.data) {
          this.valueKindDict = res.data[commonDict[100003].code]
          this.layTypeDict = res.data[commonDict[100004].code]
          this.controlTypeDict = res.data[commonDict[100005].code]
          this.connectTypeDict = res.data[commonDict[100006].code]
          this.switchTypeDict = res.data[commonDict[100007].code]
         
          this.valveTypeDict = res.data[commonDict[300002].code]
        }
        this.pressureLevelDict = serverMap['1006']
        if (type == 'add') {
          this.form = {
            valveFacilities:this.valveFacilitiesDict[0].dictCode,
            controlType: this.controlTypeDict[0].dictCode,
           
            transmit: this.optionsAideo[0].value,
            ...this.form
          }
        }
      })
      // findManufacturerOptions({ eqType: this.eqType, companyId: getCompanyId() }).then(res => {
      //   if (res.data) {
      //     this.factoryDict = res.data
      //   }
      // })
    },
    openDialog(data) {
      this.form.companyName = getCompanyName()
      this.form.companyId = getCompanyId()
      
      if (data.addOrEdit == 'edit') {
        // 编辑
        this.addOrEdit = data.addOrEdit
        this.form = {
          ...data
        }
        this.form.lnglat = data.point
        if (this.form.factory) {
          this.getFindTypeOptions(this.form.factory)
          this.getFindSpecOptions(this.form.factory)
        }
        this.getCodeName()
      } else if (data.addOrEdit == 'nodeToValve') {
        // 节点转阀门
        this.addOrEdit = data.addOrEdit
        this.form.pointId = data.id
        this.form.lnglat = data.point
        this.form.layerName = data.layerName

        this.getCodeName('add')

        let that = this;
       var geocoder = new AMap.Geocoder();
       let lnglat1 = this.form.lnglat.split(' ')
          geocoder.getAddress(lnglat1, function (status, result) {
            if (result.info === "OK") {
              that.$set(that.form,'address',result.regeocode.formattedAddress);
             // that.form.address = result.regeocode.formattedAddress;
            }
          });
      } else {
         this.form.layerName = this.$store.getters.currentLayerName
         this.form.layerId = this.$store.getters.currentLayer
        this.form.lnglat = data.point
        let that = this
        if (data.type === 'node') {
          // 点在了节点上
          this.form.pointId = data.id

          pipeLink({ pointIds: data.id }).then(res => {
            if (res.data.length > 0) {
           //   this.form.valveFacilities = this.valveFacilitiesDict[0].dictCode
            }
            this.getCodeName('add')
          })
        } else {
          
          let startPoint = data.pipelineLnglat[0]
          let endPoint = data.pipelineLnglat[data.pipelineLnglat.length - 1]
          let amapEnd = new AMap.LngLat(endPoint[0], endPoint[1])
          let lineend = lineRangePoint(that.$parent.$parent.map, amapEnd)
          var lnglat = new AMap.LngLat(data.point.split(' ')[0], data.point.split(' ')[1])
          let linestart = lineRangePoint(that.$parent.$parent.map, lnglat)
          let sqlLine =
            linestart[0].join(',') +
            ' ' +
            linestart[1].join(',') +
            ' ' +
            lineend[1].join(',') +
            ' ' +
            lineend[0].join(',') +
            ' ' +
            linestart[0].join(',')
          this.params = {
            SERVICE: 'wfs',
            VERSION: '1.0.0',
            REQUEST: 'GetFeature',
            typeName: commonLayer.monitoPoint,
            outputFormat: 'application/json',
            CQL_FILTER:'pipe_id = '+ data.id 
          //  filter: this.getFiles(sqlLine)
          }
          queryFeatrue(this.params).then(res => {
            let features = res.features
            let monitorPointIds = []
            if (features.length > 0) {
              features.forEach(itemsub => {
                 let p1=formatLocation(itemsub.properties.location_str, 'Point')
                if (AMap.GeometryUtil.isPointOnSegment(p1, lnglat, amapEnd, 0.01)) {
                  monitorPointIds.push(itemsub.properties.id)
                }
                // if (data.id == itemsub.properties.pipe_id) {
                //   monitorPointIds.push(itemsub.properties.id)
                // }
              })
            }
            let midPoint = data.point.split(' ')
            let pipeLength1 = AMap.GeometryUtil.distance(startPoint, midPoint)
            let pipeLength2 = AMap.GeometryUtil.distance(midPoint, endPoint)
            let obj = {
              pipeId: data.id,
              layerId: this.$store.getters.currentLayer,
              layerName: this.$store.getters.currentLayerName,
              line1: startPoint.join(' ') + ', ' + data.point,
              line2: data.point + ', ' + endPoint.join(' '),
              pipeLength1: pipeLength1.toFixed(2),
              pipeLength2: pipeLength2.toFixed(2),
              point: data.point,
              elevationContent: 0,
              monitorPointIds
            }
            // 点在了管线上
            this.form.point = obj
            this.getCodeName('add')
          })
          
        }
        var geocoder = new AMap.Geocoder();
          let lnglat1 = this.form.lnglat.split(' ')
              geocoder.getAddress(lnglat1, function (status, result) {
                if (result.info === "OK") {
                  that.$set(that.form,'address',result.regeocode.formattedAddress);
                }
              });
      }
      
      this.dialogVisible = true
    },
    close() {
      this.dialogVisible = false
      this.$parent.$parent.editTool.close(true)
      this.$parent.$parent.pointHighlightRemove()
      this.form = {}
      this.resetForm()
      if (this.addOrEdit == 'add') {
        this.$parent.startDraw('valve')
        this.form = {
          valveFacilities:this.valveFacilitiesDict[0].dictCode,
          controlType: this.controlTypeDict[0].dictCode,
          transmit: this.optionsAideo[0].value
        }
      }
    },
    updateData() {
      let that = this
      this.$refs.form.validate(valid => {
        if (valid) {
          delete that.form.point
          valueUpdate(that.form)
            .then(res => {
              if (res.code == 200) {
                that.$message.success(res.msg)
                that.$eventBus.$emit('setFilter')
                that.close()
              } else {
                that.$message.error(res.msg)
              }
            })
            .catch(err => {
              that.$message.error(err)
            })
        }
      })
    },
    submit() {
      let that = this
      that.form = {
        ...that.form,
        layerId: this.$store.getters.currentLayer,
        nodeType: serverMap[1002][0].dictCode
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          saveValve(this.form)
            .then(res => {
              if (res.code == 200) {
                that.$message.success(res.msg)
                that.$eventBus.$emit('setFilter')
                that.close()
              } else {
                that.$message.error(res.msg)
              }
            })
        
        }
      })
    },
    resetForm() {
      this.$refs.form.resetFields()
    },
    getFiles(pos) {
      //线上的点
      let file =
        '<Filter xmlns="http://www.opengis.net/ogc" xmlns:gml="http://www.opengis.net/gml">' +
        '<Intersects><PropertyName>location</PropertyName><gml:Polygon>' +
        '<gml:outerBoundaryIs>' +
        '<gml:LinearRing>' +
        '<gml:coordinates>' +
        pos +
        '</gml:coordinates>' +
        '</gml:LinearRing>' +
        '</gml:outerBoundaryIs>' +
        '</gml:Polygon></Intersects></Filter>'
      return file
    }
  }
}
</script>
<style lang="scss" scoped>
.btn {
  text-align: right;

  button {
    margin: 7px 15px 0 15px;
  }
}

.flex-box {
  display: flex;
  justify-content: space-between;

  .el-input {
    width: 180px;
  }

  .el-select {
    width: 180px;
  }
}
</style>
