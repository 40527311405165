<template>
  <div>
    <div v-show="dialogVisible" id="dragBox" ref="dragBox" class="layerBox" @mousedown="move">
      <div>
        <div style="width:500px;display:inline-block">
          <el-tabs ref="tabs" v-model="activeName">
            <el-tab-pane label="场站信息" name="one"></el-tab-pane>
          </el-tabs>
        </div>
        <div class="caozu">
          <span class="colseBut" @click="colse"><i class="el-icon-circle-close"></i></span>
        </div>
        <div v-if="activeName == 'one'">
          <div class="Feature">
            <el-form
              ref="form"
              :model="form"
              :rules="rules"
              label-position="right"
              label-width="100px"
            >
              <el-form-item label="所属企业名称:">
                <el-input
                  v-model="form.companyName"
                  class="selectWidth"
                  disabled
                  placeholder="请输入所属企业名称"
                ></el-input>
              </el-form-item>
              <el-form-item label="企业场站编号:">
                <el-input
                  v-model="form.stationCode"
                  class="selectWidth"
                  placeholder="请输入企业场站编号"
                ></el-input>
              </el-form-item>
              <el-form-item label="名称:" prop="stationName">
                <el-input
                  v-model="form.stationName"
                  class="selectWidth"
                  placeholder="请输入名称"
                ></el-input>
              </el-form-item>
              <el-form-item label="场站分类:" prop="stationKind">
                <el-select
                  v-model="form.stationKind"
                  class="selectWidth"
                  popper-class="gis-default"
                  placeholder="请选择场站分类"
                >
                  <el-option
                    v-for="item in stationKindList"
                    :key="item.dictCode"
                    :label="item.name"
                    :value="item.dictCode"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="经纬度:">
                <el-input
                  v-model="form.point"
                  class="selectWidth"
                  disabled
                  placeholder="请输入经纬度"
                ></el-input>
              </el-form-item>
              <el-form-item label="备注:">
                <el-input
                  v-model="form.remark"
                  class="selectWidth"
                  type="textarea"
                  :rows="2"
                  placeholder="请输入备注"
                ></el-input>
              </el-form-item>
            </el-form>
            <div class="formbtn">
              <el-button size="mini" type="primary" @click="submit">保存</el-button>
              <el-button size="mini" @click="colse">取消</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getCompanyName, getCompanyId } from '../utils/auth'
import { findDictTree } from '../apis/commonType'
import { saveStation } from '../apis/tool'
import { serverMap } from '../utils/dict.js'
export default {
  data() {
    return {
      dialogVisible: false,
      activeName: 'one',
      edit: true, //场站详情编辑
      form: {},
      stationKindList: [],
      channelTypeList: serverMap['1012'],
      rules: {
        stationName: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        stationKind: [{ required: true, message: '请选择场站分类', trigger: 'change' }]
      }
    }
  },
  mounted() {},
  methods: {
    getCodeName(type) {
      let code = type == 2 ? serverMap[1004][1].dictCode : serverMap[1004][0].dictCode
      findDictTree({ dictCode: code }).then(res => {
        if (res.data) {
          this.stationKindList = res.data[code]
        }
      })
    },
    openDialog(obj) {
      this.form = obj
      this.form.layerId = this.$store.getters.currentLayer
      this.form.companyName = getCompanyName()
      this.form.deptId = getCompanyId()
      if (obj.equipmentType == 'userSta') {
        this.getCodeName(2)
        this.form.stationType = 2
      } else {
        this.getCodeName(1)
        this.form.stationType = 1
      }
      this.dialogVisible = true
    },
    setstationKindName(value) {
      this.stationKindList.forEach(item => {
        if (item.dictCode == value) {
          this.form.stationKindName = item.name
        }
      })
    },
    // 新增、更新场站信息
    submit() {
      let that = this
      that.$refs.form.validate(valid => {
        if (valid) {
          that.setstationKindName(that.form.stationKind)
          saveStation(that.form)
            .then(res => {
              if (res.code == 200) {
                that.$message.success(res.msg)
                that.$eventBus.$emit('setFilter')
                that.colse()
              } else {
                that.$message.error(res.msg)
              }
            })
            .catch(err => {
              that.$message.error(err)
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    colse() {
      this.dialogVisible = false
      this.$parent.clearTopTool()
    },
    move(e) {
      let odiv = this.$refs.dragBox
      let disX = e.clientX - odiv.offsetLeft
      let disY = e.clientY - odiv.offsetTop
      document.onmousemove = e => {
        let left = e.clientX - disX
        let top = e.clientY - disY
        this.positionX = top
        this.positionY = left

        odiv.style.left = left + 'px'
        odiv.style.top = top + 'px'
      }
      document.onmouseup = e => {
        document.onmousemove = null
        document.onmouseup = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-tabs__item.is-active {
  color: var(--font-color-f1);
}
::v-deep .el-tabs__item {
  color: var(--font-color-c1);
}
::v-deep .el-tabs__active-bar {
  background-color: #6eb7e3;
}
::v-deep .el-tabs__nav-wrap::after {
  content: none;
}
::v-deep .el-tabs__header {
  margin: 0px;
}
::v-deep .el-button--text {
  background: transparent !important;
  border: none;
}
.caozu {
  display: inline-flex;
  height: 40px;
  font-size: 14px;
  text-align: right;
  vertical-align: text-bottom;
  line-height: 36px;
  color: #a5b4c4;
  float: right;
}
.colseBut {
  font-size: 26px;
  vertical-align: middle;
  cursor: pointer;
}
.layerBox {
  position: absolute;
  top: 2.2rem;
  left: 5rem;
  min-height: 3rem;
  min-width: 3rem;
  border: 1px solid var(--border-color-skin);
  background: var(--background-color-skin);
  z-index: 2000;
  padding: 0px 20px 10px 20px;
}
.Feature {
  margin: 5px 5px 10px 0;
  line-height: 0.28rem;
  text-align: center;
  width: 100%;
  color: var(--font-color-f1);
}
.equipment-item {
  text-align: left;
  width: 380px;
  word-break: break-word;
}
.btnDiv {
  margin-bottom: 15px;
  .btn {
    margin-right: 15px;
  }
}
.formbtn {
  button {
    margin: 7px 15px 0 15px;
  }
}
.btnText {
  color: var(--font-color-f1);
  padding: 4px 5px;
}
/* table按钮颜色 */
.btnText.danger {
  color: #f74b4b;
}
.btnText.danger:hover,
.btnText.danger:focus {
  color: #f78989;
}
.selecttag {
  margin-left: 5px;
}
.selectWidth {
  width: 380px;
}
</style>
