<template>
  <div>
    <el-dialog
      :title="type=='流量计'?form.flowName:form.pressureName"
      :visible.sync="dialogVisible"
      :inline="true"
      :modal="false"
      width="333px"
      custom-class="dialog-class"
      :close-on-click-modal="false"
      @close="close"
    >
      <el-form ref="form" :model="form" label-width="75px" class="Feature">
        <el-form-item label="设备编号 :" prop="code">
          <span class="spanDesc">{{ form.flowCode?form.flowCode:form.pressureCode }}</span>
        </el-form-item>
        <el-form-item label="设备类型 :" prop="type">
          <span class="spanDesc">{{type}} </span>
        </el-form-item>
        <el-form-item label="规格:" prop="type">
            <label slot="label">规&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;格 :</label>
              <span class="spanDesc">{{ form.spec }}</span>
        </el-form-item>
        <el-form-item label="型号:" prop="type">
          <label slot="label">型&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;号 :</label>
              <span class="spanDesc">{{ form.model }}</span>
        </el-form-item>
        <el-form-item label="设备状态 :" prop="type">
              <span class="spanDesc">{{ form.generalStatusName }}</span>
        </el-form-item>

        <el-form-item label="安装日期 :" prop="code">
          <span class="spanDesc">{{ form.installDate }}</span>
        </el-form-item>
        <el-form-item label="是否远传 :" prop="name">
          <span class="spanDesc">{{ form.transmitName }}</span>
        </el-form-item>
        <el-form-item label="是否在线 :" prop="type">
              <span class="spanDesc">{{ form.onlineName }}</span>
        </el-form-item>
        <el-form-item label="附着压力级别 :" prop="type" label-width="100px">
              <span class="spanDesc">{{ form.pressureLevelName }}</span>
        </el-form-item>
        <el-form-item label="厂家:" prop="type">
            <label slot="label">厂&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;家 :</label>
              <span class="spanDesc">{{ form.factory }}</span>
        </el-form-item>
        <el-form-item label="备注:" prop="type">
            <label slot="label">备&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;注 :</label>
              <span class="spanDesc">{{ form.remark }}</span>
        </el-form-item>
        
       
      </el-form>
      
    </el-dialog>
  </div>
</template>
<script>
import { flowFindById,pressureFindById } from '../apis/tool'
export default {
  props: {
    // id: {
    //   type: String,
    //   default: () => ''
    // },
    postion: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      form: {},
      dialogVisible: false,
      type:''
    }
  },
  mounted() {},
  methods: {
    close() {
      this.dialogVisible = false
      this.form = {}
    },
    openDesc(id) {
      let that = this
      this.dialogVisible = true;
      this.openD(id)
    },
    openD(id,type) {
        this.type=type
      this.dialogVisible = true
      //获取数据的接口等待对接
      if(type=='流量计'){
        flowFindById({id:id}).then(res=>{
            this.form = res.data
        })
      }else{
        pressureFindById({id:id}).then(res=>{
            this.form = res.data
        })
      }

      // stationFindByIds({ id:id }).then(res => {
      //   this.form = res.data
      // })
    }
  }
}
</script>
<style lang="scss" scoped>
.btn {
  text-align: right;
  button {
    margin: 7px 15px 0 15px;
  }
}
.spanDesc {
  text-align: left;
  width: 180px;
  color: var(--font-color-c2);
  margin-left: 5px;
}
::v-deep .el-form-item{
  margin-bottom:0px;
}
::v-deep .el-divider--horizontal{
  margin: 10px 0px;
}

</style>
