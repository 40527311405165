<template>
  <div>
    <div v-show="dialogVisible" id="dragBox" ref="dragBox" class="layerBox" >
        
        <div class="title" @mousedown="move">
            <div>
                <span class="title-content" >
                管网概况
                </span>
                <span class="caoz">
                    <i class="iconfont icon-shanchu2" style="float: right; padding-right: 10px;" @click="close()"></i>
                    <i class="iconfont icon-yidong" style="float: right; padding-right:13px"></i>
                </span>
                
            </div>
        </div>
            <div style="padding:0px 9px" class="contentD">
              <el-form class="blueFormLabelClass" label-position="right" v-if="form.pipeLineLengthVO">
                <el-row style="padding:0px 12px">
                    <el-col :span="24" class="allColor">
                    管线总长（m）：   {{form.pipeLineLengthVO.totalLength}}                 
                    </el-col>
                 </el-row>
                 <el-row style="padding:0px 12px">
                    <el-col :span="8" v-for="item,index in form.pipeLineLengthVO.pressureLevelList" :key="index">
                    {{item.key}}：{{item.value}}
                    </el-col>
                 </el-row> 
                 <el-row style="padding:0px 12px">
                    <el-col :span="8" v-for="item,index in form.pipeLineLengthVO.pipeTypeList" :key="index">
                    {{item.key}}：{{item.value}}
                    </el-col>
                 </el-row> 
                 <el-row style="padding:0px 12px">
                    <el-col :span="8" v-for="item,index in form.pipeLineLengthVO.pipeStatusList" :key="index">
                    {{item.key}}：{{item.value}}
                    </el-col>
                 </el-row>
                <el-row>
                    <el-divider></el-divider>
                </el-row>
                <el-row style="padding:0px 12px"   v-for="item,index in form.stationCountVOList" :key="index">
                <el-col :span="24" class="allColor">
                   {{item.stationTypeName}}：{{item.stationCount}}
                </el-col>
                <el-col :span="8" v-for="itemstation,indexstation in item.stationKindList" :key="indexstation+'_station'">
                    {{itemstation.key}}：{{itemstation.value}}
                </el-col>
                </el-row>
                 <el-row>
                    <el-divider></el-divider>
                </el-row>
                <el-row style="padding:0px 12px">
                <el-col :span="12" class="allColor">
                  管件设备：{{form.pipeFittingCountVO.pipeFittingCount}}
                </el-col>
                <el-col :span="12" class="allColor">
                   管线点：{{form.pipeFittingCountVO.pipePointCount}}
                </el-col>
                <el-col :span="8"  v-for="item,index in form.pipeFittingCountVO.pipeFittingList" :key="index">
                    {{item.key}}：{{item.value}}
                </el-col>
                </el-row>
                <el-row>
                    <el-divider></el-divider>
                </el-row>
               
                <el-row style="padding:0px 12px">
                <!-- <el-col :span="6" class="allColor">
                    重点设施：{{form.projectCountVO.facilityCont}}
                </el-col> -->
                <el-col :span="6" class="allColor">
                    隐蔽工程：{{form.projectCountVO.hiddenCont}}
                </el-col>
                <el-col :span="6" class="allColor">
                    三方施工：{{form.projectCountVO.patrolEventsCount}}
                </el-col>
                <el-col :span="6" class="allColor">
                    检测点：{{form.projectCountVO.monitorPointCount}}
                </el-col>
                </el-row>
              </el-form>
            </div>
          </div>
         
        </div>
    
</template>
<script>
import {
  getPipeOverview
} from '../../apis/gis'
export default {
  data() {
    return {
      dialogVisible: false,
      form:{pipeLineLengthVO:{},stationCountVOList:[],pipeFittingCountVO:{},projectCountVO:{}}
     
    }
  },
  mounted() {
    //this.openDialog(1)
  },
  methods: {
    openDialog(obj) {
      if(!obj){
        this.dialogVisible = true;
      }
      getPipeOverview({}).then(res=>{
        this.form = res.data
      })
    },
    close(){
        this.dialogVisible = false;
        this.$parent.lineStatShow = false
    },
    move(e) {
      let odiv = this.$refs.dragBox
      let disX = e.clientX - odiv.offsetLeft
      let disY = e.clientY - odiv.offsetTop
      document.onmousemove = e => {
        let left = e.clientX - disX
        let top = e.clientY - disY
        this.positionX = top
        this.positionY = left

        odiv.style.left = left + 'px'
        odiv.style.top = top + 'px'
      }
      document.onmouseup = e => {
        document.onmousemove = null
        document.onmouseup = null
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.blueFormLabelClass{line-height: 30px;padding-top:5px;}
.caoz{
    color:#285F88;
}
.allColor{
    color: var(--font-color-b2);
   
}
.el-divider--horizontal{margin: 10px 0px;}
.title{
    height: 40px;
    line-height: 40px;
    background: linear-gradient(90deg, var(--background-title-skin1), var(--background-title-skin2));
}
.title-content{color: var(--font-color-f1);font-weight: bold;padding: 13px 9px;font-size: 14px;}
::v-deep .el-tabs__item.is-active {
  color: var(--font-color-f1);
}
::v-deep .el-tabs__item {
  color: var(--font-color-c1);
}
::v-deep .el-tabs__active-bar {
  background-color: #6eb7e3;
}
::v-deep .el-tabs__nav-wrap::after {
  content: none;
}
::v-deep .el-tabs__header {
  margin: 0px;
}
::v-deep .el-button--text {
  background: transparent !important;
  border: none;
}
::v-deep .formItemSmall.el-form-item {
   margin-bottom: 0px;
}

.caozu {
  display: inline-flex;
  height: 40px;
  font-size: 14px;
  text-align: right;
  vertical-align: text-bottom;
  line-height: 36px;
  color: #a5b4c4;
  float: right;
}
.colseBut {
  font-size: 26px;
  vertical-align: middle;
  cursor: pointer;
}
.layerBox {
    width: 500px;
  position: absolute;
  top:50px;
  right:27px;
  min-height: 3rem;
  min-width: 3rem;
//   border: 1px solid var(--border-color-skin);
  box-shadow: 0px 1px 6px 0px rgba(4, 57, 138, 0.3);
  z-index: 2000;
  background: var(--background-color-skin);
     opacity: 0.9;
}
// .contentD{
//      background: var(--background-color-skin);
//      opacity: 0.9;
// }
.Feature {
 margin: 5px 5px 10px 0;
  line-height: 0.28rem;
  text-align: center;
  width: 100%;
  color: var(--font-color-f1);
}
.equipment-item {
  text-align: left;
  width: 380px;
  word-break: break-word;
}
.btnDiv {
  margin-bottom: 15px;
  .btn {
    margin-right: 15px;
  }
}
.formbtn {
  button {
    margin: 7px 15px 0 15px;
  }
}
.btnText {
  color: var(--font-color-f1);
  padding: 4px 5px;
}
/* table按钮颜色 */
.btnText.danger {
  color: #f74b4b;
}
.btnText.danger:hover,
.btnText.danger:focus {
  color: #f78989;
}
.selecttag {
  margin-left: 5px;
  cursor: pointer;
}
.selectWidth {
  width: 380px;
}
</style>
