<template>
<div  id="dragBox" ref="dragBox" class="layerBox" v-show="$parent.layerShow">
  <div id="layer">
    <div class="title"  @mousedown="move">
     
      <div style="position: relative">
        <span class="title-content" >
          图层管理
        </span>
        <!-- <i class="el-icon-plus" style="float: right; padding: 3px 0" @click="addLayerGroup"></i> -->
        <i class="iconfont icon-shanchu2" style="float: right; " @click="closeLayer()"></i>
        <i class="iconfont icon-yidong" style="float: right; padding-right:13px"></i>
        
        
      </div>
    </div>
    <div  @contextmenu.prevent="rightClick" style="padding-right:10px">
      <el-collapse-transition>
        <div  class="treeClass">
          <el-tree
            ref="tree"
            :data="treeData"
            show-checkbox
            node-key="id"
            default-expand-all
            :highlight-current="true"
            icon-class="el-icon-arrow-right"
            draggable
            :allow-drop="allowDrop"
            :allow-drag="allowDrag"
            :default-checked-keys="checkedKeys"
            @node-drag-start="handleDragStart"
            @node-drag-enter="handleDragEnter"
            @node-drag-leave="handleDragLeave"
            @node-drag-over="handleDragOver"
            @node-drag-end="handleDragEnd"
            @node-drop="handleDrop"
            @node-contextmenu="handelNodeContextmenu"
          >
            <span
              slot-scope="{ node, data }"
              class="slot-t-node"
              style="position: relative; width: 100%"
            >
              <div @click="treeClick(data.layerType != 1&&data.isDelete!=0  ? data.id : null,data.name)">
                <span>
                  <div v-if="data.id == activeLayer" class="activeLayer"></div>
                  <img
                    v-if="data.isSee == 2"
                    src="../image/tree-eye.png"
                    class="tree-file-class"
                    @click.stop="selectLayer(node, data)"
                  />
                  <span
                    v-else-if="data.isSee == 1"
                    class="across"
                    @click.stop="selectLayer(node, data)"
                  >
                    <div class="icon">-</div>
                  </span>
                  <img
                    v-else
                    src="../image/tree-eye-close.png"
                    class="tree-file-class"
                    @click.stop="selectLayer(node, data)"
                  />
                </span>

                <!-- 图层组、图层的图标 -->
                <img
                  v-if="data.layerType == 1"
                  src="../image/tree-folder.png"
                  class="tree-file-class"
                />
                <img v-else src="../image/tree-file.png" class="tree-file-class" />
                <span style="font-size:13px" :title="data.name">{{ data.name }}</span>
              </div>
            </span>
          </el-tree>
        </div>
      </el-collapse-transition>
    </div>
    <el-dialog
      title="右键图层管理弹出窗口"
      :visible.sync="contextmenuShow"
      :modal="false"
      :show-close="false"
      custom-class="dialog-no-title"
      @contextmenu.prevent.native="contextMenuClose()"
    >
      <div
        class="node-context"
        :style="{ position: 'fixed', left: optionCardX + 'px', top: optionCardY + 'px' }"
      >
        <div
          v-for="(item, index) in nodeListShow"
          :key="index"
          class="node-context-item"
          @click="onOperation(item)"
        >
          {{ item.name }}
        </div>
      </div>
    </el-dialog>
    <el-dialog
      width="356px"
      :title="addCoverageName"
      :visible.sync="addCoverageShow"
      :close-on-click-modal="false"
      class="dialog-class"
      @close="onClearn()"
    >
      <div v-if="addCoverageShow">
        <el-form
          ref="layerForm"
          :model="layerForm"
          :rules="rules"
          label-position="top"
          class="demo-ruleForm"
          size="mini"
        >
          <el-form-item
            :label="layerForm.layerType == 1 ? '图层分组名称' : '图层名称：'"
            prop="layerName"
          >
            <el-input v-model="layerForm.layerName"></el-input>
          </el-form-item>
          <el-form-item>
            <div style="text-align: right">
            <el-button @click="addCoverageShow = false">取消</el-button>
              <el-button
                v-if="updataStatus == 0"
                type="primary"
                style="margin-right: 10px"
                @click="onSubmit"
                >保存
              </el-button>
              <el-button v-else type="primary" style="margin-right: 10px" @click="updatasubmitfrom"
                >保存</el-button
              >
              
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
     <el-dialog
      width="444px"
      title="图层元素汇总"
      :visible.sync="queryCoverageShow"
      :close-on-click-modal="false"
      class="dialog-class"
      @close="onClearn()"
      :append-to-body="true"
    >
      <div >
        <el-form
          ref="layerForm"
          :model="layerForm"
          label-position="left"
          class="demo-ruleForm"
          size="mini"

        >
       
        <el-form-item label="汇总元素:">
                <el-select v-model="queryAll" multiple placeholder="请选择" style="width:244px">
                  <el-option
                    v-for="item in optionsList"
                    :key="item.dictCode"
                    :label="item.name"
                    :value="item.dictCode">
                  </el-option>
                </el-select>
                <el-button size="mini"  style="margin-left: 10px;" type="primary" @click="queryListF()">计算</el-button>
        </el-form-item>
        <el-form-item label="汇总信息:" >
            <div style="background: #F5F5F5;border: 1px solid #E1EAF0;width: 244px;display: inline-block;" v-if="queryListAlls.length>0">
              <div v-for="(item,key) in queryListAlls" :key="key" style="padding: 0px 5px" >
                {{item.key}}{{item.value}}
              </div>
            </div>
              
        </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
  </div>
</template>
<script>
import { Try, Confirm } from '@/decorator'
import Coverlayer from '../apis/coverlayer'
import { exportMethod } from '../utils/exportFile'
import { fillZero, formatTime } from '../utils/common'
import { getUserType, setCompanyId, getCompanyId, setCompanyName } from '../utils/auth'
import { commonLayer, serverMap } from '../utils/dict'
import { queryFeatrue } from '../apis/geo'
import { formatLocation } from '../utils/geoTool'
export default {
  data() {
    return {
      enterValue: '',
      enterList: [],
      loadTree: false,
      treeStatus: true,
      contextmenuShow: false,
      addLayerShow: false,
      addCoverageShow: false,
      addCoverageName: '',
      layerForm: {},
      updataStatus: 0,
      treeCurrent: {},
      treeParentCurrent: null,
      optionCardX: 0,
      optionCardY: 0,
      isSort: false,
      activeLayer: null,
      treeChildrenData: [],
      treeIds: [],
      checkedKeys: [],
      treeChildrenMap: new Map(),
      ids: [],
      nodeList: [
        { name: '导出', value: 6, type: 'public' },
        { name:'高亮当前对象', value: 11, type: 'children' },
        { name:'关闭其他图层', value: 12, type: 'children' },
        { name:'汇总元素', value: 13, type: 'children' },
      ],
      nodeListShow: [],
      treeData: [
        
      ],
      treeProp: {
        disabled: function(data, node) {
          if (data.parentId === 0) {
            return !data.leaf
          }
        }
      },
      rules: {
        layerName: [
          { required: true, message: '请输入名称', trigger: 'change' },
          { max: 50, message: '超出最大限制', trigger: ['change', 'blur'] }
        ]
      },
      queryCoverageShow:false,
      optionsList:serverMap[1015],
      queryAll:[],
      queryListAlls:[],
    }
  },
  mounted() {
    // this.activeLayer = sessionStorage.getItem('layerId')
    this.getFindEnter()
  },

  methods: {
    // handleChange(e) {
    //   setCompanyId(e.value)
    //   setCompanyName(e.label)
    //   this.activeLayer = null
    //   this.setStroeCurrent(null)
    //   this.getFindTree(1)
    // },
    setStroeCurrent(id,name) {
      this.$store.commit('gis/currentLayerNameChange', name)
      this.$store.commit('gis/currentLayerChange', id)
    },
    closeLayer(){
        this.$parent.layerShow = false 
    },
     move(e) {
      let odiv = this.$refs.dragBox
      let disX = e.clientX - odiv.offsetLeft
      let disY = e.clientY - odiv.offsetTop
      document.onmousemove = e => {
        let left = e.clientX - disX
        let top = e.clientY - disY
        this.positionX = top
        this.positionY = left

        odiv.style.left = left + 'px'
        odiv.style.top = top + 'px'
      }
      document.onmouseup = e => {
        document.onmousemove = null
        document.onmouseup = null
      }
    },
    setGroupLayer(layer,notlayer) {
      this.$store.commit('gis/notGroupLayerChange', notlayer)
      this.$store.commit('gis/groupLayerChange', layer)
      this.$eventBus.$emit('setFilter')
      this.$eventBus.$emit('setMarker')
    },
    treeClick(id,name) {
      if (!id) return
      this.activeLayer = id
      this.setStroeCurrent(id,name)
    },
    async getFindEnter(type) {
      
        this.getFindTree(1)
      
    },
    async getFindTree(type) {
      this.checkedKeys = []
      try {
        this.treeChildrenData = []
        let p = {sysType:1}
        let res = await Coverlayer.getFindTree(p)
        if (res.data && res.data.length > 0) {
          this.getTreeSee(res.data)
        } else {
          this.$message.error('请先创建图层！')
        }
        if (type) {
          let showLayer = this.treeChildrenData.filter(el => el.isSee == 2 && el.layerType != 1)
          let notShowLayer = this.treeChildrenData.filter(el => el.isSee == 0 && el.layerType != 1)
          this.setGroupLayer(showLayer,notShowLayer)
        }
        this.treeData = res.data

        if (!this.loadTree) {
          this.loadTree = true
          this.$parent.loadStateSet = this.$parent.loadStateSet + 1
        }
      } catch (error) {
        //this.$message.error(error.message)
      }
    },
    getTreeSee(data) {
      data.forEach(el => {
        if (!this.activeLayer && el.layerType != 1&&el.isDelete!=0 ) {
          this.activeLayer = el.id
          this.setStroeCurrent(el.id)
        }
        if (this.treeChildrenMap.has(el.id)) {
          this.$set(el, 'isSee', this.treeChildrenMap.get(el.id))
          if (this.treeChildrenMap.get(el.id) == 2 && !el.children) this.checkedKeys.push(el.id)
        } else {
          this.$set(el, 'isSee', 2)
          this.treeChildrenMap.set(el.id, 2)
          this.checkedKeys.push(el.id)
        }
        this.treeChildrenData.push(el)

        if (el.children) {
          this.getTreeSee(el.children)
        }
      })
    },
    swapItems(arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0]
      return arr
    },
    //上移
    upData() {
      let index = this.treeParentCurrent.findIndex(el => el.id === this.treeCurrent.id)
      if (index != -1 && index != 0) {
        this.treeParentCurrent = this.swapItems(this.treeParentCurrent, index, index - 1)
        this.setSort(this.treeCurrent, this.treeParentCurrent, this.treeCurrent.parentId)
      }
    },

    //下移
    downData() {
      let index = this.treeParentCurrent.findIndex(el => el.id === this.treeCurrent.id)
      if (index != -1 && index != this.treeParentCurrent.length - 1) {
        this.treeParentCurrent = this.swapItems(this.treeParentCurrent, index, index + 1)
        this.setSort(this.treeCurrent, this.treeParentCurrent, this.treeCurrent.parentId)
      }
    },

    onCoverage(type) {
      this.$set(this.layerForm, 'layerType', type)
      if (type == 1) this.$set(this.layerForm, 'pid', 0)
      this.addCoverageName = type == 1 ? '新建图层分组' : '新建图层'
      this.updataStatus = 0
      this.addCoverageShow = true
      this.addLayerShow = false
    },
    onClearn() {
      this.layerForm = {}
    },
    async updatasubmitfrom() {
      let { layerForm } = this
      layerForm.companyId = this.enterValue.value
      this.$refs['layerForm'].validate(async valid => {
        if (valid) {
          try {
            let res = await Coverlayer.update(layerForm)
            this.$message({
              message: res.msg,
              type: 'success'
            })
            this.addCoverageShow = false
            this.getFindTree()
          } catch (error) {
           // this.$message.error(error.message)
          }
        }
      })
    },
    async onSubmit() {
      let { layerForm } = this
      layerForm.companyId = this.enterValue.value
      this.$refs['layerForm'].validate(async valid => {
        if (valid) {
          try {
            let res = await Coverlayer.addSave(layerForm)
            this.$message({
              message: res.msg,
              type: 'success'
            })
            this.addCoverageShow = false
            let type = layerForm.layerType == 1 ? false : true
            this.getFindTree(type)
          } catch (error) {
           // this.$message.error(error.message)
          }
        }
      })
    },
    onOperation(item) {
      let { treeCurrent } = this
      switch (item.value) {
        case 1:
          this.activeLayer = treeCurrent.id
          this.setStroeCurrent(treeCurrent.id)
          //  sessionStorage.setItem('layerId', treeCurrent.id)
          break
        case 7:
          this.$set(this.layerForm, 'layerType', 2)
          this.$set(this.layerForm, 'pid', this.treeCurrent.id)
          this.addCoverageName = '新建图层'
          this.updataStatus = 0
          this.addCoverageShow = true
          break
        case 8:
          this.$set(this.layerForm, 'layerType', 1)
          this.$set(this.layerForm, 'pid', this.treeCurrent.id)
          this.addCoverageName = '新建图层分组'
          this.updataStatus = 0
          this.addCoverageShow = true
          break
        case 4:
          this.layerForm = {
            id: treeCurrent.id,
            layerName: treeCurrent.name,
            layerType: treeCurrent.parentId == 0 ? 1 : 2,
            pid: treeCurrent.parentId
          }
          this.updataStatus = 1
          this.addCoverageName = '重命名'
          this.addCoverageShow = true
          break
        case 2:
          this.upData()
          break
        case 3:
          this.downData()
          break
        case 5:
          this.getDelete(this.treeCurrent.id, this.treeCurrent)
          break
        case 6:
          // this.getDelete(this.treeCurrent.id, this.treeCurrent)
          this.ids = []
          this.exportExcel()
          break
          case 11:
            this.heightL(this.treeCurrent.id)
            break
        case 12:
          let id = this.treeCurrent.id;
          let selectEl = {}
          let notSelectEl = []
           this.treeChildrenData.forEach(el=>{
            if(id==el.id){
              selectEl = el
              this.$set(el,'isSee',2)
            }else{
              this.$set(el,'isSee',0)
              notSelectEl.push(el)
            }
            
          })
          this.checkedKeys = [id]
           this.$refs.tree.setCheckedKeys([id])
           this.setGroupLayer([selectEl],notSelectEl)
           this.activeLayer = treeCurrent.id
           this.setStroeCurrent(treeCurrent.id,treeCurrent.name)
           this.$parent.$refs.queryInfo.colse()
            this.$parent.$refs.markInfo.close()
          break
        case 13:
          this.currentId = this.treeCurrent.id;
          this.queryAllList();
          break
      }
      this.treeCurrent = null
      this.treeParentCurrent = null
      this.contextmenuShow = false
    },
     queryAllList(){
      this.queryCoverageShow = true
    },
    async queryListF(){
      if(this.queryAll.length<=0){
        this.$message.error('请选择要汇总的元素！')
        return;
      }
      let groups = this.queryAll.toString();
      let  res = await Coverlayer.layerGroup({groups:groups,layerId:this.currentId})
      if(res.data&&res.data.length>0){

        this.queryListAlls = res.data
      } 
    },
    async heightL(id){
      this.heightLayerId = id;
      this.$eventBus.$emit('setFilter')
      let data = {
        SERVICE: 'wfs',
        VERSION: '1.0.0',
        REQUEST: 'GetFeature',
        typeName: commonLayer['line_query'],
        outputFormat: 'application/json',
        CQL_FILTER:
          ' layer_id = '+id
      }
      // const loading = this.$loading({
      //   lock: true,
      //   text: '正在修改，请稍后',
      //   spinner: 'el-icon-loading',
      //   background: 'rgba(0, 0, 0, 0.7)'
      // })
      let heightList = [];
      await queryFeatrue(data).then(res => {
        // this.loading = false
        var ret = res.features;
        this.heightAlls(ret);
        let p=0.6
        let pt=true
          this.myTimeDisplay = setInterval(() => {           
            
            if(pt){
              p=p+0.1
            }else{
              p=p-0.1
            }
            if(p>=0.6){
               pt=false
            }else if(p<=0.1){
               pt=true
            }
            this.overlayGroups.setOptions({                        
                     strokeOpacity:p,
                    
                     borderWeight: 2+p*2
             });   
          //  this.overlayGroups.hide()
          //  setTimeout(() => {
          //   this.overlayGroups.show()
          //  }, 500);
        },100)
        setTimeout(() => {
          this.heightLayerId = 0;
           this.$eventBus.$emit('setFilter')
            clearInterval(this.myTimeDisplay)
            if (this.overlayGroups) {
             // this.overlayGroups.hide()
              this.overlayGroups.clearOverlays()
            }
         }, 15000);
      })
    },
    heightAlls(ret){
      let that = this;
      clearInterval(this.myTimeDisplay)
        if (that.overlayGroups) {
          that.overlayGroups.clearOverlays()
        } else {
          that.overlayGroups = new AMap.OverlayGroup({
            collision: false,
            allowCollision: false
          })
          that.$parent.map.add(that.overlayGroups)
        }
        
       let heightList = []; 
        ret.forEach(el=>{
          let poly = new AMap.Polyline({
                path: formatLocation(el.properties.location_str, 'LineString'),
                isOutline: true,
                outlineColor: 'rgba(46,218,0,0.4)',
                borderWeight: 3,
                strokeColor: '#186f00' ,
                strokeOpacity: 1,
                strokeWeight: 2,
                strokeStyle: 'solid',
                lineJoin: 'round',
                lineCap: 'round',
                zIndex: 150,
                extData: ''
              })
        heightList.push(poly)
        
        })
        that.$parent.map.setFitView(heightList,false,[100, 200, 200, 200],18) 
         that.overlayGroups.addOverlays(heightList)
    },
    async exportExcel() {
      let { treeCurrent } = this
      let name =
        treeCurrent.layerType == 1 ? treeCurrent.name + '图层组' : treeCurrent.name + '图层'
      if (treeCurrent.layerType == 1) {
        if (treeCurrent.children) await this.getIds(treeCurrent.children)
      } else {
        this.ids = [treeCurrent.id]
      }
      if (!this.ids.length) throw Error('请选择要导出的企业')
      let res = await Coverlayer.exportExcel({ ids: this.ids.join(',') })
      let date = new Date()
        .toLocaleDateString()
        .split('/')
        .map(n => fillZero(n))
        .join('-')
      let fileName = `${name + date}`
      exportMethod(res, fileName)
    },
    getIds(data) {
      data.forEach(el => {
        if (el.layerType == 2) {
          this.ids.push(el.id)
        } else if (el.children && el.children.length) {
          this.getIds(el.children)
        }
      })
    },
    async getDelete(id, item, confirm) {
      let mag = item.parentId == 0 ? '确认删除该图层组？' : '确认删除该图层？'
      await confirm(mag, true)
      let res = await Coverlayer.getDelete({ id, companyId: getCompanyId() })
      if (id == this.activeLayer) this.activeLayer = null
      let type = item.parentId == 0 ? false : true
      this.getFindTree(type)
    },
    /*
     ****************************************************************************************************
     *    功能描述： 选择图层是否显示
     *    开发人： 宋德福
     *    开时间： 2022-5-12 16:48
     *    重点参数含义： （例：@param immediate true - 立即执行， false - 延迟执行）
     ****************************************************************************************************
     */
    selectLayer(node, data) {
      let type = data.isSee === 2 ? false : true
      this.$refs.tree.setChecked(data.id, type, true)
      let treeIds = this.$refs.tree.getHalfCheckedKeys()
      let treeCheckedIds = this.$refs.tree.getCheckedKeys()
      this.treeChildrenData.forEach(el => {
        if (treeCheckedIds.includes(el.id)) {
          el.isSee = 2
        } else {
          el.isSee = 0
        }
        if (treeIds.includes(el.id)) {
          el.isSee = 1
        }
        this.treeChildrenMap.set(el.id, el.isSee)
      })
      let arr = this.treeChildrenData.filter(el => el.isSee == 2 && el.layerType != 1)
      let notArr = this.treeChildrenData.filter(el => el.isSee == 0 && el.layerType != 1)
      this.setGroupLayer(arr,notArr)
    },
    /*
     ****************************************************************************************************
     *    功能描述： 关闭右键图层弹出窗口
     *    开发人： 宋德福
     *    开时间： 2022-5-13 13:35
     *    重点参数含义：@contextmenuShow = false 不显示
     ****************************************************************************************************
     */
    contextMenuClose() {
      this.contextmenuShow = false
    },
   
    addLayerGroup() {
      this.addLayerShow = true
      console.log('新建图层组、新建图层')
    },
    handleDragStart(node, ev) {
      // console.log('drag start', node)
    },
    handleDragEnter(draggingNode, dropNode, ev) {
      // console.log('tree drag enter: ', dropNode.label)
    },
    handleDragLeave(draggingNode, dropNode, ev) {
      // console.log('tree drag leave: ', dropNode.label)
    },
    handleDragOver(draggingNode, dropNode, ev) {
      // console.log('tree drag over: ', dropNode.label)
    },
    /*
     ****************************************************************************************************
     *    功能描述： 拖拽时结束
     *    开发人： 庞红川
     *    开时间： 2022-6-9
     *    重点参数含义：
     *    @draggingNode 当前拖拽节点
     *    @dropNode 被放置节点
     *    @type 有三种情况 'prev'、'inner' 和 'next'，分别表示放置在目标节点前、插入至目标节点和放置在目标节点后
     ****************************************************************************************************
     */
    handleDragEnd(draggingNode, dropNode, dropType, ev) {
      if (!dropNode || !this.isSort) return
      let pid =
        dropNode.data.parentId == 0 && dropType !== 'inner'
          ? 0
          : dropType == 'inner'
          ? dropNode.data.id
          : dropNode.parent.data.id
      let data =
        dropType !== 'inner'
          ? pid == 0
            ? dropNode.parent.data
            : dropNode.parent.data.children
          : dropNode.data.children

      this.setSort(draggingNode.data, data, pid)
    },
    /*
     ****************************************************************************************************
     *    功能描述： 排序
     *    开发人： 庞红川
     *    开时间： 2022-5-23 15:05
     *    重点参数含义：
     *    @draggingNode 当前节点
     *    @dropNode 被放置节点
     *    @pid 被放置节点的id
     ****************************************************************************************************
     */
    async setSort(draggingNode, dropNode, pid) {
      let obj = {
        id: draggingNode.id,
        pid: pid,
        sorts: []
      }
      dropNode.forEach((el, index) => {
        let list = {
          id: el.id,
          sort: index + 1
        }
        obj.sorts.push(list)
      })
      try {
        let res = await Coverlayer.setSort(obj)
        this.$message({
          message: res.msg,
          type: 'success'
        })
        await this.getFindTree()
        this.$nextTick(() => {
          let treeIds = this.$refs.tree.getHalfCheckedKeys()
          let treeCheckedIds = this.$refs.tree.getCheckedKeys()
          this.treeChildrenData.forEach(el => {
            if (treeCheckedIds.includes(el.id)) {
              el.isSee = 2
            } else {
              el.isSee = 0
            }
            if (treeIds.includes(el.id)) {
              el.isSee = 1
            }
            this.treeChildrenMap.set(el.id, el.isSee)
          })
        })
      } catch (error) {
       // this.$message.error(error.message)
      }
    },
    handleDrop(draggingNode, dropNode, dropType, ev) {
      // console.log('tree drop: ', dropNode.label, dropType)
    },
    rightClick(ev) {
      this.treeCurrent = {
        id: 0
      }
      this.nodeListShow = [{ name: '新建图层分组', value: 8, type: 'parent' }]
      this.optionCardX = ev.x // 让右键菜单出现在鼠标右键的位置
      this.optionCardY = ev.y
      this.contextmenuShow = true // 展示右键菜单
    },
    /*
     ****************************************************************************************************
     *    功能描述： 当某一节点被鼠标右键点击时会触发该事件
     *    开发人： 宋德福
     *    开时间： 2022-5-16 15:23
     *    重点参数含义：
     *    @ev event
     *    @data 节点所对应的对象
     *    @n 节点对应的 Node
     *    @t 节点组件本身
     ****************************************************************************************************
     */
    handelNodeContextmenu(ev, data, n, t) {
      let parentData = n.data.parentId == 0 ? n.parent.data : n.parent.data.children
      parentData = parentData.filter(el => el.isDelete == 1)
      this.treeCurrent = n.data
      this.treeParentCurrent = data.layerType == 1 ? n.parent.data : n.parent.data.children
      let { nodeList } = this
      if (data.isDelete == 0) {
        this.nodeListShow = nodeList.filter(el => {
         // return el.value == '6' || el.value == '9'
          if(el.value == '6'||el.value == '11'||el.value == '12'){
            return true
          }
        })
      } else {
        this.nodeListShow = nodeList.filter(el => {
          if (
            el.type == 'public' ||
            (el.type == 'children' && data.layerType != 1) ||
            (el.type == 'parent' && data.layerType == 1)
          ) {
            return true
          }
        })
        this.nodeListShow = this.nodeListShow.filter(el => {
          if (el.value == 5) {
            if (data.isDelete == 1 && (data.layerType != 1 || !data.children)) return true
          } else if (el.value == 2) {
            let i = parentData.findIndex(el => el.id == data.id)
            if (i != 0) return true
          } else if (el.value == 3) {
            let i = parentData.findIndex(el => el.id == data.id)
            if (i != parentData.length - 1) return true
          } else {
            return true
          }
        })
      }
      this.optionCardX = ev.x // 让右键菜单出现在鼠标右键的位置
      this.optionCardY = ev.y
      this.contextmenuShow = true // 展示右键菜单
    },
    /*
     ****************************************************************************************************
     *    功能描述： 拖拽时判定目标节点能否被放置
     *    开发人： 宋德福
     *    开时间： 2022-5-16 15:23
     *    重点参数含义：
     *    @draggingNode 当前拖拽节点
     *    @dropNode 被放置节点
     *    @type 有三种情况 'prev'、'inner' 和 'next'，分别表示放置在目标节点前、插入至目标节点和放置在目标节点后
     ****************************************************************************************************
     */
    allowDrop(draggingNode, dropNode, type) {
      console.log(draggingNode.data.layerType)
      this.isSort = false
      if (draggingNode.data.layerType == 1 && dropNode.data.layerType != 1) {
        return false
      } else if (
        draggingNode.data.parentId != 0 &&
        draggingNode.data.layerType != 1 &&
        dropNode.data.parentId == 0 &&
        type !== 'inner'
      ) {
        return false
      } else if (
        draggingNode.data.layerType == 2 &&
        dropNode.data.layerType == 2 &&
        type === 'inner'
      ) {
        return false
      } else if (draggingNode.data.isDelete == 0 || dropNode.data.isDelete == 0) {
        return false
      } else {
        this.isSort = true
        return true
      }
    },
    allowDrag(draggingNode) {
      return draggingNode.data.id != 31
    }
  }
}
</script>

<style lang="scss" scoped>
#layer {
  box-shadow: 0px 1px 6px 0px rgba(4, 57, 138, 0.3);
}

.node-context {
  width: 110px;
  // height: 180px;
  border: 2px solid var(--border-color-skin);
  background: var(--background-color-skin);
  opacity: 0.8;
  position: fixed;
  z-index: 9;

  .node-context-item {
    color: var(--font-color-f1);
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
  }

  .node-context-item:hover {
    background: var(--background-hover-skin);
  }
}

::v-deep .el-checkbox {
  display: none;
}


.across {
  display: inline-block;
  padding-right: 5px;
  text-align: center;
  color: #ffad42;

  .icon {
    width: 12px;
    height: 12px;
    background: #262e37;
    border: 1px solid #455465;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.title {
  font-size: 16px;
  padding: 12px 9px 10px 11px;
  background: var(--background-title-skin);
  // border-bottom: 1px solid #0d1b29;

  .title-content:hover {
    cursor: pointer;
  }
  .title-content{
    font-weight: bold;
  }
  .title-content i {
    font-size: 14px;
    transition: 0.4s;
    transform-origin: center;
  }
}

.el-icon-plus:hover {
  cursor: pointer;
}

.treeClass {
  // padding-left: 15px;
  height: 480px;
  overflow:auto;
  // overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 0px;
  }
}

.tree-file-class {
  padding-left: -18px;
  padding-right: 5px;
  vertical-align: middle;
}

.activeLayer {
  width: 6px;
  height: 6px;
  background: #33d158;
  position: absolute;
  left: -15px;
  top: 8px;
}

.add-layer {
  position: absolute;
  padding: 10px 0;
  background: rgba(8, 31, 48, 0.8);
  border: 1px solid #5d99be;
  font-size: 14px;
  right: 0;
  z-index: 9;

  .add-item {
    cursor: pointer;
    padding: 0px 10px;

    &:hover {
      background-color: rgba(181, 206, 222, 0.2);
    }
  }
}

.btn {
  text-align: right;
}
.layerBox {
  position: absolute;
  top: 55px;
  left: 10px;
  height: 523px;
  width: 208px;
  box-shadow: 0px 1px 6px 0px rgba(4, 57, 138, 0.3);
//   border: 1px solid var(--border-color-skin);
  background: var(--background-color-skin);
  z-index: 8;
//   padding: 0px 20px 10px 20px;
}
</style>
