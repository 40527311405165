import request from '../utils/request'
export default {
  findNotSonList(params) {
    return request.get('/config/pipe/show/getNotUsedDictList', { params })
  },
  getList(params) {
    return request.get('/config/pipe/show/queryConfig', { params })
  },
  addSave(data) {
    return request.post('/config/pipe/show/save', data)
  },
  update(data) {
    return request.post('/config/pipe/show/update', data)
  },
  getDelete(params) {
    return request.get('/config/pipe/show/delete', { params })
  },
  setSort(data) {
    return request.post('/config/pipe/show/sort', data)
  },
  findSetting(params) {
    // return request.get('/configShow/findSetting', { params })
    return request.get('/config/pipe/show/findSetting', { params })
    
  },
  setting(params) {
    // return request.get('/configShow/setting', { params })
    return request.get('/config/pipe/show/setting', { params })
   
  },
  findSonListByParentId(params) {
    return request.get('/configShow/findSonListByParentId', { params })
  },
  findPipeStatusOptions(params) {
    return request.get('/pipe/findPipeStatusOptions', { params })
  }
}
