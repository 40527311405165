<template>
  <div>
    <el-dialog
      title="显示设置"
      :visible.sync="dialogVisible"
      :inline="true"
      width="506px"
      custom-class="dialog-class"
      :close-on-click-modal="false"
      @close="close"
    >
      <el-radio-group v-model="radio">
        <el-radio v-for="item in radioList" :key="item.value" :label="item.value">{{
          item.label
        }}</el-radio>
      </el-radio-group>
      <div class="btn">
        <el-button size="mini" @click="close">取消</el-button>
        <el-button type="primary" size="mini" @click="onConfirm">确定</el-button>
        
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    displaySettings: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      radio: null,
      radioList: [
        {
          label: '压力级别',
          value: '40000'
        },
        {
          label: '管道状态',
          value: '30010'
        },
        {
          label: '管道确认状态',
          value: '30011'
        },
        {
          label: '管道规格',
          value: 'texture'
        }
      ],
      dialogVisible: false
    }
  },

  mounted() {},
  methods: {
    onConfirm() {
      this.$emit('confirm', this.radio)
      this.close()
    },
    open() {
      if (this.displaySettings) {
        this.radio = this.displaySettings
      } else {
        if (this.radioList.length) this.radio = this.radioList[0].value
      }
      this.dialogVisible = true
    },
    close() {
      this.dialogVisible = false
      this.$parent.$refs.LeftTop.leftTopActive = ''
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep.el-radio__label {
  color: #fff;
}
::v-deep.el-radio__input.is-checked + .el-radio__label {
  color: #fff;
}
.btn {
  text-align: right;
  button {
    margin: 17px 15px 0 15px;
  }
}
</style>
