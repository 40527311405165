<template>
  <div>
    <el-dialog
      :title="form.pipeName"
      :visible.sync="dialogVisible"
      :inline="true"
      :modal="false"
      width="333px"
      custom-class="dialog-class"
      :close-on-click-modal="false"
      @close="close"
      @open="openD"
    >
      <el-form ref="form" :model="form" label-width="75px" class="desform" label-position="left">
         <el-form-item label="所属图层:">
            <div class="equipment-item">{{ form.layerName }}</div>
          </el-form-item>
          
          <el-form-item >
            <label slot="label">管&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;材:</label>
            <div class="equipment-item">{{ form.pipeTypeName }}</div>
          </el-form-item>
          <el-form-item label="管材型号:">
            <div class="equipment-item">{{ form.pipeModelName }}</div>
          </el-form-item>
          <el-form-item label="管线长度:">
            <div class="equipment-item">{{ form.pipeLength }} (m)</div>
          </el-form-item>
          <el-form-item label="燃气介质:">
            <div class="equipment-item">{{ form.gasTypeName }}</div>
          </el-form-item>
          <el-form-item label="压力级别:">
            <div class="equipment-item">{{ form.pressureLevelName }}</div>
          </el-form-item>
          <el-form-item label="设计压力:">
            <div class="equipment-item">{{ form.designPressure }}</div>
          </el-form-item>
          <el-form-item label="运行压力:">
            <div class="equipment-item">{{ form.operationPressure }}</div>
          </el-form-item>
          <el-form-item label="公称直径:">
            <div class="equipment-item">{{ form.diameterName }}</div>
          </el-form-item>
          <el-form-item label="实际外径:">
            <div class="equipment-item">{{ form.outerDiameter }} (mm)</div>
          </el-form-item>
          <el-form-item label="壁厚mm:">
            <div class="equipment-item">{{ form.thickness }} (mm)</div>
          </el-form-item>
          <el-form-item label="实际内径:">
            <div class="equipment-item">{{ form.innerDiameter }} (mm)</div>
          </el-form-item>
          <el-form-item label="埋深:">
            <label slot="label">埋&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;深(m):</label>
            <div class="equipment-item">{{ form.depth }}</div>
          </el-form-item>
          <el-form-item label="敷设方式:">
            <div class="equipment-item">{{ form.buildTypeName }}(mm)</div>
          </el-form-item>
          <el-form-item label="建设日期:">
            <div class="equipment-item">{{ form.buildDate }}</div>
          </el-form-item>
          <el-form-item label="粗糙度:">
            <label slot="label">粗&nbsp;&nbsp;糙&nbsp;&nbsp;度:</label>
            <div class="equipment-item">{{ form.roughness }}</div>
          </el-form-item>
          <el-form-item label="下次检测日期:" label-width="105px">
            <div class="equipment-item">{{ form.nextMonitorDate }}</div>
          </el-form-item>
          <el-form-item label="所在道路:">
            <div class="equipment-item">{{ form.road }}</div>
          </el-form-item>
          <el-form-item label="建设单位:">
            <div class="equipment-item">{{ form.buildOrganization }}</div>
          </el-form-item>
          <el-form-item label="设计单位:">
            <div class="equipment-item">{{ form.designOrganization }}</div>
          </el-form-item>
          <el-form-item label="使用状态:">
            <div class="equipment-item">{{ form.pipeStatusName }}</div>
          </el-form-item>
          <el-form-item label="确认状态:">
            <div class="equipment-item">{{ form.confirmStatusCodeName }}</div>
          </el-form-item>
          <el-form-item label="备注:">
            <label slot="label">备&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;注:</label>
            <div class="equipment-item">{{ form.remark }}</div>
          </el-form-item>
      </el-form>
      <!-- <div class="btn">
        <el-button size="mini" @click="close">取消</el-button>
      </div> -->
    </el-dialog>
  </div>
</template>
<script>
import { pipeFindById } from '../apis/tool'
export default {
  props: {
    id: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      form: {
        companyName: '',
        pipeCode: '',
        pipeName: '',
        pipeTypeName: '',
        pipeModelName: '',
        diameterName: '',
        thickness: '',
        realBore: '',
        pipeLength: '',
        depth: '',
        pressureLevelName: '',
        gasTypeName: '',
        buildTypeName: '',
        buildDate: '',
        roughness: '',
        pipeStatusName: '',
        road: '',
        address: '',
        standardName: '',
        probeDate: '',
        antisepticTypeName: '',
        remark: ''
      },
      dialogVisible: false
    }
  },
  mounted() {},
  methods: {
    close() {
      this.dialogVisible = false
      this.form = {}
    },
    openDesc(flag) {
      let that = this
      this.dialogVisible = flag
    },
    openD() {
      //获取数据的接口等待对接
      pipeFindById({ id: this.id }).then(res => {
        this.form = res.data
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.btn {
  text-align: center;
  button {
    margin: 7px 15px 0 15px;
  }
}
.spanDesc {
  background: rgba(102, 122, 143, 0.2);
  padding: 5px 10px;
  color: #fff;
  width: 100%;
  display: inline-block;
  min-height: 42px;
  border: rgba(110, 183, 227, 0.2) 1px solid;
  font-weight: 400;
}
.equipment-item {
  text-align: left;
  width: 180px;
  color: var(--font-color-c2);
}
.desform{text-align: left;margin-left: 10px;}
</style>
