<template>
  <el-dialog
    title="标注"
    :visible.sync="dialogVisible"
    width="300px"
    :show-close="item.uuid ? true : false"
    :close-on-click-modal="false"
  >
    <div>
      <el-input
        v-model="item.remark"
        maxlength="150"
        type="textarea"
        autosize
        placeholder="请输入内容"
      >
      </el-input>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="warning" @click="handleClose()">删 除</el-button>
      <el-button type="info" @click="save()">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { saveMake, delMake } from '../../apis/commonType'
export default {
  components: {},
  data() {
    return {
      dialogVisible: false,
      item: {}
    }
  },
  methods: {
    initialize(e) {
      this.item = e.item
      this.dialogVisible = true
    },
    save() {
      let p = {
        ...this.item
      }
      let that = this
      saveMake(p).then(res => {
        that.dialogVisible = false
        that.$message.success(res.msg)
        that.$parent.clearDraw()
        this.$eventBus.$emit('lineLayerGis', {})
      })
    },
    handleDel() {
      let p = { uuid: this.item.uuid }
      let that = this
      delMake(p).then(res => {
        that.dialogVisible = false
        that.$message.success(res.msg)
        that.$parent.clearDraw()
      })
    },
    //关闭
    handleClose() {
      if (this.item.uuid) {
        this.handleDel()
      } else {
        this.dialogVisible = false
        this.$parent.clearDraw()
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
