<template>
<div :style="$parent.gis?'margin-top: 10px;margin-left: 10px;width:450px;display: flex;':'width:200px'">
  <div class="searchBox" @mouseleave="removeActive()" >
    <div class="inputBox">
      <!-- <div class="search-input">搜地点</div> -->
      <el-input v-model="value" placeholder="搜地点" class="input" />
      <span class="search" @click="SearchClick()">
        <i class="el-icon-search"></i>
      </span>
    </div>

    <div v-if="SearchResultArray && SearchResultArray.length > 0" class="tool_search_result">
      <span class="colseBut" @click="colse"><i class="iconfont icon-shanchu2"></i></span>
      <div style="height: 8px;"></div>
      <ul>
        <li v-for="(item, index) in SearchResultArray" :key="index" @click="SearchItemClick(item)">
          {{ item.name }}
        </li>
      </ul>
    </div>
  </div>
   <span class="showLonlat" v-if="$parent.gis">{{$parent.positionNew.lng}} {{$parent.positionNew.lat}}</span>
  </div>
</template>

<script>
export default {
  name: 'Tool',
  components: {},
  props: {},
  data() {
    return {
      value: '',
      hasMarker: false,
      marker: null,
      SearchResultArray: []
    }
  },
  computed: {},
  mounted() {},

  methods: {
    removeActive() {
      // this.SearchResultArray = []
      // this.value = ''
    },
    SearchClick() {
      if (this.value) {
        let valArr = this.value.split(' ')
         const reg = /^(\-|\+)?(((\d|[1-9]\d|1[0-7]\d|0{1,3})\.\d{0,15})|(\d|[1-9]\d|1[0-7]\d|0{1,3})|180\.0{0,15}|180)$/
         const reg1 = /^(\-|\+)?([0-8]?\d{1}\.\d{0,15}|90\.0{0,15}|[0-8]?\d{1}|90)$/
        if(valArr.length==2&&reg.test(valArr[0])&&reg1.test(valArr[1])){
          this.SearchResultArray =[{location:valArr,name:this.value}]
        }else{
          var autoOptions = {
            input: 'tipInput'
          }
          var autoComplete = new AMap.AutoComplete(autoOptions)
          autoComplete.search(this.value, (status, result) => {
            this.SearchResultArray = result.tips
          })
        }
        
      } else {
        this.SearchResultArray = []
        this.$parent.map.remove(this.marker)
        this.hasMarker = false
        this.$parent.map.setZoom(10)
      }
    },
    colse(){
      this.SearchResultArray = []
       this.value = ''
    },
    SearchItemClick(e) {
      if (this.hasMarker && this.marker) {
        this.$parent.map.remove(this.marker)
      }
     
      // this.value = ''
      this.hasMarker = true
      let center = e.location
      if (!center || center.length < 1) {
        this.$message.error('没有查找到相应的位置信息')
        return
      }
      this.$parent.map.setCenter(center)
      // this.SearchResultArray = []
      this.marker = new AMap.Marker({
        position: center,
        content:
          '<div> <div class="scatterPoint scatterPoint2"></div>' +
          '<div class="scatterPoint scatterPoint3" ></div></div>',
        offset: new AMap.Pixel(0, 0)
      })
      this.$parent.map.add(this.marker)
      this.$parent.map.setZoom(15)
      let that = this
      setTimeout(() => {
        if (this.marker) {
          this.$parent.map.remove(this.marker)
          this.hasMarker = false
        }
      }, 10000)
    }
  }
}
</script>
<style lang="scss" scoped>
.searchBox {
  // margin-top:10px;
  // transition: left 0.5s;
  width: 260px;
  &:hover {
    .inputBox {
      width: 260px;
      .input {
        display: inline-block;
      }
      .search {
        padding: 0 10px;
      }
      .search-input {
        display: none;
      }
    }
    .tool_search_result {
      display: block;
      border-radius:2px;
      position: relative;
    }
   
  }

  .inputBox {
    width: 250px;
    overflow: hidden;
    border-radius: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--background-color-skin);
    // border: 1px solid var(--border-color-skin);
    // box-shadow: 0px 1px 6px 0px rgba(4, 57, 138, 0.3);
    transition: all 0.5s;
    .search-input {
      height: 32px;
      padding-left: 10px;
      display: flex;
      align-items: center;
      color: var(--font-color-c1);
      font-size: 12px;
    }
    .search {
      margin-right:20px;
      color: var(--font-color-c1);
    }
    // .input {
    //   display: none;
    // }
    span {
      line-height: 24px;
      font-size: 12px;
      color: var(--font-color-c1);
      cursor: pointer;
    }
  }
  ::v-deep {
    .el-input__inner {
      background: none;
      border: none;
      margin-left: 8px;
      font-size: 12px;
      color: var(--font-color-c1);
    }
    .el-input--small .el-input__icon {
      line-height: 26px;
    }
  }
  .tool_search_result {
    height: 300px;
    overflow: auto;
    border: 1px solid 1px solid var(--border-color-skin);
    border-top: none;
    background: #fff;
    background: var(--background-color-skin);
    // opacity: 0.8;
    margin-top: 2px;
    bottom: auto;
    // z-index: 12;
    text-align: left;
    font-size: 14px;
    z-index: top;
    position: relative;
  }
  .tool_search_result ul {
    width: 100%;
    height: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .tool_search_result ul li {
    cursor: pointer;
    font-size: 12px;
    color: var(--font-color-c1);
    // text-align: center;
    width: 100%;
    margin: 0px 0px 1px 0;
    padding: 5px 10px;
    border-bottom: 1px dashed rgb(170, 170, 172);
    &:hover {
      background: var(--background-active-skin);
    }
  }
  .tool_search_result ul li:last-child {
    border: none;
  }
  
}
.showLonlat{margin-left:10px;background: var(--background-color-skin);height: 24px;line-height: 24px;border-radius:20px;
  //  display: inline-block;
    padding: 4px 5px 5px 5px
  }
  .colseBut{position:absolute;right: 10px;top:10px}
</style>
